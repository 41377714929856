// step 1
// 1. update Post.js to COA.js
import Page404 from "../../pages/Page404";
import SaleInformationAdd from "./SaleInformationAdd";
import SaleInformationEdit from "./SaleInformationEdit";
import { useNavigate } from "react-router-dom";

// step 3
import * as aim from "../../js/function.easy7d";
import SubmitCheck, { CallbackCheck } from "../HandleFunction";
// step 3
// step 2
export default function Post(props) {
  // step 2
  console.log("SaleInformation.js");
  const navigate = useNavigate();
  const action = props.searchParams.get("action");
  // step 2
  const handleSubmit = (data) => {
    try {
      const getData = SubmitCheck(data);
      const d = getData.dataForm;
      // console.log("data:", getData);

      // loop d.addOption
      let add_option = "";
      if (d["option[]"]) {
        add_option = d["option[]"].map((item, index) => {
          return `ជម្រើស${index + 1}: ${item}`;
        });
        add_option = add_option.join("\n");
      }
      let extra_title = "";
      if (d["extra_title[]"]) {
        extra_title = d["extra_title[]"].map((item, index) => {
          return `បន្ថែមទំនិញ${index + 1}: ${item} តម្លៃ: ${
            d["extra_price[]"][index]
          }`;
        });
        extra_title = extra_title.join("\n");
      }
      // edit data
      const page = "postSaleInformation";
      const callback = (data) => {
        // console.log(data.errors);
        data.page = page;
        // edit data
        let action = "ពត៌មានការលក់";
        if (getData.data.page === "update") {
          action = `ធ្វើបច្ចុប្បន្នភាព ${action}`;
        }

        let dataMessage = `
<b>${action}</b>

<b>អាជីវកម្ម</b> ${d.business_name}
<b>ប្រភេទទំនិញ</b> ${d.category}
<b>ឈ្មោះទំនិញ</b> ${d.title}
<b>ប្រភេទការលក់</b> ${d.sale_type}
<b>តម្លៃ</b> ${d.price}
${add_option}
${extra_title}`;
        // convert to string with break line
        dataMessage = dataMessage.replace(/\n/g, "\\n");
        const functionName = "Sale_information";
        const gql = `
                  mutation MyMutation {
                    sendTelegramMessage(
                      text: "${dataMessage}"
                      functionName: "${functionName}"
                    ) {
                      success
                    }
                  }
                `;
        // console.log(gql);
        CallbackCheck(data, gql);
        navigate(`/${functionName}`);
      };
      // check add option
      let addOption = `addOption: null`;
      if (d["option[]"]) {
        addOption = `addOption: ${JSON.stringify(d["option[]"])}`;
      }
      let extra = `extraTitle: null`;
      if (d["extra_title[]"]) {
        extra = `extraTitle: ${JSON.stringify(d["extra_title[]"])}
          extraPrice: ${JSON.stringify(d["extra_price[]"])}
        `;
      }
      const gql = `
          mutation MyMutation {
            ${page}(
              ${data.data}
              business: "${d.business}"
              category: "${d.category}"
              title: "${d.title}"
              saleType: "${d.sale_type}"
              currency: ${d.currency}
              price: ${d.price}
              ${addOption}
              ${extra}
            ) {
              success
            }
          }`;
      // console.log(gql);
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.error("error:", error);
    }
  };
  // step 3
  // step 2
  if (action === "add") {
    // multiple props
    return <SaleInformationAdd props={{ handleSubmit }} />;
  } else if (action === "edit") {
    return (
      <SaleInformationEdit
        props={{
          ...props,
          handleSubmit,
        }}
      />
    );
  } else {
    return <Page404 />;
  }
  // step 2
}
