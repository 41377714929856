import React, { useEffect, useRef } from "react";
import * as aim from "../../js/function.easy7d";

export default function StockOut() {
  const initialized = useRef(false);
  const page_name = "stock_balance";
  const tbId = `tb_${page_name}`;
  const thead = aim.datatableHeadFoot(
    ["លរ", "ផលិតផល", "បានបញ្ចូល", "ដកស្តុក", "ត្រឡប់", "លក់", "បរិមាណ‌"],
    null
  );
  const sumAmount = null;
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      aim.loadData(
        {
          // columns: columnLoad,
          tbId: tbId,
          page_name: page_name,
          load_page: "allProductsStock",
          gql: `{
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            totalCount
            edgeCount
            edges {
              node {
                id
                name
                code
                uom
                price
                status
                latestproductquantitySet {
                  quantity
                }
                quantity
                addmoreproductSet {
                  edges {
                    node {
                      quantity
                    }
                  }
                  sumQuantity
                }
                productstockoutSet {
                  edges {
                    node {
                      quantity
                    }
                  }
                  sumQuantity
                }
                productstockreturnSet {
                  edges {
                    node {
                      quantity
                    }
                  }
                  sumQuantity
                }
              }
            }
          }`,
        },
        {
          // link: link,
          page_name: page_name,
          function_load: `${page_name}_datatable`,
          sumAmount: sumAmount,
          order_false: [1, 3, 4],
          width_column: {
            1: "100px",
          },
          columnHidden: [],
          orderColumn: ["id", "", "", "", "", ""],
          searching: ["name", "category"],
          // function
          data_execute: (result, load_page) => {
            // console.log("result:", result, "load_page:", load_page);
            const data_tb_row = [];
            result.recordsTotal = result.data[load_page].totalCount;
            result.recordsFiltered = result.data[load_page].totalCount;
            // console.log(result.recordsTotal);
            // forEach
            let number = 1;
            result.data[load_page].edges.forEach((v) => {
              console.log(v);
              if (!v.node.quantity) {
                v.node.quantity = 0;
              }
              if (!v.node.addmoreproductSet.sumQuantity) {
                v.node.addmoreproductSet.sumQuantity = 0;
              }
              if (!v.node.productstockoutSet.sumQuantity) {
                v.node.productstockoutSet.sumQuantity = 0;
              }
              if (!v.node.productstockreturnSet.sumQuantity) {
                v.node.productstockreturnSet.sumQuantity = 0;
              }
              // sum quantity and addmore
              const quantity_sum =
                v.node.quantity + v.node.addmoreproductSet.sumQuantity;

              var tb_row = [
                `${number++}`,
                `${v.node.name}`,
                `${quantity_sum} ${v.node.uom}`,
                `${aim.addCommas(v.node.productstockoutSet.sumQuantity)} ${
                  v.node.uom
                }`,
                `${aim.addCommas(v.node.productstockreturnSet.sumQuantity)} ${
                  v.node.uom
                }`,
                ``,
                `<span class="text-danger">${aim.addCommas(
                  v.node.latestproductquantitySet[0].quantity
                )} ${v.node.uom}</span>`,
              ];
              data_tb_row.push(tb_row);
            });
            return data_tb_row;
          },
        }
      );
    }
  });
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          {/* form_header */}
          {/* form_report_content */}
          {aim.body(tbId, thead)}
        </div>
      </div>
    </div>
  );
}
