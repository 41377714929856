import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Currency() {
  const initialized = useRef(false);
  const page_name = "currency";
  const tbId = `tb_${page_name}`;
  const formOption = (
    <Link
      to={`/post/${page_name}?action=add`}
      className="btn btn-success pull-right mx-1 addRow"
    >
      <i className="fa fa-plus" />
    </Link>
  );
  const thead = aim.datatableHeadFoot(
    ["លរ", "អត្តសញ្ញាណ", "រូបិយប័ណ្ណ", "ស្ថានភាព", "រូបិយប័ណ្ណគោល", "សកម្មភាព"],
    null
  );
  const sumAmount = null;

  const navigate = useNavigate();
  const statusHandle = (id, data, status) => {
    try {
      const gql = `mutation {
        postCurrency(
          id: "${id}"
          ${status}: ${data}
        ) {
          currency {
            shortName
            nameKh
            status
            isDefault
          }
        }
      }`;
      const callback = (data) => {
        // console.log(data);
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
        } else {
          // reload page when timer end
          if (status === "isDefault") {
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
          withReactContent(Swal).fire({
            icon: "success",
            title: "ជោគជ័យ",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      };
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      console.log("useEffect currency");
      aim.loadData(
        {
          // columns: columnLoad,
          tbId: tbId,
          page_name: page_name,
          load_page: "allCurrencys",
          gql: `{
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            totalCount
            edgeCount
            edges {
              cursor
              node {
                id
                flag {
                  name
                }
                nameKh
                shortName
                order
                shortcutKey
                round
                status
                isDefault
              }
            }
          }`,
        },
        {
          // link: link,
          page_name: page_name,
          function_load: `${page_name}_datatable`,
          sumAmount: sumAmount,
          order_false: [1, 3, 4, 5],
          width_column: {
            1: "100px",
          },
          columnHidden: [],
          orderColumn: ["id", "", "name_kh", "", "", ""],
          searching: ["nameKh_Icontains", "shortName_Icontains"],
          // function
          data_execute: (result, load_page) => {
            // console.log("result:", result, "load_page:", load_page);
            const data_tb_row = [];
            result.recordsTotal = result.data[load_page].totalCount;
            result.recordsFiltered = result.data[load_page].totalCount;
            // console.log(result.recordsTotal);
            // forEach
            let number = 1;
            result.data[load_page].edges.forEach((v) => {
              // base 64 decode id
              // const id = atob(v.node.id);
              // get id
              // const id_get = id.split(":")[1];

              let status = "";
              let status_val = 0;
              if (v.node.status === true) {
                status = ` checked`;
                status_val = 1;
              } else {
                status = ``;
                status_val = 0;
              }
              let checkbox_status = `<label class="switch">
                <input class="status_table updateStatus" type="checkbox" ${status} data-id="${v.node.id}" value="${status_val}">
                <span class="slider round"></span>
              </label>`;
              let isDefault = "";
              let isDefault_val = 0;
              if (v.node.isDefault === true) {
                isDefault = "checked";
                isDefault_val = 1;
              } else {
                isDefault = "";
                isDefault_val = 0;
              }
              let checkbox_isDefault = `<label class="switch">
                <input class="isDefault_table updateDefault" type="checkbox" ${isDefault} data-id="${v.node.id}" value="${isDefault_val}">
                <span class="slider round"></span>
              </label>`;
              const action = `<i title="Edit" class="btn btn-success editData fa fa-pencil-square-o" aria-hidden="true" data-edit="{'id':'${v.node.id}','page':'${page_name}'}"></i>`;
              var tb_row = [
                `${number++}`,
                `${v.node.flag.name}`,
                `ឈ្មោះ: ${v.node.nameKh}<br>
                ឈ្មោះខ្លី: ${v.node.shortName}<br>
                Short-cut Key: ${v.node.shortcutKey}<br>
                លំដាប់: ${v.node.round}`,
                `${checkbox_status}`,
                `${checkbox_isDefault}`,
                `${action}`,
              ];
              data_tb_row.push(tb_row);
            });
            return data_tb_row;
          },
        }
      );
      // document.queryselector .editData
      document.querySelector(`#${tbId}`).addEventListener("click", (e) => {
        const el = e.target;
        if (el.classList.contains("editData")) {
          const data_edit = JSON.parse(el.dataset.edit.replace(/'/g, '"'));
          const { id, page } = data_edit;
          navigate(`/post/${page}?action=edit&id=${id}`);
        } else if (el.classList.contains("updateStatus")) {
          const id = el.dataset.id;
          const status = el.checked;
          statusHandle(id, status, "status");
        } else if (el.classList.contains("updateDefault")) {
          const id = el.dataset.id;
          const is_status = el.checked;
          statusHandle(id, is_status, "isDefault");
        }
      });
    }
  });
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          {/* form_header */}
          {aim.header_option(formOption)}
          {/* form_report_content */}
          {aim.body(tbId, thead)}
        </div>
      </div>
    </div>
  );
}
