// step 1
// 1. update Post.js to COA.js
import Page404 from "../../pages/Page404";
import TableAdd from "./TableAdd";
import TableEdit from "./TableEdit";
import { useNavigate } from "react-router-dom";

// step 3
import * as aim from "../../js/function.easy7d";
import SubmitCheck, { CallbackCheck } from "../HandleFunction";
// step 3
// step 2
export default function Post(props) {
  // step 2
  console.log("Table.js");
  const navigate = useNavigate();
  const action = props.searchParams.get("action");
  // step 2
  const handleSubmit = (data) => {
    try {
      const getData = SubmitCheck(data);
      const d = getData.dataForm;
      // console.log(getData);
      // edit data
      const page = "postTable";
      const callback = (data) => {
        // console.log(getData.data.page);
        data.page = page;
        // edit data
        let action = "តុ";
        if (getData.data.page === "update") {
          action = `ធ្វើបច្ចុប្បន្នភាព ${action}`;
        }

        let dataMessage = `
<b>${action}</b>

<b>អាជីវកម្ម</b> ${d.business_name}
<b>ឈ្មោះ​តុ</b> ${d.table_name}
<b>លេខតុ</b> ${d.table_no}
<b>ប្រភេទតុ</b> ${d.table_type}`;
        // convert to string with break line
        dataMessage = dataMessage.replace(/\n/g, "\\n");
        const functionName = "Table";
        const gql = `
                  mutation MyMutation {
                    sendTelegramMessage(
                      text: "${dataMessage}"
                      functionName: "${functionName}"
                    ) {
                      success
                    }
                  }
                `;
        // console.log(gql);
        CallbackCheck(data, gql);
        navigate(`/${functionName}`);
      };

      const gql = `
        mutation MyMutation {
          ${page}(
            ${data.data}
            business: "${d.business}"
            tableName: "${d.table_name}"
            tableNo: ${d.table_no}
            tableType: "${d.table_type}"
          ) {
            success
          }
        }
      `;
      // console.log(gql);
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.error("error:", error);
    }
  };
  // step 3
  // step 2
  if (action === "add") {
    // multiple props
    return <TableAdd props={{ handleSubmit }} />;
  } else if (action === "edit") {
    return (
      <TableEdit
        props={{
          ...props,
          handleSubmit,
        }}
      />
    );
  } else {
    return <Page404 />;
  }
  // step 2
}
