import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";

export default function Post(props) {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit2({ page: "quotation", data: null });
  };
  const navigate = useNavigate();
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const $ = window.$;
      $("#date_need")
        .datepicker({ dateFormat: "dd-mm-yy" })
        .datepicker("setDate", new Date());

      $("#code_products_list").keyup(function () {
        // console.log("code_products_list", $(this).val());
        const item = $(this).val();
        const gql = `query SearchProductList {
            allProcess(
              quotationStatus: 0
              productsInfo_Invoice_Icontains: "${item}", 
              productsInfo_InvoiceManual_Icontains: "${item}"
            ) {
              edges {
                node {
                  id
                  productsInfo {
                    id
                    invoice
                    invoiceManual
                    company
                    customer
                    customerPhone
                  }
                }
              }
            }
          }`;
        $("#code_products_list").devbridgeAutocomplete({
          serviceUrl: `https://landscape.aimsarik.com/gql/`,
          type: "POST",
          ajaxSettings: {
            headers: {
              // jwt token
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            contentType: "application/json",
            data: JSON.stringify({ query: gql }),
          },
          transformResult: function (response) {
            // convert response to json object
            response = JSON.parse(response);
            console.log(response);
            return {
              suggestions: response.data.allProcess.edges.map((item) => {
                return {
                  value: `${item.node.productsInfo.company} - ${item.node.productsInfo.customer} - ${item.node.productsInfo.customerPhone} - ${item.node.productsInfo.invoice} - ${item.node.productsInfo.invoiceManual}`,
                  data: item.node.productsInfo.id,
                };
              }),
            };
          },
          onSelect: function (suggestion) {
            console.log("suggestion", suggestion);
            $("#products_list_id").val(suggestion.data);
            const gql = `query ProductsList($id: ID!) {
              productsInfo(id: $id) {
                id
                company
                customer
                customerPhone
                customerAddress
                productdetailSet {
                  edges {
                    node {
                      product {
                        name
                        uom
                      }
                      quantity
                      price
                      discount
                      total
                      afterDiscount
                    }
                  }
                  sumTotal
                  sumAfterDiscount
                }
                currency {
                  shortName
                }
              }
            }`;
            const variables = {
              id: suggestion.data,
            };
            const callback = (data) => {
              const d = data.data.productsInfo;
              console.log("data:", data);

              if (data.errors) {
                console.error("error:", data.errors);
              } else {
                // console.log("data:", d);
                let counter = 1;
                let html = "";
                let discount_check = 0;
                d.productdetailSet.edges.forEach((v) => {
                  if (v.node.discount > 0) {
                    discount_check = 1;
                  }
                  html += `<tr>
                    <td>${counter++}</td>
                    <td>${v.node.product.name}</td>
                    <td>${v.node.product.uom}</td>
                    <td>${aim.addCommas(v.node.quantity)}</td>
                    <td>${aim.currencyFormat(
                      v.node.price,
                      d.currency.shortName,
                      2
                    )}</td>
                    <td>${v.node.discount}</td>
                    <td>${aim.currencyFormat(
                      v.node.afterDiscount,
                      d.currency.shortName,
                      2
                    )}</td>
                  </tr>`;
                });

                let show_discount = "";
                if (discount_check === 1) {
                  show_discount = `<div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">បញ្ចុះតម្លៃ</span>
                      </div>
                      <span class="form-control" id="discount">${aim.currencyFormat(
                        d.productdetailSet.sumTotal -
                          d.productdetailSet.sumAfterDiscount,
                        d.currency.shortName,
                        2
                      )}</span>
                  </div>
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">ទឹកប្រាក់នៅសល់</span>
                      </div>
                      <span class="form-control" id="balance">${aim.currencyFormat(
                        d.productdetailSet.sumAfterDiscount,
                        d.currency.shortName,
                        2
                      )}</span>
                  </div>`;
                }
                const products_list_summary = `<div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ទឹកប្រាក់សរុប</span>
                    </div>
                    <span class="form-control">${aim.currencyFormat(
                      d.productdetailSet.sumTotal,
                      d.currency.shortName,
                      2
                    )}</span>
                </div>
                ${show_discount}
                `;

                $("#form_print_data").html(`
                <div class="row mt-4">
                    <div id="no-more-tables" class="w-100">
                        <div class="card border-primary">
                            <h3 class="card-header bg-primary text-white text-center">
                                <span>តារាងតម្លៃ</span>
                            </h3> 

                            <div class="card-body mt-2 row">
                                <table class="table table-hover table-bordered" id="addCategory">
                                    <thead>
                                        <tr>
                                            <th>លរ</th>
                                            <th>ឈ្មោះផលិតផល</th>
                                            <th>ឯកតា</th>
                                            <th>បរិមាណ</th>
                                            <th>តម្លៃលក់</th>
                                            <th>បញ្ចុះតម្លៃ%</th>
                                            <th>តម្លៃសរុប</th>
                                        </tr>
                                    </thead>
                                    <tbody id="table_body">
                                        ${html}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-end" id="products_list_summary" style="display: grid">
                    ${products_list_summary}
                </div>`);
                $("#submit").show();
              }
            };
            aim.jquery_free(gql, variables, callback);
          },
        });
        $(this).focus();
      });
    }
  });
  return (
    <div className="content-wrapper">
      <div className="container-fluid" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">បញ្ជាទិញទំនិញ</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">កូដតារាងរួម</span>
                      </div>
                      <input
                        type="hidden"
                        name="products_list_id"
                        id="products_list_id"
                        className="form-control"
                      />
                      <input
                        type="text"
                        name="code_products_list"
                        id="code_products_list"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">កាលបរិច្ឆេទ</span>
                      </div>
                      <input
                        type="text"
                        name="date_need"
                        id="date_need"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="form_print_data" className="col-12" />
              <div className="card-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  id="submit"
                  style={{ display: "none" }}
                >
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/products_list")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
