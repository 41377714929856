import React, { useContext, useRef, useEffect, useState } from "react";
import { AuthContext } from "../../appState/AuthProvider";
import * as aim from "../../js/function.easy7d";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SubmitCheck from "../../postData/HandleFunction";
// import QRCode from "qrcode";
export function printInvoice(d) {
  console.log(d);

  const $ = window.$;
  const currencyDefault = aim.useGetCurrencyDefault();
  const cd = currencyDefault[0].node;
  const di = d;
  const dateTime = aim.dateFormat.date_time_kh(di.saleInvoice.date.created);

  let listCount = 0;
  let list = "";
  let prepareGroup = [];
  let prepareGroupStock = [];

  const listFunction = (d, exchange) => {
    return `
      <tr key=${listCount++}>
        <td style="text-align: left;">
          ${d.name}
          <span>${d.addExtra}</span>
        </td>
        <td style="text-align: left; vertical-align: top">${d.quantity} x</td>
        <td style="text-align: left; vertical-align: top">
          ${aim.multiple_digits(d.price * exchange, 2)}
          ${d.extraPrice}
        </td>
        <td style="text-align: left; vertical-align: top">
          <strong>
            ${aim.addCommas(aim.currencyRound(d.total * exchange, cd.round))}
          </strong>
        </td>
      </tr>`;
  };

  const tbodyData = di.saleInvoice.saleorderSet.edges;
  const food = tbodyData.filter((v) => v.node.type !== "stock");
  const stock = tbodyData.filter((v) => v.node.type === "stock");
  food.sort((a, b) => a.node.id - b.node.id);
  stock.sort((a, b) => a.node.id - b.node.id);

  food.forEach((x) => {
    let addOption = "";
    if (x.node.saleorderoptionSet.length > 0) {
      x.node.saleorderoptionSet.forEach((v) => {
        addOption += `<br>${v.AddOption.name}`;
      });
    }
    const exchange = aim.exchangeRate(
      x.node.saleInformation.currency.isDefault,
      x.node.saleInformation.currency.rate.fixed,
      cd
    );
    let addExtra = "";
    let extraPrice = "";
    if (x.node.saleorderextraSet.length > 0) {
      x.node.saleorderextraSet.forEach((v) => {
        addExtra += `<br>${v.AddExtra.title}`;
        extraPrice += `<br>${aim.multiple_digits(
          parseFloat(v.price) * exchange,
          2
        )}`;
      });
    }
    if (addOption.length > 0 || addExtra.length > 0) {
      const d = {
        id: x.node.id,
        name: x.node.saleInformation.name,
        addOption: addOption,
        addExtra: addExtra,
        extraPrice: extraPrice,
        quantity: x.node.quantity,
        price: x.node.price,
        discountPercent: x.node.discountPercent,
        total: x.node.total,
      };
      list += listFunction(d, exchange);
    } else {
      prepareGroup.push(x);
    }
  });

  const groupFood = prepareGroup.reduce((r, a) => {
    const key = a.node.saleInformation.name;
    r[key] = r[key] || [];
    r[key].group = r[key].group || [];
    r[key].group.push(a);
    r[key].quantity = (r[key].quantity || 0) + a.node.quantity;
    r[key].total = (r[key].total || 0) + parseFloat(a.node.total);
    return r;
  }, Object.create(null));

  Object.keys(groupFood).forEach((v) => {
    const exchange = aim.exchangeRate(
      groupFood[v].group[0].node.saleInformation.currency.isDefault,
      groupFood[v].group[0].node.saleInformation.currency.rate.fixed,
      cd
    );
    let addExtra = "";
    let extraPrice = "";
    if (groupFood[v].group[0].node.saleorderextraSet.length > 0) {
      groupFood[v].group[0].node.saleorderextraSet.forEach((v) => {
        addExtra += `<br>${v.AddExtra.title}`;
        extraPrice += `<br>${aim.multiple_digits(
          parseFloat(v.price) * exchange,
          2
        )}`;
      });
    }
    const d = {
      id: groupFood[v].group[0].node.id,
      name: groupFood[v].group[0].node.saleInformation.name,
      addOption: "",
      addExtra: addExtra,
      extraPrice: extraPrice,
      quantity: groupFood[v].quantity,
      price: groupFood[v].group[0].node.price,
      discountPercent: groupFood[v].group[0].node.discountPercent,
      total: groupFood[v].total,
    };
    list += listFunction(d, exchange);
  });

  // loop through stock
  stock.forEach((x) => {
    prepareGroupStock.push(x);
  });

  const groupStock = prepareGroupStock.reduce((r, a) => {
    const key = a.node.stock.name;
    r[key] = r[key] || [];
    r[key].group = r[key].group || [];
    r[key].group.push(a);
    r[key].quantity = (r[key].quantity || 0) + a.node.quantity;
    r[key].total = (r[key].total || 0) + parseFloat(a.node.total);
    return r;
  }, Object.create(null));

  Object.keys(groupStock).forEach((v) => {
    const exchange = aim.exchangeRate(
      groupStock[v].group[0].node.stock.currency.isDefault,
      groupStock[v].group[0].node.stock.currency.rate.fixed,
      cd
    );
    const d = {
      id: groupStock[v].group[0].node.id,
      name: groupStock[v].group[0].node.stock.name,
      addOption: "",
      addExtra: "",
      extraPrice: "",
      quantity: groupStock[v].quantity,
      price: groupStock[v].group[0].node.stock.price,
      discountPercent: groupStock[v].group[0].node.discountPercent,
      total: groupStock[v].total,
    };
    list += listFunction(d, exchange);
  });

  // editi later
  // set print in chrome to 150%
  let customerInfo = "";
  if (di.saleInvoice.customer) {
    customerInfo = `<strong>អតិថិជន</strong>
    <span> : ${di.saleInvoice.customer.name}</span><br />
    <strong>ទូរស័ព្ទ</strong>
    <span> : ${di.saleInvoice.customer.phone[0].number}</span>`;
  }
  // console.log(di.saleInvoice.status);
  let invoiceTitle = "";
  let totalDesc = "";
  if (di.saleInvoice.status === 1) {
    invoiceTitle = "ប័ណ្ណទទួលប្រាក់";
    let paid = "";
    // loop through paymentSet
    const paymentSet = di.saleInvoice.paidSet;
    paymentSet.forEach((v) => {
      paid += `<tr style="border: 2px solid #000">
      <td width="65%" align="right" style="line-height: 1.3rem">
        <strong>បានទទួល (${v.coaId.type.type})</strong>: </span>
      </td>
      <td width="35%" align="right" style="line-height: 1.3rem; padding-right: 12px">
        <strong>${aim.currencyFormat(
          v.paid,
          v.coaId.currency.shortName,
          2
        )}</strong>
      </td>
    </tr>`;
    });
    let change = "";
    const changePayment = di.saleInvoice.changepriceSet;
    changePayment.forEach((v) => {
      change += `<tr style="border: 2px solid #000">
      <td width="65%" align="right" style="line-height: 1.3rem">
        <strong>លុយអាប់ (${v.coaId.type.type}): </span>
      </td>
      <td width="35%" align="right" style="line-height: 1.3rem; padding-right: 12px">
        <strong>${aim.currencyFormat(
          v.change,
          v.coaId.currency.shortName,
          2
        )}</strong>
      </td>
    </tr>`;
    });
    totalDesc = `<tbody style="border: 0 !important;">
      <tr style="border: 0; border-top: 2px solid #000">
        <td width="65%" align="right" style="line-height: 1.3rem">
          <span>សរុប: </span>
        </td>
        <td width="35%" align="right" style="line-height: 1.3rem; padding-right: 12px">
            ${aim.currencyFormat(di.saleInvoice.total, cd.shortName, 2)}
        </td>
      </tr>

      <tr style="border: 0">
        <td width="65%" align="right" style="line-height: 1.3rem">
          <span>បញ្ចុះតម្លៃ: </span>
        </td>
        <td width="35%" align="right" style="line-height: 1.3rem; padding-right: 12px">
          ${aim.currencyFormat(di.saleInvoice.discount, cd.shortName, 2)}
        </td>
      </tr>
      
      <tr style="border: 2px solid #000">
        <td width="65%" align="right" style="line-height: 1.3rem">
          <strong>នៅសល់: </span>
        </td>
        <td width="35%" align="right" style="line-height: 1.3rem; padding-right: 12px">
          <strong>${aim.currencyFormat(
            di.saleInvoice.grandTotal,
            cd.shortName,
            2
          )}</strong>
        </td>
      </tr>
      ${paid}
      ${change}
    </tbody>`;
  } else {
    // console.log(di);
    const currencyCheck = aim.useGetCurrency();
    // console.log(currencyCheck);

    // console.log(di.saleInvoice.discount, cd.shortName, cd.round);

    invoiceTitle = "វិក្កយបត្រ";
    let discountData = `<tr style="border: 0">
      <td width="65%" align="right" style="line-height: 1.3rem">
        <span>បញ្ចុះតម្លៃ: </span>
      </td>
      <td width="35%" align="right" style="line-height: 1.3rem; padding-right: 12px">
        ${aim.currencyFormat(
          aim.currencyRound(di.saleInvoice.discount, cd.round),
          cd.shortName,
          2
        )}
      </td>
    </tr>`;

    let balanceMultiple = "";
    currencyCheck.forEach((v) => {
      const exchange = aim.exchangeRate(cd.id, cd.rate.fixed, v.node);
      // console.log(exchange);

      balanceMultiple += `<tr style="border: 2px solid #000">
      <td width="65%" align="right" style="line-height: 1.3rem">
        <strong>នៅសល់: </span>
      </td>
      <td width="35%" align="right" style="line-height: 1.3rem; padding-right: 12px">
        <strong>${aim.currencyFormat(
          aim.currencyRound(di.saleInvoice.grandTotal * exchange, v.node.round),
          v.node.shortName,
          2
        )}</strong>
      </td>
    </tr>`;
    });

    totalDesc = `<tbody style="border: 0 !important;">
    <tr style="border: 0; border-top: 2px solid #000">
      <td width="65%" align="right" style="line-height: 1.3rem">
        <span>សរុប: </span>
      </td>
      <td width="35%" align="right" style="line-height: 1.3rem; padding-right: 12px">
          ${aim.currencyFormat(di.saleInvoice.total, cd.shortName, 2)}
      </td>
    </tr>

    ${discountData}
    ${balanceMultiple}
    
  </tbody>`;
  }
  let marginPrint = "margin: 0 !important;";
  if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
  ) {
    marginPrint = "margin: 0 0 0 -8px !important;";
  }

  const data_print_invoice = `<div class="modal-body"
  style="width: 262px; 
  color: #000000 !important; 
  font-size: 16px;
  font-family: Battambang;
  ${marginPrint}
  text-align: left;">
    <div class="row">
      ${di.saleInvoice.table.business.Company.address}<br />
      ទូរស័ព្ទ: ${di.saleInvoice.table.business.Company.phone}<br />
    </div>
    <div style="font-size: 28px; text-align: center">
      <strong>${invoiceTitle}</strong>
    </div>
    <div class="row" style="font-size: 13px">
      <strong>លេខ​វិក្កយបត្រ</strong>
      <span> : ${di.saleInvoice.invoice}</span>
      <br />
      <div class="input-group">
        <strong>កាលបរិច្ឆេទ</strong>
        <span> : ${dateTime}</span>
      </div>
    </div>

    <div class="row">
      <div>
        <strong>សេវាកម្ម</strong>
        <span> : តុ ${di.saleInvoice.table.name}</span><br />
        ${customerInfo}
      </div>
    </div>

    <div class="mt-1 row" style="font-family: Battambang;">
      <table style="width: 100%; font-family: Battambang;">
          <thead>
              <tr style="border: 0; border-bottom: 2px solid #000">
                <th align="left" width="40%" style="text-align: left;">មុខទំនិញ</th>
                <th></th>
                <th>តម្លៃ</th>
                <th>សរុប</th>
              </tr>
          </thead>
          <tbody style="border: 0 !important">
            ${list}
          </tbody>
      </table>
      <table style="width: 100%; color: #000000 !important; border: 0 !important; font-family: Battambang;">
        ${totalDesc}
      </table>
    </div>
  </div>`;
  if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
  ) {
    const data_print = [
      {
        type: "text",
        value: `<div style="font-weight: 700; font-size: 1.4rem; text-align: center">${di.saleInvoice.table.business.Company.name}</div>`,
        style: {},
      },
      {
        type: "text",
        value: data_print_invoice,
        style: {},
      },
    ];
    const options = {
      preview: false,
      margin: "0 0 0 0 ",
      copies: 1,
      printerName: "R Cashier",
      timeOutPerLine: 10000,
      pageSize: {
        width: 284,
        height: 7600,
      }, // page size
      silent: true,
    };
    /* if (di.saleInvoice.status === 0) {
      const data_print_qr = {
        type: "qrCode",
        value:
          "00020101021130510016abaakhppxxx@abaa01151240411151408690208ABA Bank5204581453038405802KH5919Landscape by C.SUON6010PHNOM PENH625768530010PAYWAY@ABA01071268699020902121643604066583270501199130002026803mmp6304F329",
        height: 165,
        width: 165,
        position: "center",
      };
      const data_qr_name = {
        type: "text",
        value: `<div style="text-align: center; font-size: 1.2rem; font-weight: 700">Landscape Cafe</div>`,
        style: {},
      };
      // push to data_print
      data_print.push(data_print_qr);
      data_print.push(data_qr_name);
    } */
    withReactContent(Swal)
      .fire({
        icon: "success",
        // title: "ជោគជ័យ",
        width: 372,
        heightAuto: false,
        html: data_print_invoice,
        showCancelButton: true,
        cancelButtonText: "បោះបង់",
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ព្រីន",
      })
      .then((result) => {
        if (result.isConfirmed) {
          window.electronAPI.setPinter({
            data: data_print,
            options: options,
            saleType: 0,
            printer_name: "R Cashier",
            logo: true,
          });
        }
      });
  } else {
    /* const generateQR = async (text) => {
      try {
        const qrCode = await QRCode.toDataURL(text);
        return `<img src="${qrCode}" alt="qr code" style="width: 100px; height: 100px" />`;
      } catch (err) {
        console.error(err);
      }
    };
    generateQR("hello world").then((qrCode) => {
      const print_data = `<div style="width: 262px">
        <div class="col-auto mr-auto text-center mb-2">
            <img alt="logo" src="/dist/img/gray_logo.png" id="company_logo" width="100">
        </div>
        <div style="text-align: center; font-weight: 700; font-size: 1rem;">
        ${di.saleInvoice.table.business.Company.name}
        </div>
        ${data_print_invoice}
        <div class="text-center">
          ${qrCode}
        </div>
        <div style="text-align: center; font-size: 1.4rem; font-weight: 700">Landscape Cafe</div>
      </div>`;
      $.print(print_data);
    }); */
    $.print(data_print_invoice);
  }
}
export default function Table() {
  const initialized = useRef(false);
  // get user from AuthProvider
  const { user } = useContext(AuthContext);
  const [companyData, setCompanyData] = useState([]);
  // console.log(user);

  const dateNowRef = useRef();
  const companyPhoneRef = useRef();
  const companyAddressRef = useRef();
  const customerPhoneRef = useRef();

  const [searchParams] = useSearchParams();
  const table_name = searchParams.get("table_name");
  const table = searchParams.get("table");
  const business = searchParams.get("business");

  const [tableList, setTableList] = useState([]);
  const [balance, setbalance] = useState([]);

  let [total, setTotal] = useState(0);
  const [currency, setCurrency] = useState([]);
  // const [currencyId, setCurrencyId] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const data = { page: "create", data: null };
      const getData = SubmitCheck(data);
      const d = getData.dataForm;
      // console.log(d);

      const page = "postInvoice";
      const gql = `
        mutation PostInvoice {
          ${page}(
            ${data.data}
            tableNumber: "${table}"
            curencyId: "${currency.id}"
            customerID: ${JSON.stringify(d["customer_phone_sale[]"])}
            discountAll: "${d.discountAll}"
            itemId: ${JSON.stringify(d["item_id[]"])}
            itemDiscPerc: ${JSON.stringify(d["itemDiscPerc[]"])}
            itemDiscMoney: ${JSON.stringify(d["itemDiscMoney[]"])}
            total: "${d.total}"
            proDiscount: "${d.proDiscount}"
            grandTotal: "${d.grandTotal}"
          ) {
            success
            saleInvoice {
              date {
                created
              }
              saleorderSet(status: 0) {
                edges {
                  node {
                    type
                    id
                    price
                    discountPercent
                    quantity
                    total
                    saleorderoptionSet {
                      AddOption {
                        name
                      }
                    }
                    saleInformation {
                      name
                      currency {
                        rate {
                          fixed
                        }
                        isDefault
                      }
                    }
                    saleorderextraSet {
                      AddExtra {
                        title
                      }
                      price
                    }
                    stock {
                      name
                      price
                      originalPrice
                      currency {
                        rate {
                          fixed
                        }
                        isDefault
                      }
                    }
                  }
                }
              }
              customer {
                name
                phone {
                  number
                }
              }
              table {
                name
                business {
                  Company {
                    phone
                    address
                    name
                  }
                }
              }
              invoice
              total
              discount
              grandTotal
              status
            }
          }
        }
      `;
      const callback = (data) => {
        console.log(data);

        // print invoice
        printInvoice(data.data.postInvoice);
        // print invoice
        navigate(`/Sale_invoice`);
      };
      // console.log(gql);
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const $ = window.$;
      if (user.business) {
        // all company data not use in this page
        // allCustomers
        // allTable
        const gql = `query CreateInvoice {
            # allCompanies {
            #    edges {
            #      node {
            #        # name
            #        phone
            #        logo
            #        address
            #        branch
            #      }
            #    }
            #  }
            allTable(business_Business_In: ["${business}"], name: "${table_name}", status: 1) {
              edges {
                node {
                  business {
                    business
                    Company {
                      name
                      phone
                      logo
                      address
                      branch
                    }
                  }
                  saleorderSet(status: 0) {
                    edges {
                      node {
                        id
                        type
                        saleorderoptionSet {
                          AddOption {
                            name
                          }
                        }
                        saleInformation {
                          currency {
                            rate {
                              fixed
                            }
                            isDefault
                          }
                        }
                        saleorderextraSet {
                          AddExtra {
                            title
                          }
                          price
                        }
                        saleInformation {
                          name
                        }
                        quantity
                        price
                        total
                        stock {
                          name
                          price
                          originalPrice
                          currency {
                            rate {
                              fixed
                            }
                            isDefault
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            allCustomers {
              edges {
                node {
                  discount
                  id
                  name
                  phone {
                    number
                  }
                }
              }
            }
          }`;
        const callback = (data) => {
          if (data.errors) {
            withReactContent(Swal).fire({
              icon: "error",
              title: "មានបញ្ហា បង្កើតវិក្កយបត្រ",
              html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
              confirmButtonText: "យល់ព្រម",
            });
          } else {
            // console.log(data);

            let company = data.data.allTable.edges[0].node.business.Company;
            company.logo = `https://landscape.aimsarik.com/media/${company.logo}`;
            setCompanyData(company);

            // create invoice number with date time
            const date = new Date();
            // 2024-05-01
            const year = date.getFullYear();
            // 05 2 digit month
            const month = ("0" + (date.getMonth() + 1)).slice(-2);
            // 01 2 digit day
            const day = ("0" + date.getDate()).slice(-2);
            // 2024-05-01
            const dateNow = `${year}-${month}-${day}`;
            dateNowRef.current.innerText = aim.dateFormat.date_kh(dateNow);
            // get company phone
            companyPhoneRef.current.innerText = company.phone;
            // get company address
            companyAddressRef.current.innerText = company.address;

            // get table list
            let list = [];
            // total price of all item
            let totalData = 0;
            let prepareGroup = [];
            let prepareGroupStock = [];
            let gDiscPerc = 0;
            let gDiscMoney = 0;
            let listCount = 0;
            let brCount = 0;

            // table list function
            const listFunction = (d, exchange) => {
              // console.log(d);
              // console.log(exchange);

              let item_id = [];
              gDiscPerc++;
              gDiscMoney++;
              d.id.forEach((v) => {
                item_id.push(
                  <input type="hidden" name="item_id[]" value={v} key={v} />,
                  <input
                    type="hidden"
                    // add class group for itemDiscPerc
                    className={"itemDiscPerc p" + gDiscPerc}
                    name="itemDiscPerc[]"
                    key={v + "perc"}
                    defaultValue={0}
                  />,
                  <input
                    type="hidden"
                    className={"itemDiscMoney m" + gDiscMoney}
                    name="itemDiscMoney[]"
                    key={v + "money"}
                    defaultValue={0}
                  />
                );
              });
              return (
                <tr key={listCount++}>
                  <td>
                    {d.name}
                    <p style={{ margin: "-22px 0px 0 12px" }}>{d.addOption}</p>
                    <p style={{ margin: "-22px 0px 0 12px" }}>{d.addExtra}</p>
                    {item_id}
                  </td>
                  <td>{d.quantity}</td>
                  <td>
                    {aim.multiple_digits(d.price * exchange, 2)}
                    {d.extraPrice}
                  </td>
                  <td className="totalItem">
                    {aim.multiple_digits(d.total * exchange, 2)}
                  </td>
                  <td>
                    <div className="row">
                      <div className="input-group col">
                        <input
                          type="text"
                          className={
                            "form-control itemDiscPerc itemPerc number p" +
                            gDiscPerc
                          }
                          data-class={"p" + gDiscPerc}
                          defaultValue={0}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">%</span>
                        </div>
                      </div>
                      <div className="input-group col">
                        <input
                          type="text"
                          className={
                            "form-control itemDiscMoney itemMoney number m" +
                            gDiscMoney
                          }
                          data-class={"m" + gDiscMoney}
                          autoComplete="off"
                          defaultValue={0}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            {cd.shortName}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            };

            // loop through saleorderSet
            const d = data.data.allTable.edges[0].node.saleorderSet.edges;

            const food = d.filter((v) => v.node.type !== "stock");
            const stock = d.filter((v) => v.node.type === "stock");
            food.sort((a, b) => a.node.id - b.node.id);
            stock.sort((a, b) => a.node.id - b.node.id);

            // console.log(food);
            // get currency default
            const currencyDefault = aim.useGetCurrencyDefault();
            const cd = currencyDefault[0].node;
            // set currencyName and currencyId to curr
            const currency = { name: cd.shortName, id: cd.id };
            setCurrency(currency);

            // console.log(currency);

            food.forEach((x) => {
              let addOption = [];
              if (x.node.saleorderoptionSet.length > 0) {
                x.node.saleorderoptionSet.forEach((v) => {
                  // add break line
                  addOption.push(<br key={brCount++} />, v.AddOption.name);
                });
              }
              // console.log(addOption);
              const exchange = aim.exchangeRate(
                x.node.saleInformation.currency.isDefault,
                x.node.saleInformation.currency.rate.fixed,
                cd
              );
              let addExtra = [];
              let extraPrice = [];
              if (x.node.saleorderextraSet.length > 0) {
                x.node.saleorderextraSet.forEach((v) => {
                  addExtra.push(<br key={brCount++} />, v.AddExtra.title);
                  extraPrice.push(
                    <br key={brCount++} />,
                    aim.multiple_digits(parseFloat(v.price) * exchange, 2)
                  );
                });
              }
              // console.log(addExtra);
              if (addOption.length > 0 || addExtra.length > 0) {
                const d = {
                  id: [x.node.id],
                  name: x.node.saleInformation.name,
                  addOption: addOption,
                  addExtra: addExtra,
                  extraPrice: extraPrice,
                  quantity: x.node.quantity,
                  price: x.node.price,
                  total: x.node.total,
                };
                // console.log(d);
                list.push(listFunction(d, exchange));
                totalData += parseFloat(x.node.total) * exchange;
                // setTotal((prev) => prev + parseFloat(x.node.total) * exchange);
                // setTotal(totalData);
              } else {
                prepareGroup.push(x);
              }
            });
            // console.log(prepareGroup);

            // old version
            /* const foodGroup = prepareGroup.reduce((r, a) => {
              const key = a.node.price + "|" + a.node.saleInformation.name;
              r[key] = r[key] || [];
              r[key].push(a);
              return r;
            }, Object.create(null));
            // console.log(foodGroup);
            // console.log(foodGroup["1.5|Ice"]);

            const sumFoodGroup = Object.keys(foodGroup).map((v) => {
              let quantity = 0;
              let total = 0;
              foodGroup[v].forEach((x) => {
                quantity += x.node.quantity;
                total += parseFloat(x.node.total);
              });
              return { quantity, total, group: foodGroup[v] };
            });
            console.log(sumFoodGroup);

            sumFoodGroup.forEach((x) => {
              const exchange = aim.exchangeRate(
                x.group[0].node.saleInformation.currency.isDefault,
                x.group[0].node.saleInformation.currency.rate.fixed,
                cd
              );
              // count group item
              let groupID = [];
              const countGroup = x.group.length;
              if (countGroup > 1) {
                groupID = x.group.map((v) => v.node.id);
              } else {
                groupID = [x.group[0].node.id];
              }
              const d = {
                id: groupID,
                name: x.group[0].node.saleInformation.name,
                addOption: "",
                addExtra: "",
                extraPrice: "",
                quantity: x.quantity,
                price: x.group[0].node.price,
                total: x.total,
              };
              list.push(listFunction(d, exchange));
              // total += parseFloat(x.total) * exchange;
              setTotal((prev) => prev + parseFloat(x.total) * exchange);
            }); */
            // old version
            // new version
            const sumFoodGroup = prepareGroup.reduce((r, a) => {
              const key = a.node.saleInformation.name;
              r[key] = r[key] || [];
              r[key].group = r[key].group || [];
              r[key].group.push(a);
              r[key].quantity = (r[key].quantity || 0) + a.node.quantity;
              r[key].total = (r[key].total || 0) + parseFloat(a.node.total);
              /* r[key].push(a);
              r[key].quantity = (r[key].quantity || 0) + a.node.quantity;
              r[key].total = (r[key].total || 0) + parseFloat(a.node.total); */
              return r;
            }, Object.create(null));
            // console.log(sumFoodGroup);
            // loop through sumFoodGroup
            Object.keys(sumFoodGroup).forEach((v) => {
              // console.log(sumFoodGroup[v]);
              const exchange = aim.exchangeRate(
                sumFoodGroup[v].group[0].node.saleInformation.currency
                  .isDefault,
                sumFoodGroup[v].group[0].node.saleInformation.currency.rate
                  .fixed,
                cd
              );
              // count group item
              let groupID = [];
              const countGroup = sumFoodGroup[v].group.length;
              if (countGroup > 1) {
                groupID = sumFoodGroup[v].group.map((v) => v.node.id);
              } else {
                groupID = [sumFoodGroup[v].group[0].node.id];
              }
              const d = {
                id: groupID,
                name: sumFoodGroup[v].group[0].node.saleInformation.name,
                addOption: "",
                addExtra: "",
                extraPrice: "",
                quantity: sumFoodGroup[v].quantity,
                price: sumFoodGroup[v].group[0].node.price,
                total: sumFoodGroup[v].total,
              };
              list.push(listFunction(d, exchange));
              totalData += parseFloat(sumFoodGroup[v].total) * exchange;
              /* setTotal(
                (prev) => prev + parseFloat(sumFoodGroup[v].total) * exchange
              ); */
              // setTotal(totalData);
            });

            // loop through stock
            stock.forEach((x) => {
              prepareGroupStock.push(x);
            });

            const sumStockGroup = prepareGroupStock.reduce((r, a) => {
              const key = a.node.stock.name;
              r[key] = r[key] || [];
              r[key].group = r[key].group || [];
              r[key].group.push(a);
              r[key].quantity = (r[key].quantity || 0) + a.node.quantity;
              r[key].total = (r[key].total || 0) + parseFloat(a.node.total);
              return r;
            }, Object.create(null));
            // console.log(sumStockGroup);
            // loop through sumStockGroup
            Object.keys(sumStockGroup).forEach((v) => {
              // console.log(sumStockGroup[v]);
              const exchange = aim.exchangeRate(
                sumStockGroup[v].group[0].node.stock.currency.isDefault,
                sumStockGroup[v].group[0].node.stock.currency.rate.fixed,
                cd
              );
              // count group item
              let groupID = [];
              const countGroup = sumStockGroup[v].group.length;
              if (countGroup > 1) {
                groupID = sumStockGroup[v].group.map((v) => v.node.id);
              } else {
                groupID = [sumStockGroup[v].group[0].node.id];
              }
              const d = {
                id: groupID,
                name: sumStockGroup[v].group[0].node.stock.name,
                addOption: "",
                addExtra: "",
                extraPrice: "",
                quantity: sumStockGroup[v].quantity,
                price: sumStockGroup[v].group[0].node.stock.price,
                total: sumStockGroup[v].total,
              };
              list.push(listFunction(d, exchange));
              totalData += parseFloat(sumStockGroup[v].total) * exchange;
              /* setTotal(
                (prev) => prev + parseFloat(sumStockGroup[v].total) * exchange
              ); */
              // setTotal(totalData);
            });
            // new version
            /* list = (
              <tr>
                <td>ទំនិញ</td>
                <td>បរិមាណ</td>
                <td>តម្លៃ</td>
                <td>សរុប</td>
                <td>បញ្ចុះតម្លៃ</td>
              </tr>
            ); */
            setTableList(list);
            // get table list

            // customer phone discount
            const balanceFunc = (total, discount) => {
              console.log(total, discount);
              const discountData = (
                <div className="input-group mb-1">
                  <div className="input-group-prepend">
                    <span className="input-group-text">បញ្ចុះតម្លៃ</span>
                  </div>
                  <input
                    type="hidden"
                    className="form-control proDiscount"
                    name="proDiscount"
                    id="proDiscount"
                    value={discount}
                  />
                  <span className="form-control" id="proDiscount_show" readOnly>
                    {aim.currencyFormat(discount, cd.shortName, 2)}
                  </span>
                </div>
              );
              const grandTotal = (
                <input
                  type="hidden"
                  id="grandTotal"
                  className="form-control"
                  name="grandTotal"
                  value={total - discount}
                />
              );
              const currencyCheck = aim.useGetCurrency();
              // console.log(currencyCheck);
              // loop currencyCheck to get multiple currency balance
              let balanceMultiple = [];
              currencyCheck.forEach((v) => {
                // console.log(v);

                const exchange = aim.exchangeRate(cd.id, cd.rate.fixed, v.node);
                const currencyBalance = (
                  <div className="input-group mb-1" key={v.node.id}>
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        {/* <span>នៅសល់ {v.node.shortName}</span> */}
                        <span>នៅសល់</span>
                      </div>
                    </div>
                    <span
                      className="form-control"
                      id="grandTotal_show"
                      readOnly
                    >
                      {aim.currencyFormat(
                        aim.currencyRound(
                          (total - discount) * exchange,
                          v.node.round
                        ),
                        v.node.shortName,
                        2
                      )}
                    </span>
                  </div>
                );
                balanceMultiple.push(currencyBalance);
              });
              return (
                <>
                  {discountData}
                  {grandTotal}
                  {balanceMultiple}
                </>
              );
            };

            const discountAll = (data, cd) => {
              // console.log(data, cd);
              if (data === "") {
                return 0;
              } else if (isNaN(data)) {
                return 0;
              } else if (data < 0) {
                return 0;
              } else if (data > 100) {
                return 0;
              } else {
                const discountAll = parseFloat(data);
                // console.log("discountAll", discountAll);
                $(".itemDiscPerc").val(discountAll);
                let discountData = 0;
                $(".itemPerc").each(function () {
                  const perc = parseFloat($(this).val());
                  const totalItem = parseFloat(
                    $(this)
                      .closest("tr")
                      .find(".totalItem")
                      .text()
                      .replace(/,/g, "")
                  );
                  const discMoney = aim.currencyRound(
                    (totalItem * perc) / 100,
                    cd.round
                  );
                  // console.log(discMoney);

                  $(this).closest("tr").find(".itemDiscMoney").val(discMoney);
                  discountData += discMoney;
                });
                // get total from useState
                setbalance(
                  balanceFunc(parseFloat($("#total").val()), discountData)
                );
                /* $("#proDiscount").val(discountData);
                $("#proDiscount_show").text(
                  aim.currencyFormat(discountData, cd.shortName, 2)
                );
                const total = parseFloat($("#total").val());
                const grandTotal = total - discountData;
                $("#grandTotal").val(grandTotal);
                $("#grandTotal_show").text(
                  aim.currencyFormat(grandTotal, cd.shortName, 2)
                ); */
              }
            };

            const customerDidscount = data.data.allCustomers.edges;
            // console.log(customerDidscount);
            let optionBlank = "";
            const option = customerDidscount.map((e) => {
              const discount = parseFloat(e.node.discount);
              e.node.phone.forEach((v) => {
                optionBlank = `<option value="${e.node.id}" data-customer-discount="${discount}">${v.number} - ${e.node.name}</option>`;
              });
              return optionBlank;
            });
            // console.log(option);
            customerPhoneRef.current.innerHTML = `<div class="input-group-prepend">
                <span class="input-group-text">
                  ទូរស័ព្ទអតិថិជន
                </span>
              </div>
              <select
                class="form-control customerPhone select2"
                name="customer_phone_sale[]"
                id="customer_phone_sale"
                style="width: 65%"
              >
                <option value="" data-customer-discount="0"></option>
                ${option}
              </select>`;
            $("#customer_phone_sale").on("change", function () {
              const discount = $(this)
                .find(":selected")
                .data("customer-discount");
              $("#discountAll").val(discount);
              discountAll(discount, cd);
            });
            $(".customerPhone").select2();
            // customer phone discount

            // function on keyup
            $("#addDataForm").on("keyup", "#discountAll", function () {
              discountAll($(this).val(), cd);
            });

            $("#addDataForm").on("keyup", ".itemDiscPerc", function () {
              // check if itemDiscPerc is number and not empty
              if (isNaN($(this).val())) {
                $(this).val(0);
                return;
              } else if ($(this).val() === "") {
                $(this).val(0);
                return;
              } else if ($(this).val() < 0) {
                $(this).val(0);
                return;
              } else if ($(this).val() > 100) {
                $(this).val(0);
                return;
              } else {
                // const discountAll = parseFloat($("#discountAll").val());
                // console.log("discountAll", discountAll);
                const pGroup = $(this).data("class");
                $(`.${pGroup}`).val($(this).val());
                let discountData = 0;
                $(".itemPerc").each(function () {
                  const perc = parseFloat($(this).val());
                  // console.log(perc);
                  const totalItem = parseFloat(
                    $(this)
                      .closest("tr")
                      .find(".totalItem")
                      .text()
                      .replace(/,/g, "")
                  );
                  // console.log(totalItem);
                  const discMoney = aim.currencyRound(
                    (totalItem * perc) / 100,
                    cd.round
                  );
                  $(this).closest("tr").find(".itemDiscMoney").val(discMoney);
                  const mGroup = $(this)
                    .closest("tr")
                    .find(".itemDiscMoney")
                    .data("class");
                  $(`.${mGroup}`).val(discMoney);
                  // console.log(discMoney);
                  discountData += discMoney;
                });
                setbalance(
                  balanceFunc(parseFloat($("#total").val()), discountData)
                );
                // console.log(discountData);
                /* $("#proDiscount").val(discountData);
                $("#proDiscount_show").text(
                  aim.currencyFormat(discountData, cd.shortName, 2)
                );
                const total = parseFloat($("#total").val());
                const grandTotal = total - discountData;
                $("#grandTotal").val(grandTotal);
                $("#grandTotal_show").text(
                  aim.currencyFormat(grandTotal, cd.shortName, 2)
                ); */
              }
            });
            $("#addDataForm").on("keyup", ".itemDiscMoney", function () {
              if (isNaN($(this).val())) {
                $(this).val(0);
                return;
              } else if ($(this).val() === "") {
                $(this).val(0);
                return;
              } else if ($(this).val() < 0) {
                $(this).val(0);
                return;
              } else {
                const mGroup = $(this).data("class");
                $(`.${mGroup}`).val($(this).val());
                let discountData = 0;
                $(".itemMoney").each(function () {
                  const money = parseFloat($(this).val());
                  const totalItem = parseFloat(
                    $(this)
                      .closest("tr")
                      .find(".totalItem")
                      .text()
                      .replace(/,/g, "")
                  );
                  const perc = aim.currencyRound(
                    (money / totalItem) * 100,
                    cd.round
                  );
                  $(this).closest("tr").find(".itemDiscPerc").val(perc);
                  const pGroup = $(this)
                    .closest("tr")
                    .find(".itemDiscPerc")
                    .data("class");
                  $(`.${pGroup}`).val(perc);
                  discountData += money;
                });
                setbalance(
                  balanceFunc(parseFloat($("#total").val()), discountData)
                );
              }
            });
            // function on keyup

            setTotal(totalData);
            setbalance(balanceFunc(totalData, 0));
          }
        };
        aim.jquery_free(gql, {}, callback);
      }
    }
  }, [user, business, table_name]);

  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          <div className="border rounded mt-4">
            <form
              method="post"
              id="addDataForm"
              name="addDataForm"
              onSubmit={handleSubmit}
            >
              <div className="border-bottom p-2">
                <div title="logo">
                  <img
                    alt="logo"
                    src={companyData.logo}
                    className="text-primary text-center"
                    height={75}
                  />
                  <h2 className="text-primary text-center">
                    {companyData.name}
                  </h2>
                </div>
              </div>
              <div className="modal-body">
                <div className="row justify-content-between">
                  <div className="col-sm-6">
                    <div className="input-group">
                      <strong>កាលបរិច្ឆេទ:&nbsp;</strong>
                      <span ref={dateNowRef} id="dateNow"></span>
                    </div>
                    <div className="input-group">
                      <strong>អ្នក​ទាក់ទង​បាន:&nbsp;</strong>
                      <span ref={companyPhoneRef} id="companyPhone"></span>
                    </div>
                    <span ref={companyAddressRef} id="companyAddress"></span>
                    <br />
                  </div>
                  <div className="col-sm-5">
                    <strong>សេវាកម្ម: </strong>
                    <span>{table_name}</span>
                    <br />
                    <input
                      type="hidden"
                      className="form-control operator"
                      name="tableNumber"
                      defaultValue={table}
                    />
                    <div
                      className="input-group mb-2"
                      ref={customerPhoneRef}
                    ></div>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">បញ្ចុះតម្លៃ %</span>
                      </div>
                      <input
                        className="form-control number"
                        name="discountAll"
                        id="discountAll"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div id="no-more-tables" className="w-100">
                    <div className="card border-primary">
                      <h3 className="card-header bg-primary text-white text-center">
                        <span>វិក្កយបត្រ</span>
                      </h3>
                      <div
                        className="card-body"
                        style={{ padding: 0 }}
                        id="desc"
                      >
                        <table className="table table-hover" id="invoiceList">
                          <thead>
                            <tr>
                              <th width="30%">ទំនិញ</th>
                              <th>បរិមាណ</th>
                              <th>តម្លៃ</th>
                              <th>សរុប</th>
                              <th>បញ្ចុះតម្លៃ</th>
                            </tr>
                          </thead>

                          <tbody>{tableList}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row justify-content-end"
                  style={{ display: "grid" }}
                  id="hideReport"
                >
                  <div className="input-group mb-1">
                    <div className="input-group-prepend">
                      <span className="input-group-text">សរុប</span>
                    </div>
                    <input
                      type="hidden"
                      className="form-control operator"
                      id="total"
                      name="total"
                      defaultValue={total}
                    />
                    <span
                      className="form-control"
                      id="total_show"
                      data-currency="{v.currShortName}"
                      readOnly
                    >
                      {aim.currencyFormat(total, currency.name, 2)}
                    </span>
                  </div>
                  {balance}
                </div>
                {/*from report*/}
                <div className="row modal-footer">
                  <div className="col">
                    <div className="d-block">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block submit"
                      >
                        រួចរាល់
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
