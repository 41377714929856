import React, { useEffect, useRef } from "react";
import * as aim from "../../js/function.easy7d";

export default function Currency() {
  const initialized = useRef(false);
  const page_name = "sale_check";
  const tbId = `tb_${page_name}`;
  const thead = aim.datatableHeadFoot(
    ["លរ", "ឈ្មោះ", "បរិមាណ", "ប្រភេទ", "ឈ្មោះតុ", "កាលបរិច្ឆេទ"],
    null
  );
  const sumAmount = null;
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      aim.loadData(
        {
          tbId: tbId,
          page_name: page_name,
          load_page: "allSaleOrder",
          gql: `{
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
              totalCount
              edgeCount
              edges {
                cursor
                node {
                  type
                  saleInformation {
                    name
                  }
                  stock {
                    name
                  }
                  id
                  quantity
                  delete
                  date {
                    created
                    creator {
                      username
                    }
                  }
                  saleorderextraSet {
                    AddExtra {
                      title
                    }
                  }
                  table {
                    name
                    number
                  }
                }
              }
          }`,
        },
        {
          page_name: page_name,
          function_load: `${page_name}_datatable`,
          sumAmount: sumAmount,
          order_false: [2, 3],
          width_column: {
            1: "100px",
          },
          columnHidden: [],
          orderColumn: ["id", "name", "", "", ""],
          searching: ["name_Icontains"],
          /* otherOption: {
            status: true,
          }, */
          // function
          data_execute: (result, load_page) => {
            // step 7
            // console.log("result:", result, "load_page:", load_page);
            const data_tb_row = [];
            result.recordsTotal = result.data[load_page].totalCount;
            result.recordsFiltered = result.data[load_page].totalCount;
            let number = 1;
            result.data[load_page].edges.forEach((v) => {
              let productName = "";
              if (v.node.type === "stock") {
                productName = v.node.stock.name;
              } else {
                productName = v.node.saleInformation.name;
              }
              let typeSale = "";
              if (v.node.type === "food") {
                typeSale = "ម្ហូប";
              } else if (v.node.type === "drink") {
                typeSale = "គ្រឿងទឹក";
              } else if (v.node.type === "stock") {
                typeSale = "ផលិតផលក្នុងស្តុក";
              } else if (v.node.type === "other") {
                typeSale = "ផ្សេងៗ";
              } else if (v.node.type === "set") {
                typeSale = "ឈុត";
              }
              const dateTime = new Date(v.node.date.created);
              var tb_row = [
                `${number++}`,
                `${productName}`,
                `${v.node.quantity}`,
                `${typeSale}`,
                `${v.node.table.name}`,
                `${aim.dateFormat.date_time_kh(dateTime)}`,
              ];
              data_tb_row.push(tb_row);
            });
            return data_tb_row;
          },
        }
      );
      aim.dateRange(tbId);
    }
  });
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          {/* form_header */}
          {aim.header()}
          {/* step 6 */}
          {aim.body(tbId, thead)}
        </div>
      </div>
    </div>
  );
}
