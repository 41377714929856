import Swal from "sweetalert2";
// import React, { useEffect, useState } from "react";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";

const $ = window.$;
// jquery mutation
export const jquery_mutation = (gql) => {
  $.ajax({
    url: `https://landscape.aimsarik.com/gql/`,
    type: "POST",
    headers: {
      // jwt token
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    contentType: "application/json",
    data: JSON.stringify({
      query: gql,
    }),
    success: function (data) {
      // console.log(data.errors);
      if (data.errors) {
        withReactContent(Swal).fire({
          icon: "error",
          title: "បរាជ័យ",
          html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
        });
      } else {
        withReactContent(Swal).fire({
          icon: "success",
          title: "ជោគជ័យ",
          showConfirmButton: false,
          timer: 3500,
        });
      }
    },
    error: function (error) {
      // console.log(error);
      withReactContent(Swal).fire({
        icon: "error",
        title: "បរាជ័យ",
        html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${error.statusText}`,
      });
    },
  });
};
// jquery query
export const jquery_query = (gql, callback) => {
  // console.log("gql:", gql);
  $.ajax({
    url: `https://landscape.aimsarik.com/gql/`,
    type: "POST",
    headers: {
      // jwt token
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    contentType: "application/json",
    data: JSON.stringify({
      query: `query {
        ${gql}
        }`,
    }),
    async: false,
    success: function (data) {
      // console.log(data);
      callback(data);
    },
    error: function (error) {
      console.log(error);
    },
  });
};
// jquery free
export const jquery_free = (gql, variables = {}, callback) => {
  $.ajax({
    url: `https://landscape.aimsarik.com/gql/`,
    type: "POST",
    headers: {
      // jwt token
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    contentType: "application/json",
    data: JSON.stringify({
      query: gql,
      variables: variables,
    }),
    success: function (data) {
      callback(data);
    },
    error: function (error) {
      callback(error.responseJSON);
    },
  });
};
// jquery free
export const jquery_uploadFile = (gql, callback) => {
  $.ajax({
    url: `https://landscape.aimsarik.com/media/`,
    type: "POST",
    headers: {
      // jwt token
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    data: gql,
    contentType: false,
    processData: false,
    success: function (data) {
      callback(data);
    },
    error: function (error) {
      callback(error.responseJSON);
    },
  });
};
// format
export const addCommas = (str) => {
  var arr, int, dec;
  str += "";

  arr = str.split(".");
  int = arr[0] + "";
  dec = arr.length > 1 ? "." + arr[1] : "";

  return int.replace(/(\d)(?=(\d{3})+$)/g, "$1,") + dec;
};
export const multiple_digits = (data, digits) => {
  return addCommas(parseFloat(Number(data).toFixed(digits)));
};
export const currencyFormat = (data, currency, digits) => {
  return `${multiple_digits(data, digits)} ${currency}`;
};
export const currencyRound = (number, precision) => {
  var factor = Math.pow(10, precision);
  var tempNumber = number * factor;
  var roundedTempNumber = Math.round(tempNumber);
  // var roundedTempNumber = parseInt(tempNumber);
  return roundedTempNumber / factor;
};

export const exchangeRate = (checkCurrencyDefault, rateFixed, cd) => {
  // console.log("checkCurrencyDefault", checkCurrencyDefault, "rateFixed", rateFixed, "cd", cd);

  let rate = 1;
  // console.log("not default currency");
  // default currency
  /* {
    "id": "Q3VycmVuY3lUeXBlOjE=",
    "nameEn": "អាមេរិចដុល្លារ",
    "nameKh": "អាមេរិចដុល្លារ",
    "shortcutKey": "d",
    "shortName": "ដុល្លារ",
    "round": 1,
    "status": true,
    "isDefault": true,
    "rate": {
        "fixed": 1
    }
  } */
  // exchange currency
  /* {
    "id": "Q3VycmVuY3lUeXBlOjI=",
    "nameEn": "ខ្មែររៀល",
    "shortName": "រៀល",
    "round": -2,
    "rate": {
        "fixed": 4000
    },
    "isDefault": false
  } */
  const rateFixedDefault = cd.rate.fixed; // 1 or 4000 or 35
  const rateFixedExchange = rateFixed; // 4000

  if (rateFixedDefault === rateFixedExchange) {
    // console.log("default === exchange");
    // console.log(rateFixedDefault, rateFixedExchange);
    rate = 1;
  } else if (rateFixedDefault < rateFixedExchange) {
    // console.log("default < exchange");
    // console.log(rateFixedDefault, rateFixedExchange);
    rate = rateFixedDefault / rateFixedExchange;
  } else {
    // console.log("default > exchange");
    // console.log(rateFixedDefault, rateFixedExchange);
    rate = rateFixedDefault / rateFixedExchange;
  }
  return rate;
};
// format
export const useGetCurrency = () => {
  const gql = `query {
    allCurrencys(status: true) {
      edges {
        node {
          id
          nameKh
          shortcutKey
          nameEn
          shortName
          status
          isDefault
          rate {
            fixed
          }
          round
        }
      }
    }
  }`;
  let currency = [];
  $.ajax({
    url: `https://landscape.aimsarik.com/gql/`,
    type: "POST",
    headers: {
      // jwt token
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    contentType: "application/json",
    data: JSON.stringify({
      query: gql,
    }),
    async: false,
    success: function (data) {
      // console.log(data);
      currency = data.data.allCurrencys.edges;
    },
    error: function (error) {
      currency = error.responseJSON;
    },
  });
  if (currency.length > 0) {
    return currency;
  }
};
export const useGetCurrencyDefault = () => {
  const gql = `query {
    allCurrencys(isDefault: true) {
      edges {
        node {
          id
          nameEn
          nameKh
          shortcutKey
          shortName
          round
          status
          isDefault
          rate {
            fixed
          }
        }
      }
    }
  }`;
  let currency = [];
  $.ajax({
    url: `https://landscape.aimsarik.com/gql/`,
    type: "POST",
    headers: {
      // jwt token
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    contentType: "application/json",
    data: JSON.stringify({
      query: gql,
    }),
    async: false,
    success: function (data) {
      // console.log(data);
      currency = data.data.allCurrencys.edges;
    },
    error: function (error) {
      currency = error.responseJSON;
    },
  });
  if (currency.length > 0) {
    return currency;
  }
};
export const useGetCoa = (business) => {
  const gql = `query {
    allCoa(status: true, business_Business_In: [${business}]) {
      edges {
        node {
          id
          name
          accountNo
          category
          status
          type {
            id
            type
          }
          business {
            id
            business
          }
          currency {
            id
            nameKh
            shortName
            status
            round
            rate {
              fixed
            }
            order
            isDefault
          }
        }
      }
    }
  }`;
  let coa = [];
  $.ajax({
    url: `https://landscape.aimsarik.com/gql/`,
    type: "POST",
    headers: {
      // jwt token
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    contentType: "application/json",
    data: JSON.stringify({
      query: gql,
    }),
    async: false,
    success: function (data) {
      // console.log(data);
      coa = data.data.allCoa.edges;
    },
    error: function (error) {
      coa = error.responseJSON;
    },
  });
  if (coa.length > 0) {
    return coa;
  }
};
// useGetBusiness
export const useGetBusiness = () => {
  // const [business, setBusiness] = useState([]);
  const gql = `query {
    allCompanies {
      edges {
        node {
          id
          name
          businessSet {
            edges {
              node {
                id
                business
              }
            }
          }
        }
      }
    }
  }`;

  let business = [];
  $.ajax({
    url: `https://landscape.aimsarik.com/gql/`,
    type: "POST",
    headers: {
      // jwt token
      Authorization: `JWT ${localStorage.getItem("token")}`,
    },
    contentType: "application/json",
    data: JSON.stringify({
      query: gql,
    }),
    async: false,
    success: function (data) {
      // console.log(data);
      business = data.data.allCompanies.edges;
    },
    error: function (error) {
      business = error.responseJSON;
    },
  });
  if (business.length > 0) {
    return business;
  }
};
// form
export const header = (option = "") => {
  // console.log("option:", option);
  const header = (
    <div className="form_header">
      <div className="row hidePrint">
        <div className="col-md-12 my-3">
          {option}
          <form method="post" id="date_range">
            <div
              className="input-group pull-right mx-1"
              style={{ width: "unset" }}
            >
              <div
                className="reportrange pull-right form-control"
                style={{ cursor: "pointer" }}
              >
                <i className="fa fa-calendar" />
                &nbsp;
                <span /> <i className="fa fa-caret-down" />
                <input
                  type="hidden"
                  name="p_date"
                  className="form-control p_date"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
  return header;
};
export const header_option = (option = "") => {
  /* var cancel_check = $(`.cancel`).length;
  if (cancel_check > 0){
      $(".cancel").parents(".card").remove();
  } */
  const header = (
    <div className="form_header">
      <div className="row hidePrint">
        <div className="col-md-12 my-3">{option}</div>
      </div>
    </div>
  );
  return header;
};
export const body = (id, thead) => {
  const form_report_content = (
    <div className="printReport form_report_content">
      <div className="mb-3 text-center hideScreen company_profile">
        {/* <img alt="logo" src="${profile.company.logo}" height={75} /> */}
        {/* <h2 className="text-primary">${'{'}profile.company.name{'}'}</h2> */}
      </div>
      <div className="col-12 card border-primary py-3">
        <div className="card-header bg-primary text-white text-center">
          <h3>របាយការណ៍</h3>
          <span className="report_date" />
        </div>
        <div className="card-body mt-3 pt-3 p-0">
          <table
            className="table table-bordered table-striped display nowrap responsive"
            id={id}
            data-date-range
            data-max-id
            cellSpacing={0}
            width="100%"
          >
            {thead}
            <tbody className="filterTable" />
          </table>
        </div>
      </div>
    </div>
  );
  return form_report_content;
};
export const body_report = (id, thead) => {
  const form_report_content = (
    <table
      className="table table-bordered table-striped display nowrap responsive"
      id={id}
      data-date-range
      data-max-id
      cellSpacing={0}
      width="100%"
    >
      {thead}
      <tbody className="filterTable" />
    </table>
  );
  return form_report_content;
};
// form

// date format
export const dateFormat = {
  date_time_kh: (data) => {
    const isDate = (str) => {
      return new Date(str) !== "Invalid Date" && !isNaN(new Date(str));
    };
    if (isDate(data)) {
      const date = moment(data);
      let date_default = date.format("Y-MM-DD h:mm:ss A");
      const month = date_default.split("-");
      if (month[1] === "01") {
        date_default = `${month[0]}-មករា-${month[2]}`;
      } else if (month[1] === "02") {
        date_default = `${month[0]}-កុម្ភៈ-${month[2]}`;
      } else if (month[1] === "03") {
        date_default = `${month[0]}-មីនា-${month[2]}`;
      } else if (month[1] === "04") {
        date_default = `${month[0]}-មេសា-${month[2]}`;
      } else if (month[1] === "05") {
        date_default = `${month[0]}-ឧសភា-${month[2]}`;
      } else if (month[1] === "06") {
        date_default = `${month[0]}-មិថុនា-${month[2]}`;
      } else if (month[1] === "07") {
        date_default = `${month[0]}-កក្កដា-${month[2]}`;
      } else if (month[1] === "08") {
        date_default = `${month[0]}-សីហា-${month[2]}`;
      } else if (month[1] === "09") {
        date_default = `${month[0]}-កញ្ញា-${month[2]}`;
      } else if (month[1] === "10") {
        date_default = `${month[0]}-តុលា-${month[2]}`;
      } else if (month[1] === "11") {
        date_default = `${month[0]}-វិច្ឆិកា-${month[2]}`;
      } else if (month[1] === "12") {
        date_default = `${month[0]}-ធ្នូ-${month[2]}`;
      }
      return date_default;
    } else {
      console.log("no date");
      return data;
    }
  },
  date_kh: (data) => {
    if (data === "1970-01-01") {
      return "";
    } else {
      var date = moment(data);
      var date_default = date.format("DD-MM-Y");
      var month = date_default.split("-");
      if (month[1] === "01") {
        date_default = `${month[0]}-មករា-${month[2]}`;
      } else if (month[1] === "02") {
        date_default = `${month[0]}-កុម្ភៈ-${month[2]}`;
      } else if (month[1] === "03") {
        date_default = `${month[0]}-មីនា-${month[2]}`;
      } else if (month[1] === "04") {
        date_default = `${month[0]}-មេសា-${month[2]}`;
      } else if (month[1] === "05") {
        date_default = `${month[0]}-ឧសភា-${month[2]}`;
      } else if (month[1] === "06") {
        date_default = `${month[0]}-មិថុនា-${month[2]}`;
      } else if (month[1] === "07") {
        date_default = `${month[0]}-កក្កដា-${month[2]}`;
      } else if (month[1] === "08") {
        date_default = `${month[0]}-សីហា-${month[2]}`;
      } else if (month[1] === "09") {
        date_default = `${month[0]}-កញ្ញា-${month[2]}`;
      } else if (month[1] === "10") {
        date_default = `${month[0]}-តុលា-${month[2]}`;
      } else if (month[1] === "11") {
        date_default = `${month[0]}-វិច្ឆិកា-${month[2]}`;
      } else if (month[1] === "12") {
        date_default = `${month[0]}-ធ្នូ-${month[2]}`;
      }
      return date_default;
    }
  },
};
export const action_group = (action) => {
  var data = `<div class="btn-group">
    <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        សកម្មភាព
    </button>
    <div class="dropdown-menu dropdown-menu-right">
        ${action}
    </div>
  </div>`;
  return data;
};
// datatable
export const datatableHead = (array_thead) => {
  let head = array_thead.map((thead, index) => {
    return <th key={index}>{thead}</th>;
  });
  return (
    <>
      <thead>
        <tr>{head}</tr>
      </thead>
    </>
  );
};
export const datatableHeadFoot = (array_thead, column) => {
  let head = array_thead.map((thead, index) => {
    return <th key={index}>{thead}</th>;
  });
  let count = array_thead.length;
  let foot = [];
  for (let i = 0; i < count; i++) {
    if (i === column) {
      foot.push(
        <th className="text-right" key={i}>
          សរុប
        </th>
      );
    } else {
      foot.push(<th key={i}></th>);
    }
  }

  return (
    <>
      <thead>
        <tr>{head}</tr>
      </thead>
      <tfoot>
        <tr>{foot}</tr>
      </tfoot>
    </>
  );
};

// datatable
let dateTimeRange = {};
export const dateRange = (tbName) => {
  let start = moment();
  let end = moment();
  let ix = 1;
  // console.log("dateRange:", tbName);

  function cb(start, end) {
    // set time to 00:00:00
    $(".reportrange span").html("ស្វែងរកតាមកាលបរិច្ឆេទ");
    if (ix > 1) {
      // convert start and end time zone to UTC
      start = start.utc();
      end = end.utc();
      const date = {
        start: start.format("YYYY-MM-DDTHH:mm:ssZ"),
        end: end.format("YYYY-MM-DDTHH:mm:ssZ"),
      };
      // reverse start and end time zone to local
      // start = start.local();
      // console.log("start:", start.format("YYYY-MM-DDTHH:mm:ssZ"));
      dateRangeData(tbName, date);
    }
    ix++;
  }
  $(".reportrange").daterangepicker(
    {
      timePicker: true,
      startDate: moment().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
      endDate: moment().set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 99,
      }),
    },
    cb
  );
  cb(start, end);
};
// date range data submit
export const dateRangeData = (tbName, data = null) => {
  if (data != null) {
    // console.log("data:", data);
    dateTimeRange = data;
    $(`#${tbName}`).DataTable().ajax.reload(null, false);
  }
  // console.log("dateTimeRange:", dateTimeRange);
  return dateTimeRange;
};
// datatable
export const sumColumn = (d) => {
  function checkData(x) {
    if (typeof x === "string") {
      // console.log("x:", x);
      const sx = x.split(" ");
      if (sx.length > 1) {
        const sxn = sx[0].replace(/,/g, "") * 1;
        return {
          type: "string",
          value: sxn,
          currency: sx[1],
        };
      } else {
        const sxn = x.replace(/,/g, "") * 1;
        return { type: "string2", value: sxn, currency: "" };
      }
    } else if (typeof x === "number") {
      return { type: "number", value: x, currency: "" };
    } else {
      return { type: "null", value: 0, currency: "" };
    }
  }
  // check string have '<br>' or not
  if (d.includes("<br>")) {
    const brSplit = d.split("<br>");
    const count = brSplit.length;
    let multiple = [];
    if (count > 1) {
      for (let i = 0; i < count; i++) {
        multiple.push(checkData(brSplit[i]));
      }
    }
    return multiple;
  } else {
    return checkData(d);
  }
};
export const loadData = (data, data_arr = []) => {
  let footerCallbackFunction = {};
  if (data_arr.sumAmount != null) {
    footerCallbackFunction = {
      footerCallback: function () {
        const api = this.api();
        // function
        $.each(data_arr.sumAmount, function (index, value) {
          const moneyType = api
            .column(value, { page: "current" })
            .data()
            .map((x) => {
              // console.log("x:", x);
              return sumColumn(x);
            });

          const sumMoney = [];
          const checkRate = (currency) => {
            if (currency !== "") {
              const rate = data.currency.filter((item) => {
                // console.log("item:", item);
                return item.node.shortName === currency;
              });

              if (rate.length > 0) {
                // console.log("rate:", rate);
                return rate[0].node.rate.fixed;
              } else {
                return 1;
              }
            } else {
              return 1;
            }
          };
          moneyType.reduce(function (res, v) {
            if (Array.isArray(v)) {
              const count = v.length;
              for (let i = 0; i < count; i++) {
                if (!res[v[i].currency]) {
                  res[v[i].currency] = {
                    currency: v[i].currency,
                    sum_money: 0,
                    column: value,
                    rate: checkRate(v[i].currency),
                    type: v[i].type,
                  };
                  sumMoney.push(res[v[i].currency]);
                }
                res[v[i].currency].sum_money += v[i].value;
              }
            } else {
              if (!res[v.currency]) {
                res[v.currency] = {
                  currency: v.currency,
                  sum_money: 0,
                  column: value,
                  rate: checkRate(v.currency),
                  type: v.type,
                };
                sumMoney.push(res[v.currency]);
              }
              res[v.currency].sum_money += v.value;
            }
            return res;
          }, {});
          // console.log("sumMoney:", sumMoney);

          const count = sumMoney.length;
          let sumMoneyFormat = "";
          let exchangeRate = 0;
          for (let i = 0; i < count; i++) {
            exchangeRate += sumMoney[i].sum_money / sumMoney[i].rate;
            sumMoneyFormat += `${currencyFormat(
              sumMoney[i].sum_money,
              sumMoney[i].currency,
              2
            )}<br />`;
          }
          $(api.column(value).footer()).html(
            `${sumMoneyFormat}
            <h6 class="text-primary">${currencyFormat(
              exchangeRate,
              "Avg",
              2
            )}</h6>`
          );
        });
      },
    };
  } else {
    footerCallbackFunction = {
      footerCallback: function () {
        // console.log("footer callback null");
      },
    };
  }
  if (!$.fn.dataTable.isDataTable(`#${data.tbId}`)) {
    if (!Array.isArray(data.data)) {
      data.data = [data.data];
    }
    let autoWidth = true;
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      autoWidth = true;
    } else {
      autoWidth = false;
    }
    const columnDefsFunction = [];
    if (data_arr.order_false != null) {
      columnDefsFunction.push({
        orderable: false,
        targets: data_arr.order_false,
      });
    }

    if (data_arr.width_column) {
      $.each(data_arr.width_column, function (index, value) {
        columnDefsFunction.push({ width: value, targets: parseInt(index) });
      });
    }
    if (data_arr.columnHidden) {
      columnDefsFunction.push({
        targets: data_arr.columnHidden,
        visible: false,
      });
    }
    // console.log(data.tbId);
    // let draw = 0;
    $(`#${data.tbId}`).DataTable({
      responsive: false,
      autoWidth: autoWidth,
      lengthMenu: [
        [10, 25, 50, 100],
        [10, 25, 50, 100],
      ],
      ordering: true,
      paging: true,
      searching: true,
      dom: '<"row col-12 justify-content-between"lfB>rtip',
      buttons: [
        {
          extend: "colvis",
          postfixButtons: ["colvisRestore"],
        },
        {
          extend: "excelHtml5",
          footer: true,
        },
        {
          extend: "print",
          text: "Print",
          exportOptions: {
            columns: ":visible:not(.not-exported)",
            rows: ":visible",
          },
          footer: true,
        },
      ],
      processing: true,
      serverSide: true,
      order: [],
      ajax: {
        url: `https://landscape.aimsarik.com/gql/`,
        type: "POST",
        headers: {
          // jwt token
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        contentType: "application/json",
        data: function (d) {
          // console.log("test:", d);

          let date_picker = "";
          if (dateTimeRange.start !== undefined) {
            date_picker = `date_Created_Range: ["${dateTimeRange.start}", "${dateTimeRange.end}"]`;
            // console.log("date picker", date_picker);
          } else {
            // console.log("no date picker");
          }
          // convert start to after by base 64
          let after = "";
          if (d.start > 0) {
            after = btoa(`arrayconnection:${d.start - 1}`);
          }
          const orderColumn = data_arr.orderColumn;
          let orderBy = "-id";
          if (d.order.length > 0) {
            const order = d.order[0];
            if (order.dir === "asc") {
              orderBy = orderColumn[order.column];
            } else {
              orderBy = `-${orderColumn[order.column]}`;
            }
          }
          let searching = "";
          if (d.search.value !== "") {
            data_arr.searching.map((item, index) => {
              if (index === 0) {
                searching += `${item}: "${d.search.value}"`;
              } else {
                searching += `, ${item}: "${d.search.value}"`;
              }
              return searching;
            });
          }
          let otherOption = "";
          if (data_arr.otherOption) {
            $.each(data_arr.otherOption, function (key, value) {
              otherOption += `\n${key}: ${value}`;
            });
          }

          const gql = `query {
            ${data.load_page}(
              orderBy: "${orderBy}"
              first: ${d.length}
              after: "${after}"
              ${searching}
              ${otherOption}
              ${date_picker}
            ) ${data.gql}
          }`;
          // console.log("gql:", gql);

          /* const query = {
            "query":gql,
            "variables": {}
          }; */
          return JSON.stringify({
            query: gql,
          });
        },
        dataSrc: function (result) {
          if (typeof result.data != "undefined") {
            // var data_obj = data_execute(result, data.load_page);
            // var data_obj = [result, data.load_page];
            var data_obj = data_arr.data_execute(result, data.load_page);
            return data_obj;
          } else {
            return [];
          }
        },
      },
      // columns: data.columns,
      rowCallback: function (row, data, index) {
        // var info = this.api().page.info();
        // console.log("info:", info);
        // var page = info.page;
        // var length = info.length;
        /* if (data_arr.page_name === "coa") {
          // console.log(index);
          // console.log(data);
          if (data[1] === "colspan") {
            // $('td:eq(0)', row).html(index);
            $("td:eq(0)", row).attr("colspan", 5).css("text-align", "center");
            $("td:eq(1)", row).css("display", "none");
            $("td:eq(2)", row).css("display", "none");
            $("td:eq(3)", row).css("display", "none");
            $("td:eq(4)", row).css("display", "none");
          }
        } */
      },
      columnDefs: columnDefsFunction,
      footerCallback: footerCallbackFunction.footerCallback,
    });
  }
};
export const loadDataCloseReport = (data, data_arr = []) => {
  // console.log("data:", data);
  // console.log("data_arr:", data_arr);
  let footerCallbackFunction = {};
  if (data_arr.sumAmount != null) {
    footerCallbackFunction = {
      footerCallback: function () {
        const api = this.api();
        // function
        // sum data by column
        $.each(data_arr.sumAmount, function (index, value) {
          const moneyType = api
            .column(value, { page: "current" })
            .data()
            .map((x) => {
              return sumColumn(x);
            });

          const sumMoney = [];
          const checkRate = (currency) => {
            if (currency !== "") {
              const rate = data.currency.filter((item) => {
                return item.node.shortName === currency;
              });
              if (rate.length > 0) {
                return rate[0].node.rate.fixed;
              } else {
                return 1;
              }
            } else {
              return 1;
            }
          };
          moneyType.reduce(function (res, v) {
            // console.log(res[v.currency]);
            // check v is array or not
            if (Array.isArray(v)) {
              const count = v.length;
              for (let i = 0; i < count; i++) {
                if (!res[v[i].currency]) {
                  res[v[i].currency] = {
                    currency: v[i].currency,
                    sum_money: 0,
                    column: value,
                    rate: checkRate(v[i].currency),
                    type: v[i].type,
                  };
                  sumMoney.push(res[v[i].currency]);
                }
                res[v[i].currency].sum_money += v[i].value;
              }
            } else {
              if (!res[v.currency]) {
                res[v.currency] = {
                  currency: v.currency,
                  sum_money: 0,
                  column: value,
                  rate: checkRate(v.currency),
                  type: v.type,
                };
                sumMoney.push(res[v.currency]);
              }
              res[v.currency].sum_money += v.value;
            }
            return res;
          }, {});
          // console.log("sumMoney:", sumMoney);

          const count = sumMoney.length;
          let sumMoneyFormat = "";
          let exchangeRate = 0;
          for (let i = 0; i < count; i++) {
            exchangeRate += sumMoney[i].sum_money / sumMoney[i].rate;
            sumMoneyFormat += `${currencyFormat(
              sumMoney[i].sum_money,
              sumMoney[i].currency,
              2
            )}<br />`;
          }
          $(api.column(value).footer()).html(
            `${sumMoneyFormat}
            <h6 class="text-primary">${currencyFormat(
              exchangeRate,
              "Avg",
              2
            )}</h6>`
          );
        });
      },
    };
  } else {
    footerCallbackFunction = {
      footerCallback: function () {
        // console.log("footer callback null");
      },
    };
  }
  if (!$.fn.dataTable.isDataTable(`#${data.tbId}`)) {
    if (!Array.isArray(data.data)) {
      data.data = [data.data];
    }
    let autoWidth = true;
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      autoWidth = true;
    } else {
      autoWidth = false;
    }
    const columnDefsFunction = [];
    if (data_arr.order_false != null) {
      columnDefsFunction.push({
        orderable: false,
        targets: data_arr.order_false,
      });
    }

    if (data_arr.width_column) {
      $.each(data_arr.width_column, function (index, value) {
        columnDefsFunction.push({ width: value, targets: parseInt(index) });
      });
    }
    if (data_arr.columnHidden) {
      columnDefsFunction.push({
        targets: data_arr.columnHidden,
        visible: false,
      });
    }
    // close sale list (close report)
    // console.log(data.tbId);
    function processDoc(doc) {
      /* pdfMake.fonts = {
        // All 4 components must be defined
        Battambang: {
          normal: "Battambang-Regular.ttf",
          bold: "Battambang-Bold.ttf",
          italics: "Battambang-Light.ttf",
          bolditalics: "Battambang-Thin.ttf",
        },
      }; */
      doc.defaultStyle.font = "Battambang";
    }
    let landscape = "";
    if (data.tbId === "tb_sale_income_close") {
      landscape = "landscape";
    }
    const data_table_info = {
      lengthMenu: [[-1], ["All"]],
      ordering: false,
      paging: false,
      searching: false,
    };
    const buttons = [
      {
        extend: "colvis",
      },
      {
        extend: "pdfHtml5",
        orientation: landscape,
        pageSize: "A4",
        /* action: function ( e, dt, node, config ){
          Swal.fire({
            title: 'បិទបញ្ជី?',
            html: `របាយការណ៍ត្រូវបានបិទបន្ទាប់ពីអ្នកយល់ព្រម`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'បោះបង់',
            confirmButtonText: 'យល់ព្រម'
          }).then((result) => {
            if (result.isConfirmed) {
              // $.fn.dataTable.ext.buttons.pdfHtml5.action(e, dt, button, config);
              $.fn.dataTable.ext.buttons.pdfHtml5.action.call(this, e, dt, node, config);
            }
          })
        }, */
        footer: true,
        customize: function (doc) {
          processDoc(doc);
          // doc.defaultStyle.font = 'Battambang';
        },
        /* exportOptions: {
          stripNewlines: false
        } */
        // messageTop: 'PDF created by PDFMake with Buttons for DataTables.',
        // text: 'បិទបញ្ជី',
        // add style to pdf button
        // className: 'btn-success-dt',
        // title: 'Title'
      },
    ];

    // let draw = 0;
    $(`#${data.tbId}`).DataTable({
      responsive: false,
      autoWidth: autoWidth,
      ...data_table_info,
      dom: '<"row col-12 justify-content-between"lfB>rtip',

      buttons: buttons,
      processing: true,
      serverSide: true,
      order: [],
      ajax: {
        url: `https://landscape.aimsarik.com/gql/`,
        type: "POST",
        headers: {
          // jwt token
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
        contentType: "application/json",
        data: function (d) {
          let orderBy = "-id";
          let otherOption = "";
          if (data_arr.otherOption) {
            $.each(data_arr.otherOption, function (key, value) {
              otherOption += `\n${key}: ${value}`;
            });
          }
          const gql = `query {
            ${data.load_page}(
              orderBy: "${orderBy}"
              ${otherOption}
            ) ${data.gql}
          }`;
          // console.log("gql:", gql);

          /* const query = {
            "query":gql,
            "variables": {}
          }; */
          return JSON.stringify({
            query: gql,
          });
        },
        dataSrc: function (result) {
          if (typeof result.data != "undefined") {
            // var data_obj = data_execute(result, data.load_page);
            // var data_obj = [result, data.load_page];
            var data_obj = data_arr.data_execute(result, data.load_page);
            return data_obj;
          } else {
            return [];
          }
        },
      },
      // columns: data.columns,
      rowCallback: function (row, data, index) {
        // var info = this.api().page.info();
        // console.log("info:", info);
        // var page = info.page;
        // var length = info.length;
        /* if (data_arr.page_name === "coa") {
          // console.log(index);
          // console.log(data);
          if (data[1] === "colspan") {
            // $('td:eq(0)', row).html(index);
            $("td:eq(0)", row).attr("colspan", 5).css("text-align", "center");
            $("td:eq(1)", row).css("display", "none");
            $("td:eq(2)", row).css("display", "none");
            $("td:eq(3)", row).css("display", "none");
            $("td:eq(4)", row).css("display", "none");
          }
        } */
      },
      columnDefs: columnDefsFunction,
      footerCallback: footerCallbackFunction.footerCallback,
    });
  }
};
