import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";

export default function Loading() {
  const navigate = useNavigate();
  const initialized = useRef(false);
  const page_name = "products_list";
  const tbId = `tb_${page_name}`;
  const formOption = [
    <Link
      to={`/post/${page_name}?action=create_payment`}
      className="btn btn-success pull-right mx-1 createPayment"
      key="createPayment"
    >
      ទូទាត់
    </Link>,
    <Link
      to={`/post/${page_name}?action=create_delivery_note`}
      className="btn btn-success pull-right mx-1 createDeliveryNote"
      key="createDeliveryNote"
    >
      ប្រគល់ទំនិញ
    </Link>,
    <Link
      to={`/post/${page_name}?action=create_invoice`}
      className="btn btn-success pull-right mx-1 createInvoice`"
      key="createInvoice`"
    >
      វិក្កយបត្រ
    </Link>,
    <Link
      to={`/post/${page_name}?action=create_quotation`}
      className="btn btn-success pull-right mx-1 createQuotation"
      key="createQuotation"
    >
      តារាងតម្លៃ
    </Link>,
    <Link
      to={`/post/${page_name}?action=create_po`}
      className="btn btn-success pull-right mx-1 createPo"
      key="createPo"
    >
      បញ្ជាទិញ
    </Link>,
    <Link
      to={`/post/${page_name}?action=add`}
      className="btn btn-success pull-right mx-1 addRow"
      key="addRow"
    >
      <i className="fa fa-plus" />
    </Link>,
  ];
  const thead = aim.datatableHeadFoot(
    ["លរ", "ព័ត៌មាន​", "ទឹកប្រាក់សរុប", "បញ្ចុះតម្លៃ", "នៅសល់", "សកម្មភាព"],
    1
  );
  const sumAmount = [2, 3, 4];

  const curr = aim.useGetCurrency();
  // console.log("curr:", curr);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      aim.loadData(
        {
          // columns: columnLoad,
          currency: curr,
          tbId: tbId,
          page_name: page_name,
          load_page: "allProductsInfo",
          gql: `{
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            totalCount
            edgeCount
            edges {
              cursor
              node {
                id
                invoice
                invoiceManual
                company
                customer
                customerPhone
                customerAddress
                sale
                salePhone
                currency {
                  shortName
                }
                productdetailSet {
                  sumTotal
                  sumAfterDiscount
                }
                processSet {
                  edges {
                    node {
                      poStatus
                      quotationStatus
                      invoiceStatus
                      deliveryStatus
                      paymentStatus
                    }
                  }
                }
              }
            }
          }`,
        },
        {
          // link: link,
          page_name: page_name,
          function_load: `${page_name}_datatable`,
          sumAmount: sumAmount,
          order_false: [1, 2, 3],
          width_column: {
            1: "100px",
          },
          columnHidden: [],
          orderColumn: ["id", "", "", "", "", ""],
          searching: ["phone_Icontains"],
          // function
          data_execute: (result, load_page) => {
            // console.log("result:", result, "load_page:", load_page);
            const data_tb_row = [];
            result.recordsTotal = result.data[load_page].totalCount;
            result.recordsFiltered = result.data[load_page].totalCount;
            // console.log(result.recordsTotal);
            // forEach
            let number = 1;
            result.data[load_page].edges.forEach((v) => {
              // base 64 decode id
              // const id = atob(v.node.id);
              // get id
              // const id_get = id.split(":")[1];

              // set status
              let printAction = `<div class="dropdown-item-action">
                  <span class="w-100 btn btn-primary">
                    <i class="fa fa-pencil-square-o printData" aria-hidden="true" data-edit="{'id':'${v.node.id}','page':'${page_name}','status':'products_list'}"> ព្រីនតារាងរួម</i>
                  </span>
                </div>`;
              let status = "តារាងរួម";
              v.node.processSet.edges.forEach((vs) => {
                const { node } = vs;

                if (node.poStatus === 1) {
                  status = "បញ្ជាទិញ";
                  printAction += `<div class="dropdown-item-action">
                    <span class="w-100 btn btn-primary">
                      <i class="fa fa-pencil-square-o printData" aria-hidden="true" data-edit="{'id':'${v.node.id}','page':'${page_name}','status':'po'}"> ព្រីនបញ្ជាទិញ</i>
                    </span>
                  </div>`;
                }
                if (node.quotationStatus === 1) {
                  status = "តារាងតម្លៃ";
                  printAction += `<div class="dropdown-item-action">
                    <span class="w-100 btn btn-primary">
                      <i class="fa fa-pencil-square-o printData" aria-hidden="true" data-edit="{'id':'${v.node.id}','page':'${page_name}','status':'quotation'}
                      "> ព្រីនតារាងតម្លៃ</i>
                    </span>
                  </div>`;
                }
                if (node.invoiceStatus === 1) {
                  status = "វិក្កយបត្រ";
                  printAction += `<div class="dropdown-item-action">
                    <span class="w-100 btn btn-primary">
                      <i class="fa fa-pencil-square-o printData" aria-hidden="true" data-edit="{'id':'${v.node.id}','page':'${page_name}','status':'invoice'}
                      "> ព្រីនវិក្កយបត្រ</i>
                    </span>
                  </div>`;
                }
                if (node.deliveryStatus === 1) {
                  status = "ប្រគល់ទំនិញ";
                  printAction += `<div class="dropdown-item-action">
                    <span class="w-100 btn btn-primary">
                      <i class="fa fa-pencil-square-o printData" aria-hidden="true" data-edit="{'id':'${v.node.id}','page':'${page_name}','status':'delivery_note'}
                      "> ព្រីនប្រគល់ទំនិញ</i>
                    </span>
                  </div>`;
                }
                if (node.paymentStatus === 1) {
                  status = "ទូទាត់";
                  printAction += `<div class="dropdown-item-action">
                    <span class="w-100 btn btn-primary">
                      <i class="fa fa-pencil-square-o printData" aria-hidden="true" data-edit="{'id':'${v.node.id}','page':'${page_name}','status':'payment'}
                      "> ព្រីនទូទាត់</i>
                    </span>
                  </div>`;
                }
              });

              let action = printAction;
              action = aim.action_group(action);

              var tb_row = [
                `${number++}`,
                `លេខកូដ: ${v.node.invoice}<br>
                វិក្កយបត្រ: ${v.node.invoiceManual}<br>
                ក្រុមហ៊ុន: ${v.node.company}<br>
                ​អតិថិជន: ${v.node.customer}<br>
                លេខទូរស័ព្ទ: ${v.node.customerPhone}<br>
                អាសយដ្ឋាន: ${v.node.customerAddress}<br>
                អ្នកលក់: ${v.node.sale}<br>
                ស្ថានភាព: <b style="font-size:1rem">${status}</b>`,
                `${aim.currencyFormat(
                  v.node.productdetailSet.sumTotal,
                  v.node.currency.shortName
                )}`,
                `${aim.currencyFormat(
                  v.node.productdetailSet.sumTotal -
                    v.node.productdetailSet.sumAfterDiscount,
                  v.node.currency.shortName
                )}`,
                `${aim.currencyFormat(
                  v.node.productdetailSet.sumAfterDiscount,
                  v.node.currency.shortName
                )}`,
                `${action}`,
              ];
              data_tb_row.push(tb_row);
            });
            return data_tb_row;
          },
        }
      );
      // document.queryselector .editData
      document.querySelector(`#${tbId}`).addEventListener("click", (e) => {
        const el = e.target;
        if (el.classList.contains("editData")) {
          const data_edit = JSON.parse(el.dataset.edit.replace(/'/g, '"'));
          const { id, page } = data_edit;
          navigate(`/post/${page}?action=edit&id=${id}`);
        } else if (el.classList.contains("printData")) {
          const data_edit = JSON.parse(el.dataset.edit.replace(/'/g, '"'));
          const { id, page, status } = data_edit;
          navigate(`/post/${page}?action=print&id=${id}&status=${status}`);
        }
      });
    }
  });
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          {/* form_header */}
          {aim.header_option(formOption)}
          {/* form_report_content */}
          {aim.body(tbId, thead)}
        </div>
      </div>
    </div>
  );
}
