// step 1
// 1. update side bar
// 2. update the page in App.js
import { useContext, useEffect, useRef, useState } from "react";
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthContext } from "../../appState/AuthProvider";
import { Link } from "react-router-dom";
// step 2
export default function Table() {
  // get user from AuthContext
  const { user } = useContext(AuthContext);
  const [tables, setTables] = useState([]);

  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      // console.log("user", user);
      let business = "";
      user.business.forEach((x) => {
        business += `"${x.business}",`;
      });
      // console.log("business", business);

      // get currency default
      const currencyDefault = aim.useGetCurrencyDefault();
      const cd = currencyDefault[0].node;

      const gql = `query TableInfo {
        allTable(business_Business_In: [${business}]) {
          edges {
            node {
              id
              name
              number
              tableType
              status
              business {
                id
                business
              }
              saleorderSet(status: 0) {
                edges {
                  node {
                    total
                    status
                    type
                    saleInformation {
                        id
                        name
                        price
                        currency {
                          id
                          nameEn
                          shortName
                          round
                          rate {
                            fixed
                          }
                          isDefault
                        }
                      }
                      stock {
                        id
                        name
                        price
                        originalPrice
                        currency {
                          id
                          nameEn
                          shortName
                          round
                          rate {
                            fixed
                          }
                          isDefault
                        }
                      }
                  }
                }
              }
            }
          }
        }
      }`;
      const callback = (data) => {
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
        } else {
          let tableP = [];
          if (data.data.allTable.edges && data.data.allTable.edges.length > 0) {
            data.data.allTable.edges.forEach((x) => {
              if (x.node.status === 0) {
                x.tableSource = "bg-success";
              } else if (x.node.status === 1) {
                x.tableSource = "bg-danger";
              } else if (x.node.status === 2) {
                x.tableSource = "bg-warning";
              }
              const d = x.node.saleorderSet.edges;
              const food = d.filter((x) => x.node.type !== "stock");
              const stock = d.filter((x) => x.node.type === "stock");

              food.sort((a, b) =>
                a.node.saleInformation.name.localeCompare(
                  b.node.saleInformation.name
                )
              );
              stock.sort((a, b) =>
                a.node.stock.name.localeCompare(b.node.stock.name)
              );
              let total = 0;
              food.forEach((x) => {
                const exchange = aim.exchangeRate(
                  x.node.saleInformation.currency.isDefault,
                  x.node.saleInformation.currency.rate.fixed,
                  cd
                );
                total += x.node.total * exchange;
              });
              stock.forEach((x) => {
                const exchange = aim.exchangeRate(
                  x.node.stock.currency.isDefault,
                  x.node.stock.currency.rate.fixed,
                  cd
                );
                total += x.node.total * exchange;
              });

              tableP.push(
                <div
                  key={x.node.name}
                  className="col-6 col-sm-3 my-2"
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className={`info-box ${x.tableSource}`}
                    style={{ minHeight: "100%" }}
                  >
                    <div className="info-box-content">
                      <span className="info-box-text">
                        <Link
                          to={`/sale_order?table=${x.node.id}&table_name=${x.node.name}&table_type=${x.node.tableType}&business=${x.node.business.business}`}
                          style={{ color: "white" }}
                        >
                          <h4 className="info-box-text">{x.node.name}</h4>
                          {
                            <span className="info-box-number">
                              សរុប: {aim.currencyFormat(total, cd.shortName, 2)}
                            </span>
                          }
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              );
            });
          } else {
            const noTable = (
              <div className="row justify-content-center">
                <div className="text-center col-4 col-sm-3">
                  <Link
                    to="/post/table?action=add"
                    id="NewTable"
                    className="more_info"
                  >
                    <i className="fa fa-plus" style={{ fontSize: "128px" }}></i>
                    <br />
                    <span>បង្កើតតុ</span>
                  </Link>
                </div>
              </div>
            );
            setTables(noTable);
          }
          setTables(tableP);
        }
      };
      aim.jquery_free(gql, {}, callback);
    }
  }, [user]);
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          <div className="form_action" id="form_action_table_sale">
            <div id="tableInfo">
              <div id="info" className="row justify-content-between pt-3">
                <div className="col-5 pl-5">
                  <span className="text-success">
                    <b>F - ទំនេរ </b>
                  </span>
                  <span className="text-danger">
                    <b>G - មានភ្ញៀវ </b>
                  </span>
                  <span className="text-warning">
                    <b>B - បានកក់ទុក</b>
                  </span>
                </div>
                <div className="col-4">
                  <div className="card collapsed-card cursor-pointer">
                    <div
                      className="card-header bg-primary"
                      style={{ cursor: "pointer" }}
                      data-card-widget="collapse"
                    >
                      <h3 className="card-title">ជ្រើសរើសសកម្មភាព</h3>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-plus" />
                        </button>
                      </div>
                    </div>
                    <div className="card-body p-0" style={{ display: "none" }}>
                      <ul className="nav nav-pills flex-column">
                        <li className="nav-item">
                          <div className="nav-link">
                            <i className="far fa-circle text-warning" /> កក់ទុក
                          </div>
                        </li>
                        <li className="nav-item">
                          <div className="nav-link">
                            <i className="far fa-circle text-primary" /> ប្ដូរតុ
                          </div>
                        </li>
                        <li className="nav-item">
                          <div className="nav-link">
                            <i className="far fa-circle text-primary" /> រួមតុ
                          </div>
                        </li>
                        <li className="nav-item">
                          <div className="nav-link">
                            <i className="far fa-circle text-info" /> លុបការកក់
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row justify-content-md-center"
                id="row_filter_table"
              >
                <div className="input-group mt-3 col-6 d-none">
                  <div className="input-group-prepend">
                    <span className="input-group-text">លេខតុ</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="filter_number"
                    name="filter_number"
                    autoComplete="off"
                    onClick={(e) => e.target.select()}
                  />
                </div>
              </div>
              <div className="row all_table">{tables}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
