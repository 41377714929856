import React, { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./order.css";
import { AuthContext } from "../../appState/AuthProvider";
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CallbackCheck } from "../../postData/HandleFunction";

export default function Table() {
  const [searchParams] = useSearchParams();
  const [Count, setCount] = useState(0);
  const initialized = useRef(false);
  const $ = window.$;

  const { user } = useContext(AuthContext);
  const business = searchParams.get("business");
  const table = searchParams.get("table");
  const table_type = searchParams.get("table_type");
  const table_name = searchParams.get("table_name");
  // tap_panes_content("typeFood", "foodCategoryFlex", "foodItem")
  function tap_panes_content(name) {
    const flexslider_name = `flexslider carousel flexslider_${name}`;
    const name_category_slide = `${name}_category_slide`;
    const itemName = `${name}Item`;
    const typeName = `type${name}`;
    const categoryName = `${name}CategoryFlex`;
    const data = (
      <div>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">ប្រភេទ</span>
          </div>
          <select
            name="type"
            id={typeName}
            className="form-control custom-select"
          >
            <option value="All">ទាំងអស់</option>
          </select>
        </div>
        <div id={categoryName}>
          <div className={flexslider_name}>
            <ul className="slides" id={name_category_slide}>
              <li data-item="All" style={{ cursor: "pointer" }}>
                <div
                  className="d-flex align-items-center justify-content-center bg-primary border border-dark rounded"
                  style={{ width: 100, height: 100 }}
                >
                  <span>ទាំងអស់</span>
                </div>
                <br />
                <div className="text-center" style={{ marginTop: "-12px" }}>
                  ទាំងអស់
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="row justify-content-center my-4" id={itemName}></div>
      </div>
    );
    return data;
  }
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const currencyDefault = aim.useGetCurrencyDefault();
      const cd = currencyDefault[0].node;
      const getOrder = () => {
        const gql = `query MyQuery {
          allSaleOrder(status: 0, table_Name: "${table_name}") {
            edges {
              node {
                type
                saleInformation {
                  id
                  name
                  price
                  currency {
                    id
                    nameEn
                    shortName
                    round
                    rate {
                      fixed
                    }
                    isDefault
                  }
                }
                stock {
                  id
                  name
                  price
                  originalPrice
                  currency {
                    id
                    nameEn
                    shortName
                    round
                    rate {
                      fixed
                    }
                    isDefault
                  }
                }
                id
                quantity
                price
                total
                description
                status
                delete
                date {
                  created
                  creator {
                    username
                  }
                }
                saleorderoptionSet {
                  AddOption {
                    name
                  }
                }
                saleorderextraSet {
                  AddExtra {
                    title
                    price
                  }
                  price
                }
                table {
                  id
                  name
                  number
                  tableType
                  amount
                  status
                }        
              }
            }
          }
        }`;
        const callback = (data) => {
          if (data.errors) {
            withReactContent(Swal).fire({
              icon: "error",
              title: "មានបញ្ហា",
              html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
              confirmButtonText: "យល់ព្រម",
            });
          } else {
            const d = data.data.allSaleOrder.edges;
            // separate type of product if not null
            const food = d.filter((v) => v.node.type !== "stock");
            const stock = d.filter((v) => v.node.type === "stock");
            // sort by name
            food.sort((a, b) =>
              a.node.saleInformation.name.localeCompare(
                b.node.saleInformation.name
              )
            );
            stock.sort((a, b) =>
              a.node.stock.name.localeCompare(b.node.stock.name)
            );
            let list = "";
            let total = 0;
            let prepareGroup = [];
            let prepareGroupStock = [];
            let countOptionExtra = 0;
            food.forEach((x) => {
              // check currency is default or not
              let addOption = "";
              if (x.node.saleorderoptionSet.length > 0) {
                x.node.saleorderoptionSet.forEach((v) => {
                  addOption += `<br><span class="text-success">${v.AddOption.name}</span> `;
                });
              }
              const exchange = aim.exchangeRate(
                x.node.saleInformation.currency.isDefault,
                x.node.saleInformation.currency.rate.fixed,
                cd
              );
              let addExtra = "";
              let extraPrice = "";
              if (x.node.saleorderextraSet.length > 0) {
                x.node.saleorderextraSet.forEach((v) => {
                  addExtra += `<br><span class="text-danger">${v.AddExtra.title}</span> `;
                  extraPrice += `<br>${aim.multiple_digits(
                    parseFloat(v.price) * exchange,
                    2
                  )}`;
                });
              }
              if (addOption !== "" || addExtra !== "") {
                list += `<tr>
                    <td>
                        <i class="fa fa-minus-square close transfer orderId" data-table="1" data-sale_type="food" data-pro_id="2" data-quantity="3" data-submit="4" style="color:red; font-size: 28px"></i>
                    </td>
                    <td>${
                      x.node.saleInformation.name
                    }${addExtra}${addOption}</td>
                    <td>${aim.addCommas(
                      x.node.quantity
                    )} <span class="text-info h6">x</span> </td>
                    <td>${aim.multiple_digits(
                      x.node.price * exchange,
                      2
                    )}${extraPrice}</td>
                    <td>${aim.multiple_digits(x.node.total * exchange, 2)}</td>
                </tr>`;
                total += parseFloat(x.node.total * exchange);
                countOptionExtra += 1;
              } else {
                prepareGroup.push(x);
              }
            });
            // group node.saleInformation.name and node.price
            const group = prepareGroup.reduce((r, a) => {
              const key = a.node.price + "|" + a.node.saleInformation.name;
              r[key] = r[key] || [];
              r[key].push(a);
              return r;
            }, Object.create(null));

            // sum quantity and total from group
            const sumGroup = Object.keys(group).map((v) => {
              let quantity = 0;
              let total = 0;
              group[v].forEach((x) => {
                quantity += x.node.quantity;
                total += parseFloat(x.node.total);
              });
              return { quantity, total, group: group[v] };
            });

            stock.forEach((x) => {
              prepareGroupStock.push(x);
            });
            // group node.stock.name and node.price
            const stockGroup = prepareGroupStock.reduce((r, a) => {
              const key = a.node.price + "|" + a.node.stock.name;
              r[key] = r[key] || [];
              r[key].push(a);
              return r;
            }, Object.create(null));

            // sum quantity and total from group
            const sumGroupStock = Object.keys(stockGroup).map((v) => {
              let quantity = 0;
              let total = 0;
              stockGroup[v].forEach((x) => {
                quantity += x.node.quantity;
                total += parseFloat(x.node.total);
              });
              return { quantity, total, group: stockGroup[v] };
            });

            $("#noti .box1").html(
              `<h3 class="btn btn-primary">តុ ${table_name}  <i class="fa fa-bell text-danger"></i> <span class="num">${
                countOptionExtra + sumGroup.length + sumGroupStock.length
              }</span></h3>`
            );

            for (const [key, value] of Object.entries(sumGroup)) {
              const d = value.group;
              const exchange = aim.exchangeRate(
                d[0].node.saleInformation.currency.isDefault,
                d[0].node.saleInformation.currency.rate.fixed,
                cd
              );

              list += `<tr>
                  <td data-title="${key}">
                      <i class="fa fa-minus-square close transfer orderId" data-table="1" data-sale_type="food" data-pro_id="2" data-quantity="3" data-submit="4" style="color:red; font-size: 28px"></i>
                  </td>
                  <td>${d[0].node.saleInformation.name}</td>
                  <td>${aim.addCommas(
                    value.quantity
                  )} <span class="text-info h6">x</span> </td>
                  <td>${aim.multiple_digits(d[0].node.price * exchange, 2)}</td>
                  <td>${aim.multiple_digits(value.total * exchange, 2)}</td>
              </tr>`;
              total += parseFloat(value.total * exchange);
            }

            for (const [key, value] of Object.entries(sumGroupStock)) {
              const d = value.group;
              const exchange = aim.exchangeRate(
                d[0].node.stock.currency.isDefault,
                d[0].node.stock.currency.rate.fixed,
                cd
              );

              list += `<tr>
                  <td data-title="${key}">
                      <i class="fa fa-minus-square close transfer orderId" data-table="1" data-sale_type="food" data-pro_id="2" data-quantity="3" data-submit="4" style="color:red; font-size: 28px"></i>
                  </td>
                  <td>${d[0].node.stock.name}</td>
                  <td>${aim.addCommas(
                    value.quantity
                  )} <span class="text-info h6">x</span> </td>
                  <td>${aim.multiple_digits(d[0].node.price * exchange, 2)}</td>
                  <td>${aim.multiple_digits(value.total * exchange, 2)}</td>
              </tr>`;
              total += parseFloat(value.total * exchange);
            }

            const data_order = `
            <div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                          <th></th>
                          <th>ឈ្មោះ</th>
                          <th>ចំនួន</th>
                          <th>តម្លៃ</th>
                          <th>សរុប</th>
                        </tr>
                    </thead>
                    <tbody>
                      ${list}
                    </tbody>
                </table>
                <div>
                  <div class="row w-100 mt-3">
                    <div class="col-7">
                      <h5 class="text-center">សរុបសាច់ប្រាក់</h5>
                    </div>
                    <div class="col-5">
                      <h5 class="text-primary">${aim.currencyFormat(
                        total,
                        cd.shortName,
                        2
                      )}</h5>
                    </div>
                  </div>
                </div>`;
            $(".data_order_list").html(data_order);
          }
        };
        aim.jquery_free(gql, {}, callback);
      };
      let drinkStatus = 0;
      let foodStatus = 0;
      let stockStatus = 0;
      let otherStatus = 0;
      let setStatus = 0;
      let allItemsType = {};
      const loadData = (tap_name) => {
        if (tap_name === "drink") {
          drinkStatus = 1;
        } else if (tap_name === "food") {
          foodStatus = 1;
        } else if (tap_name === "stock") {
          stockStatus = 1;
        } else if (tap_name === "other") {
          otherStatus = 1;
        } else if (tap_name === "set") {
          setStatus = 1;
        }
        let gql = ``;
        if (tap_name === "stock") {
          gql = `query MyQuery {
            allBusiness(business_In: ["${business}"]) {
              edges {
                node {
                  id
                  business
                  categorySet {
                    edges {
                      node {
                        id
                        name
                        productSet {
                          edges {
                            node {
                              id
                              name
                              price
                              originalPrice
                              image
                              status
                              currency {
                                id
                                shortName
                                round
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }`;
        } else {
          gql = `query MyQuery {
            allBusiness(business_In: ["${business}"]) {
              edges {
                node {
                  id
                  business
                  saleinformationtypeSet(name_Icontains: "${tap_name}") {
                    edges {
                      node {
                        id
                        name
                        saleinformationcategorySet {
                          id
                          name
                          saleinformationSet {
                            edges {
                              node {
                                id
                                name
                                price
                                image
                                status
                                addoptionSet {
                                  id
                                  name
                                }
                                addextraSet {
                                  id
                                  title
                                  price
                                }
                                currency {
                                  id
                                  shortName
                                  round
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }`;
        }
        const callback = (data) => {
          if (data.errors) {
            withReactContent(Swal).fire({
              icon: "error",
              title: "មានបញ្ហា",
              html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
              confirmButtonText: "យល់ព្រម",
            });
          } else {
            const d = data.data.allBusiness.edges;
            let item = "";
            // select option category of product
            const typeOfItem = (dataName) => {
              const typeOfItemData = `<option>${dataName}</option>`;
              $(`#type${tap_name}`).append(typeOfItemData);
              // type of food flex slider
              const itemCategoryFlex = `<li data-item="${dataName}" class="${dataName}" style="cursor: pointer">
                <div class="d-flex align-items-center justify-content-center border border-dark rounded" style="width: 100px; height: 100px">
                <img class="fit" alt="" src="/dist/img/noimage.jpg" draggable="false">
                </div><br>
                <div class="text-center" style="margin-top:-12px">${dataName}</div>
              </li>`;
              // flex slider category image
              $(`#${tap_name}_category_slide`).append(itemCategoryFlex);
            };
            const productItem = (d) => {
              // food item image
              let img = "/dist/img/noimage.jpg";
              if (d.pImage) {
                img = `https://landscape.aimsarik.com/media/${d.pImage}`;
              }
              item += `<div class="col-md-3 col-sm-2 ${d.cName} chooseType mb-5 text-center" style="cursor: pointer; height: 100px;">
                <img 
                  class="products fit" 
                  data-type="${tap_name}"
                  data-category_id="${d.cId}"
                  data-id="${d.pId}" 
                  alt="" 
                  src="${img}" 
                  width="100" height="100"><br>
                <p class="text-center mt-2">${d.pName} - ${d.cName}</p>
              </div>`;
            };
            d.forEach((e) => {
              if (tap_name === "stock") {
                let allItems = [];
                e.node.categorySet.edges.forEach((x) => {
                  allItems.push(x.node);
                  typeOfItem(x.node.name);
                  // loop through the item
                  x.node.productSet.edges.forEach((v) => {
                    const pIdata = {
                      cId: x.node.id,
                      cName: x.node.name,
                      pId: v.node.id,
                      pName: v.node.name,
                      pImage: v.node.image,
                    };
                    productItem(pIdata);
                  });
                });
                // add allItems to tap_name array
                // add allItems to tap_name array
                allItemsType[tap_name] = allItems;
              } else {
                e.node.saleinformationtypeSet.edges.forEach((x) => {
                  // convert x.node.name to case sensitive
                  const productType = x.node.name.toLowerCase();
                  if (productType === tap_name) {
                    // type of food select option with unique value
                    let allItems = [];
                    x.node.saleinformationcategorySet.forEach((e) => {
                      allItems.push(e);
                      typeOfItem(e.name);
                      // loop through the item
                      e.saleinformationSet.edges.forEach((v) => {
                        const pIdata = {
                          cId: e.id,
                          cName: e.name,
                          pId: v.node.id,
                          pName: v.node.name,
                          pImage: v.node.image,
                        };
                        productItem(pIdata);
                      });
                    });
                    // add allItems to tap_name array
                    allItemsType[tap_name] = allItems;
                  }
                });
              }
              // add item to the div
              $(`#${tap_name}Item`).html(item);
              // flex
              $(`.flexslider_${tap_name}`).flexslider({
                animation: "slide",
                animationLoop: false,
                itemWidth: 100,
                itemMargin: 5,
                mousewheel: true,
                minItems: 5,
                slideshow: false,
                controlNav: true,
                directionNav: false,
                move: 2,
                start: function (slider) {
                  $("body").removeClass("loading");
                },
              });
              $(".flexslider").swipe({
                swipeLeft: function (
                  event,
                  direction,
                  distance,
                  duration,
                  fingerCount
                ) {
                  console.log("swipeLeft");
                  this.flexslider("next");
                },
                swipeRight: function () {
                  console.log("swipeRight");
                  this.flexslider("prev");
                },
                /*threshold: 0,
                tap: function (event, target) {
                window.location = $(this).find(".carousel-item.active a").attr("href");
                },
                excludedElements: "label, button, input, select, textarea, .noSwipe" */
              });
              // flex
            });
          }
        };
        aim.jquery_free(gql, {}, callback);
      };
      // first load data from food
      loadData("drink");
      $(".panel_tab a").click(function () {
        const tap_name = $(this).attr("data-title");
        if (tap_name === "drink" && drinkStatus === 0) {
          loadData("drink");
        } else if (tap_name === "food" && foodStatus === 0) {
          loadData("food");
        } else if (tap_name === "stock" && stockStatus === 0) {
          loadData("stock");
        } else if (tap_name === "other" && otherStatus === 0) {
          loadData("other");
        } else if (tap_name === "set" && setStatus === 0) {
          loadData("set");
        }
      });

      $("#form_action_order").on("click", ".flexslider li", function () {
        $(this).attr("data-item");
        var typeSelect = $(this).attr("data-item");
        if (typeSelect === "All") {
          $(".chooseType").show();
        } else {
          $(".chooseType").hide();
          $("." + typeSelect).show();
        }
      });

      $(document).on("change keyup", "#order-form", function () {
        let price = $("#price").val();
        let quantity = $("#quantity").val();
        // sum add extra price
        let addExtraPrice = 0;
        $(".addExtra:checked").each(function () {
          addExtraPrice += parseFloat($(this).data("price"));
        });
        let total = parseFloat(price) * parseFloat(quantity) + addExtraPrice;
        $("#total").val(total);
      });
      // product click
      $("#form_action_order").on("click", ".products", function () {
        const type = $(this).attr("data-type");
        const category_id = $(this).attr("data-category_id");
        const id = $(this).attr("data-id");
        const allItems = allItemsType[type];
        const category = allItems.find((v) => v.id === category_id);
        let product;
        if (type === "stock") {
          product = category.productSet.edges.find((v) => v.node.id === id);
        } else {
          product = category.saleinformationSet.edges.find(
            (v) => v.node.id === id
          );
        }
        let productPrice = 0;
        if (table_type === "vip") {
          productPrice = product.node.price;
        } else {
          productPrice = product.node.price;
        }
        let addoptionSet = "";
        let addextraSet = "";
        if (type !== "stock") {
          if (product.node.addoptionSet.length > 0) {
            product.node.addoptionSet.forEach((v) => {
              addoptionSet += `<div class="icheck-primary d-inline">
                  <input class="form-check-input" type="checkbox" value="${v.id}" id="option_box_${v.id}" name="option[]">
                  <label class="form-check-label" for="option_box_${v.id}">
                      ${v.name}
                  </label>
              </div>`;
            });
            const addTitle = `<h4 class="text-primary">ជម្រើស</h4>
            ${addoptionSet}`;
            addoptionSet = addTitle;
          }
          // check add extra
          if (product.node.addextraSet.length > 0) {
            product.node.addextraSet.forEach((v) => {
              let extraPrice = 0;
              if (table_type === "vip") {
                extraPrice = aim.currencyRound(
                  v.price,
                  product.node.currency.round
                );
              } else {
                extraPrice = aim.currencyRound(
                  v.price,
                  product.node.currency.round
                );
              }
              addextraSet += `<div class="icheck-primary d-inline">
                  <input class="form-check-input addExtra" type="checkbox" data-price="${extraPrice}" value="${v.id}--${extraPrice}" id="extra_box_${v.id}" name="extra[]">
                  <label class="form-check-label" for="extra_box_${v.id}">
                      ${v.title} <span class="text-danger">${extraPrice}</span>
                  </label>
              </div>`;
            });
            const addTitle = `<h4 class="text-primary">បន្ថែម</h4>
            ${addextraSet}`;
            addextraSet = addTitle;
          }
        }
        // console.log(product);

        withReactContent(Swal)
          .fire({
            html: `<div class=col">
                <h4 class="mt-1">ដំណើរការលក់</h4>
                <form id="order-form" method="post">
                  <hr>
                  <h3 class="text-primary text-left">${product.node.name}</h3>
                  <div class="input-group mb-3">
                      <div class="input-group-prepend">
                          <span class="input-group-text">តម្លៃ</span>
                      </div>	
                      <input type="text" name="price" class="form-control number" id="price" value="${aim.currencyRound(
                        productPrice,
                        product.node.currency.round
                      )}">
                      <div class="input-group-append">
                          <input type="hidden" name="productId" id="productId" value="${
                            product.node.id
                          }">
                          <input type="hidden" name="productType" id="productType" value="${type}">
                          <span class="input-group-text text-danger">${
                            product.node.currency.shortName
                          }</span>
                      </div>	
                  </div>

                  <div class="input-group mb-3">
                      <div class="input-group-prepend">
                          <span class="input-group-text">បរិមាណ</span>
                      </div>	
                      <input type="number" name="quantity" id="quantity" value="1" class="form-control number" required autocomplete="off">
                  </div>

                  <div class="form-group clearfix text-left">
                      ${addoptionSet}
                  </div>
                  
                  <div class="form-group clearfix text-left">
                      ${addextraSet}
                  </div>
                  
                  <div class="input-group mb-3">
                      <div class="input-group-prepend">
                          <span class="input-group-text">សរុប</span>
                      </div>	
                      <input type="text" name="total" id="total" class="form-control number" readonly value="${aim.currencyRound(
                        product.node.price,
                        product.node.currency.round
                      )}">
                      <div class="input-group-append">
                          <span class="input-group-text text-danger">${
                            product.node.currency.shortName
                          }</span>
                      </div>	
                  </div>

                  <div class="input-group mb-3">
                      <div class="input-group-prepend">
                          <span class="input-group-text">បរិយាយ</span>
                      </div>
                      <textarea class="form-control" name="description" id="description" rows="2"></textarea>
                  </div>

                </form>
              </div>`,
            showCancelButton: true,
            confirmButtonText: "យល់ព្រម",
            cancelButtonText: "បោះបង់",
            showLoaderOnConfirm: true,
            preConfirm: () => {
              const dataForm = {};
              $("#order-form")
                .serializeArray()
                .map(function (x) {
                  if (x.name.indexOf("[]") !== -1) {
                    if (Array.isArray(dataForm[x.name])) {
                      dataForm[x.name].push(x.value);
                    } else {
                      dataForm[x.name] = [x.value];
                    }
                  } else {
                    dataForm[x.name] = x.value;
                  }
                  return true;
                });
              const d = dataForm;
              const callback = (data) => {
                if (data.errors) {
                  withReactContent(Swal).fire({
                    icon: "error",
                    title: "មានបញ្ហា",
                    html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
                    confirmButtonText: "យល់ព្រម",
                  });
                } else {
                  // telegram notification
                  const ds = data.data.postSaleOrder.saleOrder;
                  data.page = "postSaleOrder";
                  let dataMessage = "";
                  let print_data = "";
                  if (ds.type === "food") {
                    /* const dateTime = ds.date.created.split("T");
                    // const date = new Date(dateTime[0]);
                    const timeUTC = dateTime[1].split(".")[0];
                    // add 7 hours to UTC time
                    const localhour = parseInt(timeUTC.split(":")[0]) + 7;
                    const time = `${localhour}:${timeUTC.split(":")[1]}:${
                      timeUTC.split(":")[2]
                    }`; */
                    const dateTime = new Date(ds.date.created);
                    // const time = `${dateTime.getHours()}:${dateTime.getMinutes()}:${dateTime.getSeconds()}`;
                    const time = dateTime.toLocaleTimeString();
                    let extra = "";
                    if (ds.saleorderextraSet.length > 0) {
                      ds.saleorderextraSet.forEach((v) => {
                        extra += `<b>បន្ថែម</b> ${v.AddExtra.title}\n`;
                      });
                    }
                    let option = "";
                    if (ds.saleorderoptionSet.length > 0) {
                      ds.saleorderoptionSet.forEach((v) => {
                        option += `<b>ជម្រើស</b> ${v.AddOption.name}\n`;
                      });
                    }
                    let description = "";
                    if (ds.description) {
                      description = `<b>បរិយាយ</b> ${ds.description}\n`;
                    }
                    let action = `បញ្ជាទិញ តុ ${ds.table.name}`;

                    dataMessage += `<b><u>${action}</u></b>\n\n`;

                    dataMessage += `<b><u>ឈ្មោះ</u></b> ${ds.saleInformation.name}\n`;
                    dataMessage += `<b><u>បរិមាណ</u></b> ${ds.quantity}\n`;
                    dataMessage += `${option}`;
                    dataMessage += `${extra}`;
                    dataMessage += `${description}`;
                    dataMessage += `<b><u>ម៉ោង</u></b> ${time}`;

                    // replace \n to <br>
                    print_data = `<div style="color: #000000 !important; margin: 0 !important; font-family: Battambang; margin-bottom: 22px !important">
                        <h2>តុ ${ds.table.name}</h2>           
                        <div style="width: 100%; color: #000000 !important; margin: 0 !important; font-family: Battambang; font-size: 18px;">
                          <b><u></u>${ds.saleInformation.name}= ${
                      ds.quantity
                    }</b><br>
                          ${option.replace(/\n/g, "<br>")}
                          ${extra.replace(/\n/g, "<br")}
                          ${description.replace(/\n/g, "<br>")}
                          <b><u>ម៉ោង</u></b> ${time}<br>
                          ${user.user.firstName}
                        </div>
                    </div>`;
                  }
                  // convert to string with break line
                  if (dataMessage !== "") {
                    if (
                      typeof navigator === "object" &&
                      typeof navigator.userAgent === "string" &&
                      navigator.userAgent.indexOf("Electron") >= 0
                    ) {
                      console.log("Electron");
                      withReactContent(Swal).fire({
                        icon: "success",
                        title: "ជោគជ័យ",
                        showConfirmButton: false,
                        timer: 2500,
                      });
                      const data_print = [
                        {
                          type: "text",
                          value: print_data,
                          style: {},
                        },
                      ];
                      const options = {
                        preview: false,
                        margin: "auto",
                        copies: 1,
                        printerName: "R Cashier",
                        timeOutPerLine: 1000,
                        pageSize: "80mm",
                        silent: true,
                      };
                      window.electronAPI.setPinter({
                        data: data_print,
                        options: options,
                        saleType: data.type,
                        printer_name: "R Cashier",
                        logo: false,
                      });
                    } else {
                      console.log("Not Electron");
                      // print data
                      /* const print_data = `<div style="width: 400px">
                        <div class="col-auto mr-auto text-center mb-2">
                            <img alt="logo" src="/front_end/dist/img/gray_logo.png" id="company_logo" width="220">
                        </div>
                        <div style="text-align: center; font-weight: 700; font-size: 2.2rem;">
                          Sea Forest Resort
                        </div>
                      </div>`;
                      // disable print preview in browser and print
                      $.print(print_data); */
                    }
                    dataMessage = dataMessage.replace(/\n/g, "\\n");
                    const functionName = "Sale_order";
                    const gql = `mutation MyMutation {
                      sendTelegramMessage(
                        text: "${dataMessage}"
                        functionName: "${functionName}"
                      ) {
                        success
                      }
                    }`;
                    // console.log(gql);
                    CallbackCheck(data, gql);
                    // navigate(`/${functionName}`);
                  }
                }
              };

              let addOption = `addOption: null`;
              if (d["option[]"]) {
                addOption = `addOption: ${JSON.stringify(d["option[]"])}`;
              }
              let addExtra = `addExtra: null`;
              let addExtraPrice = `addExtraPrice: null`;
              if (d["extra[]"]) {
                addExtra = [];
                addExtraPrice = [];
                d["extra[]"].forEach((v) => {
                  const extra = v.split("--");
                  const extraId = extra[0];
                  const extraPrice = extra[1];
                  addExtra.push(extraId);
                  addExtraPrice.push(extraPrice);
                });
                addExtra = `addExtra: ${JSON.stringify(addExtra)}`;
                addExtraPrice = `addExtraPrice: ${JSON.stringify(
                  addExtraPrice
                )}`;
              }
              const page = "postSaleOrder";
              const gql = `
                mutation MyMutation {
                  ${page}(
                    productId: "${d.productId}"
                    productType: "${d.productType}"
                    tableId: "${table}"
                    ${addOption}
                    description: "${d.description}"
                    price: "${d.price}"
                    ${addExtra}
                    ${addExtraPrice}
                    quantity: "${d.quantity}"
                    total: "${d.total}"
                  ) {
                    success
                    saleOrder {
                      type
                      quantity
                      price
                      total
                      description
                      stock {
                        id
                        name
                        originalPrice
                      }
                      saleInformation {
                        id
                        name
                      }
                      saleorderextraSet {
                        price
                        AddExtra {
                          title
                        }
                      }
                      saleorderoptionSet {
                        AddOption {
                          name
                        }
                      }
                      table {
                        name
                      }
                      date {
                        created
                      }
                    }
                  }
                }
              `;
              // console.log(gql);
              aim.jquery_free(gql, {}, callback);
            },
            allowOutsideClick: () => !Swal.isLoading(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              /* Swal.fire({
                title: `${result.value.title}!`,
                html: `successfully: ${result.value.title}`,
                confirmButtonText: "យល់ព្រម",
              }); */
              // setCount
              setCount(Count + 1);
              initialized.current = false;
            }
          });
      });
      getOrder();
      // update notification

      if (Count === 0) {
        $("#noti .box1").on("click", function () {
          $(".data_order_list").toggle(250);
        });
        $(document).click(function (event) {
          if (!$(event.target).closest("#noti .box1").length) {
            $(".data_order_list").hide();
          }
        });
      }
    }
  }, [$, business, table_type, table, table_name, Count, user]);
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          <div className="form_action" id="form_action_order">
            {/* form_action_order */}
            <div className="row">
              <div id="tableInfo" className="col-12">
                <div id="noti">
                  <div className="box1 text-center mt-2"></div>
                  <div
                    className="data_order_list"
                    style={{
                      width: "430px",
                      backgroundColor: "#c9e0fb",
                      position: "absolute",
                      zIndex: 999,
                      transform: "translate(-50%, 0)",
                      left: "50%",
                      padding: 12,
                      display: "none",
                    }}
                  ></div>
                </div>
                <ul
                  className="nav nav-tabs nav-pills nav-justified"
                  role="tablist"
                >
                  <li className="panel_tab nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="pill"
                      href="#drink"
                      data-title="drink"
                    >
                      គ្រឿងទឹក
                    </a>
                  </li>
                  <li className="panel_tab nav-item">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#food"
                      data-title="food"
                    >
                      ម្ហូប
                    </a>
                  </li>
                  <li className="panel_tab nav-item">
                    <a
                      className="nav-link setup_table"
                      data-toggle="pill"
                      href="#stock"
                      data-title="stock"
                    >
                      ផលិតផលក្នុងស្តុក
                    </a>
                  </li>
                  <li className="panel_tab nav-item">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#other"
                      data-title="other"
                    >
                      ផ្សេងៗ
                    </a>
                  </li>
                  <li className="panel_tab nav-item">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#set"
                      data-title="set"
                    >
                      ឈុត
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div id="drink" className="tab-pane mt-4 active">
                    {tap_panes_content("drink")}
                  </div>
                  <div id="food" className="tab-pane mt-4">
                    {tap_panes_content("food")}
                  </div>
                  <div id="stock" className="tab-pane mt-4">
                    {tap_panes_content("stock")}
                  </div>
                  <div id="other" className="tab-pane mt-4">
                    {tap_panes_content("other")}
                  </div>
                  <div id="set" className="tab-pane mt-4">
                    {tap_panes_content("set")}
                  </div>
                </div>
              </div>
            </div>
            {/* form_action_order */}
          </div>
        </div>
      </div>
    </div>
  );
}
