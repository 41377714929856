import Page404 from "../../pages/Page404";
import CurrencyAdd from "./CurrencyAdd";
import CurrencyEdit from "./CurrencyEdit";
import { useNavigate } from "react-router-dom";

// for add Currency
import * as aim from "../../js/function.easy7d";
import SubmitCheck, { CallbackCheck } from "../HandleFunction";

export default function Post(props) {
  const navigate = useNavigate();
  // console.log(props);
  const action = props.searchParams.get("action");
  // clear form
  const clearForm = () => {
    // console.log("clearForm");
    // document.getElementById("addDataForm").reset();
    navigate("/currency");
  };
  // handle submit
  const handleSubmit = (data) => {
    try {
      const getData = SubmitCheck(data);
      // console.log(getData);
      const d = getData.dataForm;
      // edit data
      const page = "postCurrency";

      const callback = (data) => {
        data.page = page;
        let action = "ពត៌មានរូបិយប័ណ្ណ";
        if (getData.data.page === "update") {
          action = `ធ្វើបច្ចុប្បន្នភាព ${action}`;
        }
        let dataMessage = `<b><u>${action}</u></b>\n\n`;
        dataMessage += `<b>ឈ្មោះ:</b> ${d.name}\n`;
        dataMessage += `<b>ឈ្មោះខ្លី:</b> ${d.short_name}\n`;
        dataMessage += `<b>លេខរៀង:</b> ${d.order}\n`;
        dataMessage += `<b>ទសភាគ:</b> ${d.round}\n`;
        /* let dataMessage = `
<b>${action}</b>

<b>ឈ្មោះ:</b> ${d.name}
<b>ឈ្មោះខ្លី:</b> ${d.short_name}
<b>លេខរៀង:</b> ${d.order}
<b>ទសភាគ:</b> ${d.round}`; */

        dataMessage = dataMessage.replace(/\n/g, "\\n");
        // edit data Telegram Function
        const functionName = "Currency";
        const gql = `
          mutation MyMutation {
            sendTelegramMessage(
              text: "${dataMessage}"
              functionName: "${functionName}"
            ) {
              success
            }
          }
        `;
        // edit data
        console.log(data, gql);
        CallbackCheck(data, gql);
        navigate(`/${functionName}`);
      };

      const gql = `mutation {
        ${page}(
          ${getData.data.data}
          flag: "${d.flag}"
          nameKh: "${d.name}"
          shortName: "${d.short_name}"
          order: ${d.order}
          shortcutKey: "${d.shortcut_key}"
          round: ${d.round}
          rateBuy: "${d.rateBuy}"
          rateFixed: "${d.rateFixed}"
          rateSale: "${d.rateSale}"
        ) {
          success
          currency {
            nameKh
            shortName
            shortcutKey
            order
            round
          }
        }
      }`;
      // console.log(gql);
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.error("error:", error);
    }
  };

  if (action === "add") {
    // multiple props clearForm={clearForm} and handleSubmit={handleSubmit} to CurrencyAdd
    return <CurrencyAdd props={{ clearForm, handleSubmit }} />;
  } else if (action === "edit") {
    return (
      <CurrencyEdit
        props={{
          ...props,
          clearForm,
          handleSubmit,
        }}
      />
    );
  } else {
    return <Page404 />;
  }
}
