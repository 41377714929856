import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import * as aim from "../../js/function.easy7d";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";

export default function Post(props) {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "create", data: null });
  };
  const navigate = useNavigate();
  const initialized = useRef(false);
  const currency = aim.useGetCurrency();

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const $ = window.$;
      $("#date")
        .datepicker({ dateFormat: "dd-mm-yy" })
        .datepicker("setDate", new Date());

      let currencyOptions = "";
      if (currency) {
        currency.forEach((element) => {
          // base 64 decode id
          const id = atob(element.node.id);
          // get id
          const id_get = id.split(":")[1];
          if (element.node.isDefault) {
            currencyOptions += `<option value="${id_get}" data-rate="${element.node.rate.fixed}" selected>${element.node.nameKh}</option>`;
          } else {
            currencyOptions += `<option value="${id_get}" data-rate="${element.node.rate.fixed}">${element.node.nameKh}</option>`;
          }
        });
        $("#currency").html(currencyOptions);
      }

      $("#searchProduct").keyup(function () {
        // console.log("searchProduct", $(this).val());
        const item = $(this).val();
        const gql = `query MyQuery {
          autoCategories {
            edges {
              node {
                name
                productSet(name_Icontains: "${item}") {
                  edges {
                    node {
                      id
                      name
                      code
                      uom
                      price
                      currency {
                        nameKh
                        shortName
                        rate {
                          fixed
                        }
                      }
                      quantity
                      latestproductquantitySet {
                        id
                        quantity
                      }
                    }
                  }
                }
              }
            }
          }
        }`;
        $("#searchProduct").devbridgeAutocomplete({
          serviceUrl: `https://landscape.aimsarik.com/gql/`,
          type: "POST",
          ajaxSettings: {
            headers: {
              // jwt token
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            contentType: "application/json",
            data: JSON.stringify({ query: gql }),
          },
          transformResult: function (response) {
            // convert response to json object
            response = JSON.parse(response);
            // console.log(response);
            if (response.data.autoCategories.edges.length > 0) {
              let products = [];

              // cd.rate.fixed = $("#currency option:selected").data("rate");
              const cd = {
                rate: {
                  fixed: $("#currency option:selected").data("rate"),
                },
              };

              response.data.autoCategories.edges.forEach((item) => {
                item.node.productSet.edges.forEach((product) => {
                  const rate = product.node.currency.rate.fixed;
                  const exchange = aim.exchangeRate(0, rate, cd);

                  products.push({
                    id: product.node.id,
                    value: `${product.node.name} - ${product.node.code}`,
                    title: product.node.name,
                    code: product.node.code,
                    uom: product.node.uom,
                    quantity: product.node.latestproductquantitySet[0].quantity,
                    price: aim.multiple_digits(
                      product.node.price * exchange,
                      2
                    ),
                    /* price: aim.currencyFormat(
                      product.node.price,
                      product.node.currency.shortName,
                      2
                    ), */
                  });
                });
              });
              return { suggestions: products };
            }
          },
          onSelect: function (suggestion) {
            let stockID = $(`.stockItemId[value="${suggestion.id}"]`).length;
            if (stockID === 0) {
              $("#stock_out_requirements").prepend(`
                <tr>
                  <td>
                    <button type="button" class="btn btn-danger del_X">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </td>
                  <td>
                    <input type="hidden" class="stockItemId" name="stockItemId[]" value="${suggestion.id}" />
                    ${suggestion.title}
                  </td>
                  <td>${suggestion.code}</td>
                  <td>${suggestion.uom}</td>
                  <td>
                    <input type="text" class="form-control operator number quantity" name="quantity[]" value="0" onClick="this.select();"/>
                  </td>
                  <td>
                    <input type="text" class="form-control operator number price" name="price[]" value="${suggestion.price}" onClick="this.select();"/>
                  </td>
                  <td>
                    <input type="text" class="form-control operator number discount" name="discount[]" value="0" onClick="this.select();"/>
                  </td>
                  <td>
                    <input type="hidden" class="amount" name="amount[]" value="0" />
                    <input type="hidden" class="after_discount" name="after_discount[]" value="0" />
                    <span class="amountShow"></span>
                  </td>
                </tr>
              `);
            }
            $(this).val("");
          },
        });
        $(this).focus();
      });
      // delete row
      $("table").on("click", ".del_X", function () {
        $(this).closest("tr").remove();
      });
      // calculate
      $("table").on("keyup", ".operator", function () {
        let quantity = parseFloat(
          $(this).closest("tr").find(".quantity").val().replace(/,/g, "")
        );
        let price = parseFloat(
          $(this).closest("tr").find(".price").val().replace(/,/g, "")
        );
        let discount = parseFloat(
          $(this).closest("tr").find(".discount").val().replace(/,/g, "")
        );
        const amount = quantity * price;
        let after_discount = aim.multiple_digits(
          amount - (amount * discount) / 100,
          2
        );
        $(this).closest("tr").find(".amountShow").text(after_discount);
        $(this).closest("tr").find(".amount").val(amount);
        $(this).closest("tr").find(".after_discount").val(after_discount);
      });

      $("#company_currency").change(function () {
        // clear all data in stock_out_requirements
        $("#stock_out_requirements").html("");
      });
    }
  });
  return (
    <div className="content-wrapper">
      <div className="container-fluid" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">បង្កើតតារាងរួម</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">ក្រុមហ៊ុន</span>
                      </div>
                      <input
                        type="text"
                        name="company"
                        id="company"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">ឈ្មោះ​អតិថិជន</span>
                      </div>
                      <input
                        type="text"
                        name="customer_name"
                        id="customer_name"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">លេខទូរស័ព្ទ</span>
                      </div>
                      <input
                        type="text"
                        name="customer_phone"
                        id="customer_phone"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">អាសយដ្ឋាន</span>
                      </div>
                      <input
                        type="text"
                        name="customer_address"
                        id="customer_address"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">លេខវិក័យប័ត្រ</span>
                      </div>
                      <input
                        type="text"
                        name="invoice"
                        id="invoice"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">កាលបរិច្ឆេទ</span>
                      </div>
                      <input
                        type="text"
                        name="date"
                        id="date"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">អ្នកលក់</span>
                      </div>
                      <input
                        type="text"
                        name="sale_name"
                        id="sale_name"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">លេខទូរស័ព្ទ</span>
                      </div>
                      <input
                        type="text"
                        name="sale_phone"
                        id="sale_phone"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">រូបិយប័ណ្ណ</span>
                      </div>
                      <select
                        name="currency"
                        id="currency"
                        className="form-control"
                      ></select>
                    </div>
                  </div>
                </div>

                {/* find product */}
                <div className="input-group mt-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text bg-warning">
                      រកផលិតផល
                    </span>
                  </div>
                  <input
                    id="searchProduct"
                    name="searchProduct"
                    className="form-control"
                    onClick={(e) => e.target.select()}
                    autoComplete="off"
                  />
                </div>
                <div className="row mt-4">
                  <div id="no-more-tables" className="w-100">
                    <div className="card border-primary">
                      <h3 className="card-header bg-primary text-white text-center">
                        <span>ផលិតផល</span>
                      </h3>
                      <div className="card-body mt-2 row">
                        <table className="table table-hover" id="addCategory">
                          <thead>
                            <tr>
                              <th>លុប</th>
                              <th>ឈ្មោះ</th>
                              <th>លេខកូដ</th>
                              <th>ឯកតា</th>
                              <th>បរិមាណ</th>
                              <th>តម្លៃលក់</th>
                              <th>បញ្ចុះតម្លៃ</th>
                              <th>សរុប</th>
                            </tr>
                          </thead>
                          <tbody id="stock_out_requirements" />
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/products_list")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
