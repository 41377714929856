import { useContext, useEffect, useRef, useState } from "react";
import * as aim from "../../js/function.easy7d";
import { AuthContext } from "../../appState/AuthProvider";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { printInvoice } from "./CreateInvoice";

export default function SaleInvoice() {
  const page_name = "sale_invoice";
  const tbId = `tb_${page_name}`;
  const thead = aim.datatableHeadFoot(
    [
      "លរ",
      "ព័ត៌មាន",
      "សរុប",
      "បញ្ចុះតម្លៃ",
      "នៅសល់",
      "លុយទទួលបាន",
      "លុយអាប់",
      "តុល្យភាព",
      "សកម្មភាព",
    ],
    1
  );
  const [tables, setTables] = useState([]);
  const initialized = useRef(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      // console.log("sale_invoice");
      const $ = window.$;
      const currencyDefault = aim.useGetCurrencyDefault();
      const cd = currencyDefault[0].node;

      // loop user.business as array
      let business = "";
      user.business.forEach((x) => {
        business += `"${x.business}",`;
      });
      // console.log(business);

      const gql = `query Table {
        allTable(business_Business_In: [${business}], status: 1) {
          edges {
            node {
              id
              name
              number
              tableType
              status
              business {
                id
                business
              }
              saleorderSet(status: 0) {
                edges {
                  node {
                    total
                    status
                    type
                    saleInformation {
                        id
                        name
                        price
                        currency {
                          id
                          nameEn
                          shortName
                          round
                          rate {
                            fixed
                          }
                          isDefault
                        }
                      }
                      stock {
                        id
                        name
                        price
                        originalPrice
                        currency {
                          id
                          nameEn
                          shortName
                          round
                          rate {
                            fixed
                          }
                          isDefault
                        }
                      }
                  }
                }
              }
            }
          }
        }
      }`;
      const callback = (data) => {
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
        } else {
          // console.log(data);
          let tableP = [];
          if (data.data.allTable.edges && data.data.allTable.edges.length > 0) {
            data.data.allTable.edges.forEach((x) => {
              if (x.node.status === 0) {
                x.tableSource = "bg-success";
              } else if (x.node.status === 1) {
                x.tableSource = "bg-danger";
              } else if (x.node.status === 2) {
                x.tableSource = "bg-warning";
              }
              const d = x.node.saleorderSet.edges;
              const food = d.filter((x) => x.node.type !== "stock");
              const stock = d.filter((x) => x.node.type === "stock");

              food.sort((a, b) =>
                a.node.saleInformation.name.localeCompare(
                  b.node.saleInformation.name
                )
              );
              stock.sort((a, b) =>
                a.node.stock.name.localeCompare(b.node.stock.name)
              );
              let total = 0;
              food.forEach((x) => {
                // console.log(x.node.total);
                const exchange = aim.exchangeRate(
                  x.node.saleInformation.currency.isDefault,
                  x.node.saleInformation.currency.rate.fixed,
                  cd
                );
                total += x.node.total * exchange;
              });
              stock.forEach((x) => {
                const exchange = aim.exchangeRate(
                  x.node.stock.currency.isDefault,
                  x.node.stock.currency.rate.fixed,
                  cd
                );
                total += x.node.total * exchange;
              });

              tableP.push(
                <div
                  key={x.node.name}
                  className="col-6 col-sm-3 my-2"
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className={`info-box ${x.tableSource}`}
                    style={{ minHeight: "100%" }}
                  >
                    <div className="info-box-content">
                      <span className="info-box-text">
                        <Link
                          to={`/create_invoice?table=${x.node.id}&table_name=${x.node.name}&table_type=${x.node.tableType}&business=${x.node.business.business}`}
                          style={{ color: "white" }}
                        >
                          <h4 className="info-box-text">{x.node.name}</h4>
                          {
                            <span className="info-box-number">
                              សរុប: {aim.currencyFormat(total, cd.shortName, 2)}
                            </span>
                          }
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              );
            });
          }
          setTables(tableP);
        }
      };
      // console.log(gql);
      aim.jquery_free(gql, {}, callback);

      // data table
      const sumAmount = [2, 3, 4, 6, 7];
      const curr = aim.useGetCurrency();
      aim.loadData(
        {
          currency: curr,
          tbId: tbId,
          page_name: page_name,
          load_page: "allCreateInvoice",
          gql: `{
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            totalCount
            edgeCount
            edges {
              cursor
              node {
                id
                invoice
                customer {
                  id
                  name
                  discount
                  phone {
                    number
                  }
                }
                discountAll
                total
                discount
                grandTotal
                balance
                changepriceSet {
                  id
                  change
                  coaId {
                    id
                    name
                    currency {
                      id
                      nameKh
                      shortName
                    }
                  }
                }
                paidSet {
                  id
                  paid
                  coaId {
                    id
                    name
                    currency {
                      id
                      nameKh
                      shortName
                    }
                  }
                }
                table {
                  id
                  name
                  number
                }
                Currency {
                  id
                  nameKh
                  shortName
                  round
                  rate {
                    fixed
                  }
                }
                status
                saleorderSet {
                  edges {
                    node {
                      CreateInvoice {
                        invoice
                      }
                    }
                  }
                }
              }
            }
          }`,
        },
        {
          // link: link,
          page_name: page_name,
          function_load: `${page_name}_datatable`,
          sumAmount: sumAmount,
          order_false: [1, 3, 4],
          width_column: {
            1: "100px",
          },
          columnHidden: [],
          orderColumn: ["id", "", "", "", "", ""],
          searching: ["invoice", "Currency"],
          // function
          data_execute: (result, load_page) => {
            // console.log("result:", result, "load_page:", load_page);
            const data_tb_row = [];
            result.recordsTotal = result.data[load_page].totalCount;
            result.recordsFiltered = result.data[load_page].totalCount;
            // console.log(result.recordsTotal);
            // forEach
            let number = 1;
            result.data[load_page].edges.forEach((v) => {
              let status = "";
              let action = "";
              if (v.node.saleorderSet.edges.length > 0) {
                action = `<i title="Print" class="btn btn-primary printInv fa fa-print" aria-hidden="true" data-edit="{'id':'${v.node.id}','page':'${page_name}'}"></i>`;
                if (v.node.status === 0) {
                  status = `<span class="bg-warning px-2">រងចាំទូទាត់</span>`;
                  action += ` <span title="Paid" class="btn btn-success paidInv"
                    data-paid="{
                      'id':'${v.node.id}',
                      'page':'${page_name}',
                      'invoice':'${v.node.invoice}',
                      'total':'${v.node.total}',
                      'discount':'${v.node.discount}',
                      'grandTotal':'${v.node.grandTotal}',
                      'currency':'${v.node.Currency.shortName}',
                      'currencyId':'${v.node.Currency.id}',
                      'rate_fixed':'${v.node.Currency.rate.fixed}',
                      'round':'${v.node.Currency.round}'
                    }">ទូទាត់</span>`;
                } else if (v.node.status === 1) {
                  status = `<span class="bg-success px-2">បានទូទាត់</span>`;
                }
                action += ` <i  class="btn btn-danger fa fa-trash" aria-hidden="true"></i>`;
              } else {
                status = `<span class="bg-danger px-2">បោះបង់</span>`;
              }

              let balance = "";
              if (v.node.balance) {
                balance = `${aim.currencyFormat(
                  v.node.balance,
                  v.node.Currency.shortName,
                  2
                )}`;
              }
              let changePrice = "";
              if (v.node.changepriceSet.length > 0) {
                v.node.changepriceSet.forEach((x) => {
                  if (x.change !== 0) {
                    changePrice += `${aim.currencyFormat(
                      x.change,
                      x.coaId.currency.shortName,
                      2
                    )}`;
                  }
                });
              }

              let multiplePaid = "";
              if (v.node.paidSet.length > 0) {
                v.node.paidSet.forEach((x) => {
                  multiplePaid += `
                  <div class="mt-2">
                  ${aim.currencyFormat(
                    x.paid,
                    x.coaId.currency.shortName,
                    2
                  )} <span class="bg-success px-2">${x.coaId.name}</span>
                  </div>`;
                });
              }
              let customer = "";
              if (v.node.customer !== null) {
                customer = `${v.node.customer.name} (${v.node.customer.phone[0].number})`;
              } else {
                customer = "ទូទៅ";
              }

              var tb_row = [
                `${number++}`,
                `វិក្កយបត្រ: ${v.node.invoice}<br>
                ឈ្មោះតុ: ${v.node.table.name}<br>
                ​អតិថិជន:  ${customer}<br>
                បញ្ចុះតម្លៃ: ${v.node.discountAll}%<br>
                ស្ថានភាព: ${status}`,
                `${aim.currencyFormat(
                  v.node.total,
                  v.node.Currency.shortName,
                  2
                )}`,
                `${aim.currencyFormat(
                  v.node.discount,
                  v.node.Currency.shortName,
                  2
                )}`,
                `${aim.currencyFormat(
                  v.node.grandTotal,
                  v.node.Currency.shortName,
                  2
                )}`,
                `${multiplePaid}`,
                `${changePrice}`,
                `${balance}`,
                `${action}`,
              ];
              data_tb_row.push(tb_row);
            });
            return data_tb_row;
          },
        }
      );
      // document.queryselector .editData
      document.querySelector(`#${tbId}`).addEventListener("click", (e) => {
        const el = e.target;
        // console.log(e.target);
        if (el.classList.contains("printInv")) {
          const data_edit = JSON.parse(el.dataset.edit.replace(/'/g, '"'));
          // console.log(data_edit);
          const { id } = data_edit;
          const gql = `query InvoiceData {
            saleInvoice(id: "${id}") {
              date {
                created
              }
              saleorderSet {
                edges {
                  node {
                    type
                    id
                    price
                    discountPercent
                    quantity
                    total
                    saleorderoptionSet {
                      AddOption {
                        name
                      }
                    }
                    saleInformation {
                      name
                      currency {
                        rate {
                          fixed
                        }
                        isDefault
                      }
                    }
                    saleorderextraSet {
                      AddExtra {
                        title
                      }
                      price
                    }
                    stock {
                      name
                      price
                      originalPrice
                      currency {
                        rate {
                          fixed
                        }
                        isDefault
                      }
                    }
                  }
                }
              }
              customer {
                name
                phone {
                  number
                }
              }
              table {
                name
                business {
                  Company {
                    phone
                    address
                    name
                  }
                }
              }
              invoice
              total
              discount
              grandTotal
              status
              paidSet {
                paid
                coaId {
                  name
                  type {
                    type
                  }
                  currency {
                    shortName
                    nameKh
                  }
                }
              }
              changepriceSet {
                change
                coaId {
                  name
                  type {
                    type
                  }
                  currency {
                    shortName
                    nameKh
                  }
                }
              }
              balance
            }
          }`;
          const callback = (data) => {
            // console.log(data);
            if (data.errors) {
              withReactContent(Swal).fire({
                icon: "error",
                title: "មានបញ្ហា",
                html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
                confirmButtonText: "យល់ព្រម",
              });
            } else {
              // console.log(data.data);
              printInvoice(data.data);
            }
          };
          aim.jquery_free(gql, {}, callback);
        } else if (el.classList.contains("paidInv")) {
          const data_paid = JSON.parse(el.dataset.paid.replace(/'/g, '"'));
          // console.log(data_paid);
          const {
            id,
            /* page,
            invoice,
            total,
            discount, */
            grandTotal,
            currency,
            currencyId,
            rate_fixed,
            // round,
          } = data_paid;
          // console.log(data_paid);

          const coa = aim.useGetCoa(business);
          // console.log(coa);

          let incomeOptions = "<option value=''>ជ្រើសរើសគណនី</option>";
          let assetOptions = "<option value=''>ជ្រើសរើសគណនី</option>";
          if (coa) {
            coa.forEach((e) => {
              // base 64 decode id
              const id = atob(e.node.id);
              // get id
              const id_get = id.split(":")[1];
              if (e.node.category === "ទ្រព្យ") {
                assetOptions += `<option value="${id_get}"    
                data-exchange_rate_fixed="${e.node.currency.rate.fixed}"
                data-currency_id="${e.node.currency.id}"
                data-round="${e.node.currency.round}"
                >${e.node.name}</option>`;
              } else if (e.node.category === "ចំណូល") {
                incomeOptions += `<option value="${id_get}"    
                data-exchange_rate_fixed="${e.node.currency.rate.fixed}"
                data-currency_id="${e.node.currency.id}"
                data-round="${e.node.currency.round}"
                data-business="${e.node.business.id}"
                data-currency_shortname="${e.node.currency.shortName}"
                >${e.node.name}</option>`;
              }
            });
          }

          const account_income = () => {
            // grand total id
            $("#grand_total_id").change(function () {
              $("#paid_data").show(500);
              // check grand total if default currency or not
              let grandTotalD = 0;
              if (currency === cd.shortName) {
                // console.log("default currency");
                // console.log(currency, cd.shortName);
                grandTotalD = grandTotal;
              } else {
                // console.log("not default currency");
                // console.log(currency, cd.shortName);
                const exchange = aim.exchangeRate(currencyId, rate_fixed, cd);
                grandTotalD = grandTotal.replace(/,/g, "") * exchange;
              }
              // console.log(grandTotalD);

              const currencyOption = $("#grand_total_id option:selected").data(
                "currency_id"
              );
              const rateOption = $("#grand_total_id option:selected").data(
                "exchange_rate_fixed"
              );
              const roundOption = $("#grand_total_id option:selected").data(
                "round"
              );
              const balance_currShort = $(
                "#grand_total_id option:selected"
              ).data("currency_shortname");
              console.log(balance_currShort);

              const exchange = aim.exchangeRate(currencyOption, rateOption, cd);
              const grandTotalE = grandTotalD / exchange;

              $("#grand_total").val(
                aim.addCommas(aim.currencyRound(grandTotalE, roundOption))
              );
              $(".business_id").val(
                $("#grand_total_id").find(":selected").data("business")
              );
              $(".balance_currShort").text(balance_currShort);
            });
          };

          const payment_paid_add_multi = () => {
            let payment_paid = `
              <div class="payment_row">
                  <div class="row">
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                          <span class="input-group-text">​គណនីទទួល</span>
                      </div>
                      <input type="hidden" name="rate[]" class="rate" value="">
                      <input class="form-control payment_paid_load number" name="payment_paid_load[]" value="0" type="text" autocomplete="off" onClick="this.select();">
                      <div class="input-group-append">
                        <select name="payment_types_in[]" class="form-control payment_types_in select2bs4" required>${assetOptions}</select>
                      </div>
                    </div>
                  </div>
              </div>`;
            return payment_paid;
          };
          withReactContent(Swal).fire({
            // add hHeader and hBody to html
            html: `
            <style>
              .select2-container {
                z-index: 9999 !important;
              }
            </style>
            <div class="col-12">
              <h3 class="text-primary text-left">ទូទាត់លុយ</h3>
              <form id="paid-form" method="post">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ចំណូលសរុប</span>
                    </div>
                    <input type="hidden" name="invoiceId" value="${id}"> 
                    <input class="form-control number" type="text" name="grand_total" id="grand_total" readonly value="">
                    <input type="hidden" name="business_id" class="business_id" value="">
                    <div class="input-group-append">
                      <select name="grand_total_id" class="form-control select2bs4" id="grand_total_id" required>${incomeOptions}</select>
                    </div>
                </div>
                <div style="display: none" id="paid_data">
                  <div class="text-primary text-left"><h5>ព័ត៌មានការទូទាត់ <span class="payment_count text-danger cursor-pointer"></span></h5></div>
                  <div id="payment_information" class="col-12">${payment_paid_add_multi()}</div>
                  <div class="text-center mb-3"><span class="btn btn-primary" id="add_payment">បន្ថែមការទូទាត់</span></div>

                  <div class="row">
                    <div class="text-primary text-left col-12"><h5>ព័ត៌មានបន្ថែម</h5></div>
                    <div class="mb-3 col-md-12 text-center text-danger" style="display: none" id="check_balance">
                      តុល្យភាពមិនប្រក្រតីសូមត្រួតពិនិត្យឡើងវិញមុននឹងចុចរួចរាល់
                    </div>
                    <div class="input-group mb-3 col-md-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text">លុយអាប់</span>
                        </div>
                        <input class="form-control number change_for_paid" name="change_for_paid" id="change_for_paid" type="text" autocomplete="off" readonly value="">
                        <div class="input-group-append">
                            <select name="change_payment_id" class="form-control" id="change_payment_id">${assetOptions}</select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">តុល្យភាព</span>
                            </div>
                            <input class="form-control number" type="text" id="balance" name="balance" readonly value="0" required>
                            <div class="input-group-append">
                                <span class="input-group-text text-danger balance_currShort"></span>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>`,
            width: "60%",
            showCancelButton: true,
            confirmButtonText: "រួចរាល់",
            cancelButtonText: "បោះបង់",
            showLoaderOnConfirm: true,
            preConfirm: () => {
              // console.log("SubmitHandle");
              const dataForm = {};
              $("#paid-form")
                .serializeArray()
                .map(function (x) {
                  if (x.name.indexOf("[]") !== -1) {
                    if (Array.isArray(dataForm[x.name])) {
                      dataForm[x.name].push(x.value);
                    } else {
                      dataForm[x.name] = [x.value];
                    }
                  } else {
                    dataForm[x.name] = x.value;
                  }
                  return true;
                });
              const d = dataForm;
              console.log(d);

              // postJournal
              const business = d.business_id;
              const title = JSON.stringify(
                Array(business.length).fill("ចំណូលលក់")
              );
              const payment_types = JSON.stringify(d["payment_types[]"]);
              const payment_types_in = JSON.stringify(d["payment_types_in[]"]);
              const payment_paid_load = JSON.stringify(
                d["payment_paid_load[]"]
              );
              const rate = JSON.stringify(d["rate[]"]);

              // loop business if null return error
              if (business.includes("")) {
                withReactContent(Swal).fire({
                  icon: "error",
                  title: "បរាជ័យ",
                  text: "សូមជ្រើសរើសគណនីចំណូល",
                });
                return false;
              }
              // postPaid
              // const payment_paid_load = d["payment_paid_load[]"];
              const page = "postPaid";
              const gql = `
                mutation MyMutation {
                  ${page}(
                    invoiceId: "${d.invoiceId}",
                    balance: "${d.balance}",
                    change: "${d.change_for_paid}",
                    coaId: "${d.change_payment_id}",
                    paid: ${JSON.stringify(d["payment_paid_load[]"])},
                    paidPayment: ${JSON.stringify(d["payment_types[]"])}
                  ) {
                    success
                  }
                  postJournalMultiple(
                    business: ${business},
                    title: ${title},
                    paymentTypes: ${payment_types},
                    paymentTypesIn: ${payment_types_in}
                    paymentPaidLoad: ${payment_paid_load}
                    rate: ${rate}
                  ) {
                    success
                  }
                }`;
              const callback = (data) => {
                // console.log(data.errors);
                if (data.errors) {
                  withReactContent(Swal).fire({
                    icon: "error",
                    title: "មានបញ្ហា",
                    html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
                    confirmButtonText: "យល់ព្រម",
                  });
                } else {
                  withReactContent(Swal).fire({
                    icon: "success",
                    title: "ជោគជ័យ",
                    showConfirmButton: false,
                    timer: 2500,
                  });
                  // reload page
                  setTimeout(() => {
                    window.location.reload();
                  }, 1500);
                }
              };
              // console.log(gql);
              aim.jquery_free(gql, {}, callback);
            },
            allowOutsideClick: () => !Swal.isLoading(),
            didOpen: () => {
              $(".select2bs4").select2({
                theme: "bootstrap4",
              });
              account_income();
            },
          });
          // add payment
          $("#add_payment").click(function () {
            $("#payment_information").append(payment_paid_add_multi());
            $(".select2bs4").select2({
              theme: "bootstrap4",
            });
          });
          $("#payment_information").on(
            "change",
            ".payment_types_in",
            function () {
              let receive = {};
              $(".payment_types_in").each(function (i) {
                const rate = $(this)
                  .find(":selected")
                  .data("exchange_rate_fixed");
                $(this).closest(".input-group").find(".rate").val(rate);
                const payment = $(this)
                  .closest(".input-group")
                  .find(".payment_paid_load")
                  .val();
                // set group rate to receive {rate: payment}
                // convert payment to number
                const paymentNum = parseFloat(payment.split(",").join(""));
                if (rate in receive) {
                  receive[rate] += paymentNum;
                } else {
                  receive[rate] = paymentNum;
                }
              });
              console.log(receive);
            }
          );
        }
        $("body").on("keyup", ".number", function (e) {
          $(this).val(aim.addCommas($(this).val().split(",").join("")));
        });
      });
      aim.dateRange(tbId);
    }
  }, [user, tbId]);

  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          <div className="form_action" id="form_action_table_sale">
            <div id="tableInfo">
              <div
                className="row justify-content-md-center"
                id="row_filter_table"
              >
                <div className="input-group mt-3 col-6 d-none">
                  <div className="input-group-prepend">
                    <span className="input-group-text">លេខតុ</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="filter_number"
                    name="filter_number"
                    autoComplete="off"
                    onClick={(e) => e.target.select()}
                  />
                </div>
              </div>
              <div className="row all_table">{tables}</div>
            </div>
          </div>
          {/* form_header */}
          {aim.header()}
          {/* form_report_content */}
          {aim.body(tbId, thead)}
          <div id="qrcode"></div>
        </div>
      </div>
    </div>
  );
}
