import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function StockOut() {
  const $ = window.$;
  const initialized = useRef(false);
  const page_name = "stock_out";
  const tbId = `tb_${page_name}`;
  const formOption = (
    <Link
      to={`/post/${page_name}?action=add`}
      className="btn btn-success pull-right mx-1 addRow"
      key="addRow"
    >
      <i className="fa fa-plus" />
    </Link>
  );
  const thead = aim.datatableHeadFoot(
    ["លរ", "ផលិតផល", "ដកស្តុក", "បានត្រឡប់", "បរិមាណចុងក្រោយ", "ត្រឡប់ទៅស្តុក"],
    null
  );
  const sumAmount = null;

  const navigate = useNavigate();
  const statusHandle = (id, data, status) => {
    try {
      const gql = `mutation {
        updateCurrency(
          id: "${id}"
          ${status}: ${data}
        ) {
          currency {
            shortName
            nameKh
            status
            isDefault
          }
        }
      }`;
      const callback = (data) => {
        // console.log(data);
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
        } else {
          withReactContent(Swal).fire({
            icon: "success",
            title: "ជោគជ័យ",
            showConfirmButton: false,
            timer: 2000,
          });
          // reload page when timer end
          if (status === "isDefault") {
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        }
      };
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.log(error);
    }
  };
  const HandleSubmit = (e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      const data_edit = JSON.parse(e.target.dataset.edit.replace(/'/g, '"'));
      const id = data_edit.id;
      // console.log("id:", id, "page:", page);

      const formData = new FormData();
      formData.append("image_file", e.target.files[0]);
      try {
        const gql = `mutation MyMutation($id: String, $image: Upload) {
          uploadImageStock(id: $id, image: $image) {
            success
          }
        }`;
        const variables = {
          id: id,
          image: null,
        };
        const graphqlRequestBody = {
          operationName: null,
          query: gql,
          variables: variables,
        };
        const graphqlRequestBodyString = JSON.stringify(graphqlRequestBody);
        const graphqlFormData = new FormData();
        graphqlFormData.append("operations", graphqlRequestBodyString);
        graphqlFormData.append("map", '{"0": ["variables.image"]}');
        graphqlFormData.append(0, e.target.files[0]);
        // upload file

        const callback = (data) => {
          // console.log(data);
          if (data.errors) {
            withReactContent(Swal).fire({
              icon: "error",
              title: "មានបញ្ហា",
              html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
              confirmButtonText: "យល់ព្រម",
            });
          } else {
            withReactContent(Swal).fire({
              icon: "success",
              title: "ជោគជ័យ",
              showConfirmButton: false,
              timer: 2000,
            });
            // reload page when timer end
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        };
        aim.jquery_uploadFile(graphqlFormData, callback);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("No image selected");
    }
  };
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      console.log("useEffect Stock.js");
      aim.loadData(
        {
          // columns: columnLoad,
          tbId: tbId,
          page_name: page_name,
          load_page: "allStockOut",
          gql: `{
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            totalCount
            edgeCount
            edges {
              cursor
              node {
                id
                quantity
                description
                productstockreturnSet {
                  sumQuantity
                }
                product {
                  id
                  name
                  code
                  price
                  originalPrice
                  uom
                  currency {
                    shortName
                  }
                  latestproductquantitySet {
                    id
                    quantity
                  }
                }
              }
            }
          }`,
        },
        {
          // link: link,
          page_name: page_name,
          function_load: `${page_name}_datatable`,
          sumAmount: sumAmount,
          order_false: [1, 3, 4],
          width_column: {
            1: "100px",
          },
          columnHidden: [],
          orderColumn: ["id", "", "name_kh", "", "", ""],
          searching: ["name", "category"],
          // function
          data_execute: (result, load_page) => {
            // console.log("result:", result, "load_page:", load_page);
            const data_tb_row = [];
            result.recordsTotal = result.data[load_page].totalCount;
            result.recordsFiltered = result.data[load_page].totalCount;
            // console.log(result.recordsTotal);
            // forEach
            let number = 1;
            result.data[load_page].edges.forEach((v) => {
              let return_stock = 0;
              if (v.node.productstockreturnSet.sumQuantity > 0) {
                return_stock = v.node.productstockreturnSet.sumQuantity;
              }
              if (return_stock > 0) {
                return_stock = `${return_stock} ${v.node.product.uom}`;
              }
              var tb_row = [
                `${number++}`,
                `${v.node.product.name}<br>
                លេខកូដ: ${v.node.product.code}<br>
                តម្លៃលក់ = ${aim.currencyFormat(
                  v.node.product.price,
                  v.node.product.currency.shortName,
                  2
                )}<br>`,
                `${v.node.quantity} ${v.node.product.uom}`,
                `${return_stock}`,
                `${aim.addCommas(
                  v.node.product.latestproductquantitySet[0].quantity
                )} ${v.node.product.uom}`,
                `<div class="input-group mb-3 return_form">
                  <input class="form-control return_stock number" type="text" name="return[]" value="0">
                  <input class="stock_out_id" type="hidden" value="${v.node.id}">
                  <input class="product_id" type="hidden" value="${v.node.product.id}">
                  <input class="returned_stock" type="hidden" value="">
                  <div class="input-group-append">
                    <input class="input-group-text btn btn-primary return_done" type="button" value="រួចរាល់">
                  </div>
                </div>`,
              ];
              data_tb_row.push(tb_row);
            });
            return data_tb_row;
          },
        }
      );
      // document.queryselector .editData
      document.querySelector(`#${tbId}`).addEventListener("click", (e) => {
        const el = e.target;
        if (el.classList.contains("editData")) {
          const data_edit = JSON.parse(el.dataset.edit.replace(/'/g, '"'));
          const { id, page } = data_edit;
          navigate(`/post/${page}?action=edit&id=${id}`);
        } else if (el.classList.contains("updateStatus")) {
          const id = el.dataset.id;
          const status = el.checked;
          statusHandle(id, status, "status");
        } else if (el.classList.contains("updateDefault")) {
          const id = el.dataset.id;
          const is_status = el.checked;
          statusHandle(id, is_status, "isDefault");
        } else if (el.classList.contains("img-upload")) {
          $("#image_file").attr("data-edit", el.dataset.edit);
          $("#image_file").click();
        } else if (el.classList.contains("return_done")) {
          const stock_out_id =
            el.parentElement.parentElement.querySelector(".stock_out_id").value;
          const return_stock =
            el.parentElement.parentElement.querySelector(".return_stock").value;
          const product_id =
            el.parentElement.parentElement.querySelector(".product_id").value;
          const page = "postProductStockReturn";
          const gql = `
            mutation MyMutation {
              ${page}(id: "${stock_out_id}", productId: "${product_id}", returnData: ${return_stock}) {
                success
              }
            }
          `;
          const callback = (data) => {
            if (data.errors) {
              withReactContent(Swal).fire({
                icon: "error",
                title: "មានបញ្ហា",
                html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
                confirmButtonText: "យល់ព្រម",
              });
            } else {
              withReactContent(Swal).fire({
                icon: "success",
                title: "ជោគជ័យ",
                showConfirmButton: false,
                timer: 3500,
              });
            }
          };
          aim.jquery_free(gql, {}, callback);
        }
      });
      aim.dateRange(tbId);
    }
  });
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          {/* form_header */}
          {aim.header(formOption)}
          {/* form_report_content */}
          {aim.body(tbId, thead)}
          <form
            method="post"
            encType="multipart/form-data"
            name="form_upload_image"
            id="form_upload_image"
            className="col-12 d-none"
          >
            <div className="modal-body">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">Image Upload</span>
                </div>
                <input
                  type="file"
                  name="image_file"
                  id="image_file"
                  required
                  className="form-control input-lg"
                  accept="image/*"
                  onChange={(e) => {
                    HandleSubmit(e);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
