import { useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";
import { useContext, useEffect, useRef } from "react";
import { AuthContext } from "../../appState/AuthProvider";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export function operator(cd) {
  const $ = window.$;
  // console.log("operator");
  let sum_debit = 0;
  let sum_credit = 0;
  $(".itemAdd").each(function () {
    const $tr = $(this);
    const trFind = $tr.find(".account_name option:selected");

    const exchange_rate_fixed = trFind.data("exchange_rate_fixed");
    // const currency_id = trFind.data("currency_id");
    // const round = trFind.data("round");
    const default_currency = trFind.data("default_currency");

    const exchange = aim.exchangeRate(
      default_currency,
      exchange_rate_fixed,
      cd
    );
    // const debit = $tr.find(".debit").val() * exchange;
    // const credit = $tr.find(".credit").val() * exchange;
    const debit = $tr.find(".debit").val().replace(/,/g, "") * exchange;
    const credit = $tr.find(".credit").val().replace(/,/g, "") * exchange;
    sum_debit += debit;
    sum_credit += credit;
  });
  return { sum_debit, sum_credit };
}
export default function Post(props) {
  const $ = window.$;
  const navigate = useNavigate();
  const initialized = useRef(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const checkBalance = parseFloat($("#balance").val().replace(/,/g, ""));
    // if check balance is not equal to 0
    if (checkBalance === 0) {
      props.props.handleSubmit({ page: "create", data: null });
    } else {
      withReactContent(Swal).fire({
        icon: "error",
        title: "បរាជ័យ",
        html: `សមតុល្យមិនស្មើនឹង 0, សូមពិនិត្យមើលម្តងទៀត`,
        confirmButtonText: "យល់ព្រម",
      });
    }
  };
  const { user } = useContext(AuthContext);
  let business = "";
  user.business.forEach((x) => {
    business += `"${x.business}",`;
  });
  // console.log(business);
  useEffect(() => {
    if (!initialized.current) {
      console.log("useEffect StockAdd");
      initialized.current = true;
      const $ = window.$;
      const businessAll = aim.useGetBusiness();
      if (businessAll) {
        const businessSelect = document.getElementById("business");
        businessSelect.innerHTML = "";
        // add option to select
        businessSelect.innerHTML = `<option value="">ជ្រើសរើស</option>`;
        businessAll.forEach((element) => {
          // const option = document.createElement("option");
          // option.value = element.node.id;
          // option.text = element.node.name;
          element.node.businessSet.edges.forEach((v) => {
            const option = document.createElement("option");
            option.value = v.node.id;
            option.text = v.node.business + " - " + element.node.name;
            businessSelect.appendChild(option);
          });
        });
      }
      // const currency = aim.useGetCurrency();
      const currencyDefault = aim.useGetCurrencyDefault();
      const cd = currencyDefault[0].node;
      const coa = aim.useGetCoa(business);
      let coaOptions = "";
      if (coa) {
        coa.forEach((e) => {
          // base 64 decode id
          const id = atob(e.node.id);
          // get id
          const id_get = id.split(":")[1];
          coaOptions += `<option
              value="${id_get}"
              data-exchange_rate_fixed="${e.node.currency.rate.fixed}"
              data-currency_id="${e.node.currency.id}"
              data-round="${e.node.currency.round}"
              data-default_currency="${e.node.currency.isDefault}"
            >
            ${e.node.name}
            </option>`;
        });
      }
      const rowJournal = `<tr class="itemAdd">
          <td data-title="លុប">
            <span style="color: red; cursor: pointer" class="del_X">
              X
            </span>
          </td>
          <td data-title="​គណនី">
            <select
              name="account_name[]"
              class="form-control operator select2bs4 account_name"
            >
              ${coaOptions}
            </select>
          </td>
          <td data-title="ឥណពន្ធ">
            <input
              type="text"
              class="width-20 form-control operator number debit"
              name="debit[]"
              autocomplete="off"
              onClick="this.select()"
            />
          </td>
          <td data-title="ឥណទាន">
            <input
              type="text"
              class="width-20 form-control operator number credit"
              name="credit[]"
              autocomplete="off"
              onClick="this.select()"
            />
          </td>
        </tr>`;
      $(".type_acc").append(rowJournal);
      $(".type_acc").append(rowJournal);
      $(".select2bs4").select2({
        theme: "bootstrap4",
      });
      // add account
      document
        .getElementById("addAccount")
        .addEventListener("click", function () {
          // append node to div
          $(".type_acc").append(rowJournal);
          $(".select2bs4").select2({
            theme: "bootstrap4",
          });
        });
      $(`table`).on("keyup change", ".operator", function () {
        const d = operator(cd);
        $("#sum_debit").text(aim.multiple_digits(d.sum_debit, 2));
        $("#sum_credit").text(aim.multiple_digits(d.sum_credit, 2));
        const balance = d.sum_debit - d.sum_credit;
        $("#balance").val(aim.multiple_digits(balance, 2));
      });
      $("table").on("click", ".del_X", function () {
        const confirm_delete = () => {
          $(this).parents("tr").remove();
          operator(cd);
        };
        let count_row = parseInt($(".del_X").length);
        withReactContent(Swal)
          .fire({
            icon: "question",
            title: "ការជូនដំណឹង",
            text: "តើអ្នកពិតជាចង់លុបទិន្នន័យនេះមែនទេ?",
            showCancelButton: true,
            confirmButtonText: "យល់ព្រម",
            cancelButtonText: `ទេ`,
          })
          .then((result) => {
            if (result.isConfirmed) {
              if (count_row > 2) {
                confirm_delete();
              } else {
                Swal.fire({
                  icon: "info",
                  title: "ការជូនដំណឹង",
                  text: "មិនអាចលុបបានទេ!",
                });
              }
            }
            return false;
          });
      });
    }
  }, [business]);
  return (
    <div className="content-wrapper">
      <div className="container-fluid" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">ទិន្នានុប្បវត្តិ</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="row">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">អាជីវកម្ម</span>
                    </div>
                    <select
                      id="business"
                      name="business"
                      className="form-control"
                      required
                    ></select>
                  </div>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">ចំណងជើង</span>
                    </div>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      className="form-control"
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div id="no-more-tables" className="w-100">
                    <div className="card border-primary">
                      <h3 className="card-header bg-primary text-white text-center">
                        <span>ទិន្នានុប្បវត្តិពិស្ដា</span>
                      </h3>
                      <div className="card-body mt-2 row">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>លុប</th>
                              <th>​គណនី</th>
                              <th>ឥណពន្ធ</th>
                              <th>ឥណទាន</th>
                            </tr>
                            <tr className="text-center">
                              <th colSpan="4">
                                <input
                                  className="btn btn-primary"
                                  type="button"
                                  value="បង្កើតគណនី"
                                  id="addAccount"
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            className="type_acc group_type_acc"
                            id="type_acc"
                          ></tbody>
                          <tbody className="sub_total">
                            <tr align="right">
                              <th colSpan="2" style={{ textAlign: "right" }}>
                                <span>សរុប (Avg)</span>
                              </th>
                              <th style={{ textAlign: "right" }} id="sum_debit">
                                0
                              </th>
                              <th
                                style={{ textAlign: "right" }}
                                id="sum_credit"
                              >
                                0
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row justify-content-end">
                      <div className="row summary">
                        <div className="input-group mb-1">
                          <div className="input-group-prepend">
                            <span className="input-group-text">តុល្យភាព</span>
                          </div>
                          <input
                            type="text"
                            className="form-control number"
                            id="balance"
                            name="balance"
                            value="0"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/stock")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
