import { useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";
import { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// step 1
export default function Post(props) {
  console.log("stock edit");
  const $ = window.$;
  const initialized = useRef(false);
  const navigate = useNavigate();
  const id = props.props.searchParams.get("id");
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "update", data: id });
  };
  const currency = aim.useGetCurrency();
  const business = aim.useGetBusiness();

  const supplierNameRef = useRef();
  const supplierPhoneRef = useRef();
  const supplierAddressRef = useRef();
  const businessRef = useRef();
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      console.log("useEffect StockAdd");
      if (business) {
        const businessSelect = document.getElementById("business");
        businessSelect.innerHTML = "";
        // add option to select
        businessSelect.innerHTML = `<option value="">ជ្រើសរើស</option>`;
        business.forEach((element) => {
          // const option = document.createElement("option");
          // option.value = element.node.id;
          // option.text = element.node.name;
          element.node.businessSet.edges.forEach((v) => {
            const option = document.createElement("option");
            option.value = v.node.id;
            option.text = v.node.business + " - " + element.node.name;
            businessSelect.appendChild(option);
          });
        });
      }
      let currencyOptions = "";
      if (currency) {
        currency.forEach((element) => {
          // base 64 decode id
          const id = atob(element.node.id);
          // get id
          const id_get = id.split(":")[1];
          if (element.node.isDefault) {
            currencyOptions += `<option value="${id_get}" selected>${element.node.nameKh}</option>`;
          } else {
            currencyOptions += `<option value="${id_get}">${element.node.nameKh}</option>`;
          }
        });
      }

      // stock Edit
      const query = `
      query {
        productStock(id: "${id}") {
          id
          name
          image
          code
          location
          unitBox
          uom
          quantity
          price
          originalPrice
          status
          currency {
            id
            nameKh
            shortName
          }
          category {
            id
            name
          }
          supplier {
            id
            name
            phone
            address
          }
          business {
            id
            business
          }
        }
      }
    `;
      const variables = {
        id,
      };
      const callback = (data) => {
        const d = data.data.productStock;
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
          navigate("/post/stock?action=add");
        } else {
          supplierNameRef.current.value = d.supplier.name;
          supplierPhoneRef.current.value = d.supplier.phone;
          supplierAddressRef.current.value = d.supplier.address;
          businessRef.current.value = d.business.id;
          // stock
          let limit = 15,
            countItem = 0,
            countType = 0;
          $(`#addCate`).click(function () {
            let counter = $(".addItem").length + 1;
            if (counter === limit) {
              alert(`អ្នកបានឈានដល់ដែនកំណត់នៃការបន្ថែម ${counter}`);
            } else {
              const tbody_1 = `<tbody class="group_type${counter}">
            <tr>
              <td colspan="10" align="center">
                <input type="button" value="បន្ថែមផលិតផល" class="addItem btn btn-primary d-none" id="addItem${counter}" data-type="${counter}">
              </td>
            </tr>
          </tbody>`;
              const tbody_2 = `<tbody class="group_type${counter}">
            <tr>
              <td colspan="1" align="right" class="d-none">
                <span style="color:red;cursor: pointer;" class="del_type" id="group_type${counter}">X</span>
              </td>
              <td colspan="9" align="center">
                <div class="input-group" style="width: 100%; margin: 0 auto">
                  <div class="input-group-prepend">
                    <span class="input-group-text">ប្រភេទ</span>
                  </div>
                  <input type="text" name="type${counter}" class="type form-control" required autocomplete="off" value="${d.category.name}">
                </div>
              </td>
            </tr>
          </tbody>`;
              const tbody_3 = `<tbody class="type${counter} group_type${counter}">
          </tbody>`;
              $(tbody_3).prependTo(`#report #addCategory`);
              $(tbody_2).prependTo(`#report #addCategory`);
              $(tbody_1).prependTo(`#report #addCategory`);
              $(`#report #addItem${counter}`).click();
              counter++;
            }
          });
          $(`#report table`).on("click", ".addItem", function () {
            countType = $(this).attr("data-type");
            countItem = $(`.type${countType}`).find(".itemAdd").length + 1;
            if (countItem === 30) {
              // alert("អ្នកបានឈានដល់ដែនកំណត់នៃការបន្ថែម 30");
              withReactContent(Swal).fire({
                icon: "warning",
                title: "ការព្រមាន",
                text: `អ្នកបានឈានដល់ដែនកំណត់នៃការបន្ថែម 30`,
              });
            } else {
              var itemAdd = `<tr class="itemAdd">
            <td data-title="លុប" class="d-none">
              <span style="color:red;cursor: pointer;" class="del_X">X</span>
            </td>
            <td data-title="ឈ្មោះផលិតផល">
              <input type="text" class="form-control title" name="type${countType}[title[]]" autocomplete="off" required value="${d.name}">
            </td>
            <td data-title="លេខកូដ">
              <input type="text" class="form-control code" name="type${countType}[code[]]" autocomplete="off" value="${d.code}">
            </td>
            <td data-title="ទីតាំង">
              <input type="text" class="form-control location" name="type${countType}[location[]]" autocomplete="off" value="${d.location}">
            </td>
            <td data-title="Pcs/Box">
              <input type="text" class="form-control pBox number" name="type${countType}[box[]]" autocomplete="off" value="${d.unitBox}">
            </td>
            <td data-title="ឯកតា">
              <input type="text" class="form-control uom" name="type${countType}[uom[]]" autocomplete="off" value="${d.uom}">
            </td>
            <td data-title="បរិមាណ">
              <input type="text" class="form-control number operator qty" name="type${countType}[qty[]]" autocomplete="off" value="${d.quantity}">
            </td>
            <td data-title="តម្លៃលក់">
              <input type="text" class="form-control number operator price" name="type${countType}[price[]]" autocomplete="off" value="${d.price}">
            </td>
            <td data-title="ពិសេស">
              <input type="text" class="form-control number operator originalPrice" name="type${countType}[originalPrice[]]" autocomplete="off" value="${d.originalPrice}">
            </td>
            <td data-title="រូបិយប័ណ្ណ">
              <select name="type${countType}[currency[]]" class="form-control currency"></select>
            </td>
          </tr>`;
              $(itemAdd).prependTo($(`.type${countType}`));
              // add currency to select option
              $(`.type${countType} .currency`).html(currencyOptions);
              // set default currency by text value
              $(
                `.type${countType} .currency option:contains("${d.currency.nameKh}")`
              ).attr("selected", "selected");
              /* $(`.type${countType} .currency option[value="5"]`).attr(
                "selected",
                "selected"
              ); */
            }
          });
          $(`#addCate`).click();
          // focus input
          $("#addCategory").on("focus", ".form-control", function () {
            $(this)
              .parents(".itemAdd")
              .find(".form-control")
              .css("width", "100%");
            $(this).css("width", "220px");
          });
        }
      };
      aim.jquery_free(query, variables, callback);
    }
  }, [currency, business, $, id, navigate]);
  return (
    <div className="content-wrapper">
      <div className="container-fluid" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">បន្ថែមផលិតផល</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="row">
                  {/* business */}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">អាជីវកម្ម</span>
                    </div>
                    <select
                      id="business"
                      name="business"
                      className="form-control"
                      required
                      ref={businessRef}
                    ></select>
                  </div>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">អ្នកផ្គត់ផ្គង់</span>
                    </div>
                    <input
                      type="text"
                      name="supplier[name]"
                      id="supplier_name"
                      className="form-control"
                      autoComplete="off"
                      ref={supplierNameRef}
                    />
                  </div>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">ទូរស័ព្ទ</span>
                    </div>
                    <input
                      type="text"
                      name="supplier[phone]"
                      id="supplier_phone"
                      className="form-control"
                      autoComplete="off"
                      ref={supplierPhoneRef}
                    />
                  </div>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <span className="input-group-text">អាសយដ្ឋាន</span>
                    </div>
                    <input
                      type="text"
                      name="supplier[address]"
                      id="supplier_address"
                      className="form-control"
                      autoComplete="off"
                      ref={supplierAddressRef}
                    />
                  </div>
                </div>

                <div className="col-12 text-center mb-2 d-none">
                  <input
                    className="btn btn-primary"
                    type="button"
                    defaultValue="បន្ថែមក្រុម"
                    id="addCate"
                  />
                </div>

                <div className="row mt-4">
                  <div id="no-more-tables" className="w-100">
                    <div className="card border-primary">
                      <h3 className="card-header bg-primary text-white text-center">
                        <span>ផលិតផល</span>
                      </h3>
                      <div className="card-body mt-2 row">
                        <table className="table table-hover" id="addCategory">
                          <thead>
                            <tr>
                              <th className="d-none">លុប</th>
                              <th>ឈ្មោះផលិតផល</th>
                              <th>លេខកូដ</th>
                              <th>ទីតាំង</th>
                              <th>Pcs/Box</th>
                              <th>ឯកតា</th>
                              <th>បរិមាណ</th>
                              <th>តម្លៃលក់</th>
                              <th>ពិសេស</th>
                              <th>រូបិយប័ណ្ណ</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/stock")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  // step 1
}
