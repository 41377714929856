import * as aim from "../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function SubmitCheck(data) {
  const $ = window.$;
  // console.log(data);
  const dataForm = {};
  $("#addDataForm")
    .serializeArray()
    .map(function (x) {
      if (x.name.indexOf("[]") !== -1) {
        if (Array.isArray(dataForm[x.name])) {
          dataForm[x.name].push(x.value);
        } else {
          dataForm[x.name] = [x.value];
        }
      } else {
        dataForm[x.name] = x.value;
      }
      return true;
    });
  // console.log(data.page);
  if (data.page !== "update") {
    data.data = "";
  } else {
    data.data = `id: "${data.data}"`;
  }
  // return dataForm and data to the caller
  return { dataForm, data };
}
// create a function that will be called by the caller
export const CallbackCheck = (data, gql) => {
  if (data.errors) {
    withReactContent(Swal).fire({
      icon: "error",
      title: "មានបញ្ហា",
      html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
      confirmButtonText: "យល់ព្រម",
    });
  } else {
    withReactContent(Swal).fire({
      icon: "success",
      title: "ជោគជ័យ",
      showConfirmButton: false,
      timer: 3500,
    });
    const page = data.page;
    // check if the data is successfully posted
    if (data.data[page].success) {
      const callbackMessage = (data) => {
        // console.log("data:", data);
        if (data.data.sendTelegramMessage.success) {
          // console.log("success:", "success");
        } else {
          // console.log("error:", "not success");
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា Code T-2812-1",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>Telegram API មិនដំណើរការ`,
            confirmButtonText: "យល់ព្រម",
          });
        }
      };
      // console.log(gql);
      aim.jquery_free(gql, {}, callbackMessage);
    } else {
      console.log("error:", "not success");
    }
  }
};
