import { useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";
import { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// step 1
export default function Post(props) {
  // step 2
  const id = props.props.searchParams.get("id");
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "update", data: id });
  };
  console.log("TableEdit.js");
  const navigate = useNavigate();
  const businessNameRef = useRef();
  const businessRef = useRef();
  const tableNameRef = useRef();
  const tableNoRef = useRef();
  const tableTypeRef = useRef();
  const business = aim.useGetBusiness();
  useEffect(() => {
    if (business) {
      const businessSelect = document.getElementById("business");
      businessSelect.innerHTML = "";
      // add option to select
      businessSelect.innerHTML = `<option value="">ជ្រើសរើស</option>`;
      business.forEach((element) => {
        // const option = document.createElement("option");
        // option.value = element.node.id;
        // option.text = element.node.name;
        element.node.businessSet.edges.forEach((v) => {
          const option = document.createElement("option");
          option.value = v.node.id;
          option.text = v.node.business + " - " + element.node.name;
          businessSelect.appendChild(option);
        });
      });
    }
    if (id) {
      const gql = `query MyQuery($id: ID!) {
        table(id: $id) {
          id
          name
          number
          tableType
          business {
            id
            business
            Company {
              name
            }
          }
        }
      }`;
      const variables = {
        id,
      };
      const callback = (data) => {
        const d = data.data.table;
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
          navigate("/post/table?action=add");
        } else {
          // console.log("data:", d);
          businessNameRef.current.value =
            d.business.business + " - " + d.business.Company.name;
          businessRef.current.value = d.business.id;
          tableNameRef.current.value = d.name;
          tableNoRef.current.value = d.number;
          tableTypeRef.current.value = d.tableType;
        }
      };
      aim.jquery_free(gql, variables, callback);
    }
  }, [business, id, navigate]);
  // step 2
  // step 1
  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">ពត៌មានតុ</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                {/* business */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">អាជីវកម្ម</span>
                  </div>
                  <input
                    type="hidden"
                    name="business_name"
                    id="business_name"
                    ref={businessNameRef}
                  />
                  <select
                    id="business"
                    name="business"
                    className="form-control"
                    onChange={(e) => {
                      document.getElementById("business_name").value =
                        e.target.options[e.target.selectedIndex].text;
                    }}
                    required
                    ref={businessRef}
                  ></select>
                </div>
                {/* table name */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ឈ្មោះ​តុ</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="table_name"
                    id="table_name"
                    autoComplete="off"
                    required
                    ref={tableNameRef}
                  />
                </div>
                {/* table number */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">លេខតុ</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="table_no"
                    id="table_no"
                    autoComplete="off"
                    required
                    ref={tableNoRef}
                  />
                </div>
                {/* table type */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ប្រភេទតុ</span>
                  </div>
                  <select
                    className="form-control"
                    name="table_type"
                    id="table_type"
                    required
                    ref={tableTypeRef}
                  >
                    <option value="nomal">ធម្មតា</option>
                    <option value="vip">ពិសេស</option>
                  </select>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/table")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  // step 1
}
