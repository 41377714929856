import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import * as aim from "../../js/function.easy7d";

export default function Journal() {
  const initialized = useRef(false);
  const page_name = "journals";
  const tbId = `tb_${page_name}`;
  const formOption = (
    <Link
      to={`/post/${page_name}?action=add`}
      className="btn btn-success pull-right mx-1 addRow"
      key="addRow"
    >
      <i className="fa fa-plus" />
    </Link>
  );
  const thead = aim.datatableHeadFoot(
    ["លរ", "ចំណងជើង", "លេខគណនី", "ឈ្មោះ​គណនី", "ឥណពន្ធ", "ឥណទាន", "សកម្មភាព"],
    3
  );
  const sumAmount = [4, 5];
  const curr = aim.useGetCurrency();
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      aim.loadData(
        {
          // columns: columnLoad,
          currency: curr,
          tbId: tbId,
          page_name: page_name,
          load_page: "allJournals",
          gql: `{
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            totalCount
            edgeCount
            edges {
              cursor
              node {
                id
                debit
                credit
                rate
                journalTitle {
                  title
                }
                accountName {
                  accountNo
                  name
                  category
                }
              }
            }
          }`,
        },
        {
          page_name: page_name,
          function_load: `${page_name}_datatable`,
          sumAmount: sumAmount,
          order_false: [2, 3],
          width_column: {
            1: "100px",
          },
          columnHidden: [],
          orderColumn: ["id", "name", "", "", ""],
          searching: ["name_Icontains"],
          /* otherOption: {
            status: true,
          }, */
          // function
          data_execute: (result, load_page) => {
            // step 7
            // console.log("result:", result, "load_page:", load_page);
            const data_tb_row = [];
            result.recordsTotal = result.data[load_page].totalCount;
            result.recordsFiltered = result.data[load_page].totalCount;
            // console.log(result.recordsTotal);
            // console.log("result:", result.data[load_page].edges);
            // forEach
            let number = 1;
            result.data[load_page].edges.forEach((v) => {
              // base 64 decode id
              // const id = atob(v.node.id);
              // get id
              // const id_get = id.split(":")[1];
              const action = `<i title="Edit" class="btn btn-success editData fa fa-pencil-square-o" aria-hidden="true" data-edit="{'id':'${v.node.id}','page':'${page_name}'}"></i>`;
              var tb_row = [
                `${number++}`,
                `${v.node.journalTitle.title}`,
                `${v.node.accountName.accountNo}`,
                `${v.node.accountName.name} - ${v.node.accountName.category}`,
                `${v.node.debit}`,
                `${v.node.credit}`,
                `${action}`,
              ];
              data_tb_row.push(tb_row);
            });
            return data_tb_row;
          },
        }
      );
      aim.dateRange(tbId);
    }
  });

  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12" id="report">
        <div className="page-loading">
          {/* form_header */}
          {aim.header(formOption)}
          {/* form_report_content */}
          {aim.body(tbId, thead)}
        </div>
      </div>
    </div>
  );
}
