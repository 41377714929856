import React, { useEffect, useRef } from "react";
import * as aim from "../../js/function.easy7d";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

export default function Post(props) {
  console.log("customer edit");
  const $ = window.$;
  const initialized = useRef(false);
  const navigate = useNavigate();
  const id = props.props.searchParams.get("id");
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "update", data: id });
  };
  const business = aim.useGetBusiness();
  const businessRef = useRef();
  const phoneRef = useRef();
  const customerNameRef = useRef();
  const customerDiscountRef = useRef();
  const birthdayRef = useRef();
  const genderRef = useRef();
  const addressRef = useRef();
  useEffect(() => {
    console.log("useEffect Customer add");
    if (!initialized.current) {
      initialized.current = true;
      if (business) {
        const businessSelect = document.getElementById("business");
        businessSelect.innerHTML = "";
        // add option to select
        business.forEach((element) => {
          // const option = document.createElement("option");
          // option.value = element.node.id;
          // option.text = element.node.name;
          element.node.businessSet.edges.forEach((v) => {
            const option = document.createElement("option");
            option.value = v.node.id;
            option.text = v.node.business + " - " + element.node.name;
            businessSelect.appendChild(option);
          });
        });
      }
      const gql = `query Customer($id: ID!) {
        customer(id: $id) {
          id
          name
          address
          birthday
          discount
          gender
          idCard
          image
          phone {
            id
            number
          }
          business {
            edges {
              node {
                id
                business
              }
            }
          }
        }
      }`;
      const variables = {
        id,
      };
      const callback = (data) => {
        const d = data.data.customer;
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុសមានបញ្ហា សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
          navigate("/customer");
        } else {
          // businessRef.current.value = d.business.edges.map((v) => v.node.id);
          $("#birthday")
            .datepicker({ dateFormat: "dd-mm-yy" })
            .datepicker("setDate", new Date());
          phoneRef.current.value = d.phone.map((v) => v.id);
          customerNameRef.current.value = d.name;
          customerDiscountRef.current.value = d.discount;
          const birthday = new Date(d.birthday);
          birthdayRef.current.value = `${
            birthday.getDate() < 10
              ? "0" + birthday.getDate()
              : birthday.getDate()
          }-${
            birthday.getMonth() + 1 < 10
              ? "0" + (birthday.getMonth() + 1)
              : birthday.getMonth() + 1
          }-${birthday.getFullYear()}`;

          /* const formattedDate = `${
            birthday.getMonth() + 1 < 10
              ? "0" + (birthday.getMonth() + 1)
              : birthday.getMonth() + 1
          }-${
            birthday.getDate() < 10
              ? "0" + birthday.getDate()
              : birthday.getDate()
          }-${birthday.getFullYear()}`;
          birthdayRef.current.value = formattedDate; */
          genderRef.current.value = d.gender;
          addressRef.current.value = d.address;
          console.log("data:", d.phone);
          $(".phone").select2({
            tags: true,
          });
          const phone = document.getElementById("phone");
          phone.innerHTML = "";
          // async forEach then console.log the Loop is completed
          const forEachAsync = async (array, callback) => {
            for (let index = 0; index < array.length; index++) {
              await callback(array[index], index, array);
            }
            // console.log("Loop is completed");
          };
          forEachAsync(d.phone, async (element) => {
            const option = document.createElement("option");
            option.value = element.number;
            option.text = element.number;
            option.selected = true;
            phone.appendChild(option);
          });

          const business = document.getElementById("business");
          d.business.edges.forEach((element) => {
            const option = business.querySelector(
              `option[value="${element.node.id}"]`
            );
            // console.log("option:", option);
            if (option) {
              option.selected = true;
            }
          });
          $(".business").select2();
        }
      };
      aim.jquery_free(gql, variables, callback);
    }
  });
  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">ព័ត៌មានអតិថិជន</h2>
            </div>
            <form
              method="post"
              className="form col-12 p-0 center-block mt-4"
              id="addDataForm"
              name="addDataForm"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="input-group mb-4 card-default">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ជ្រើសរើសអាជីវកម្ម</span>
                  </div>
                  <select
                    className="form-control business select2"
                    multiple="multiple"
                    name="business[]"
                    id="business"
                    ref={businessRef}
                  ></select>
                </div>
                <div className="row">
                  <div
                    className="input-group mb-4 card-default col"
                    id="phone_parent"
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">លេខទូរស័ព្ទ</span>
                    </div>
                    <select
                      className="form-control phone select2"
                      multiple="multiple"
                      name="phone[]"
                      id="phone"
                      ref={phoneRef}
                    ></select>
                  </div>
                </div>
                <div className="row">
                  <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">ឈ្មោះ​អតិថិជន</span>
                    </div>
                    <input
                      className="form-control"
                      name="customer_name"
                      id="customer_name"
                      type="text"
                      autoComplete="off"
                      ref={customerNameRef}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="input-group mb-4 col">
                    <div className="input-group-prepend">
                      <span className="input-group-text">បញ្ចុះតម្លៃ %</span>
                    </div>
                    <input
                      className="form-control number"
                      name="customer_discount"
                      id="customer_discount"
                      type="text"
                      autoComplete="off"
                      ref={customerDiscountRef}
                    />
                  </div>
                </div>
                <div className="row col-12 input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ថ្ងៃកំណើត</span>
                  </div>
                  <input
                    type="text"
                    name="birthday"
                    id="birthday"
                    className="form-control"
                    ref={birthdayRef}
                  />
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ភេទ</span>
                  </div>
                  <select
                    className="form-control"
                    name="gender"
                    id="gender"
                    required
                    ref={genderRef}
                  >
                    <option value="M">ប្រុស</option>
                    <option value="F">ស្រី</option>
                    <option value="O">មិនស្គាល់</option>
                  </select>
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">អាស័យដ្ឋាន</span>
                  </div>
                  <input
                    className="form-control"
                    name="address"
                    id="address"
                    type="text"
                    autoComplete="off"
                    ref={addressRef}
                  />
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/customer")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
