import Page404 from "../../pages/Page404";
import CustomerAdd from "./CustomerAdd";
import CustomerEdit from "./CustomerEdit";
import { useNavigate } from "react-router-dom";

// for add Customer
import * as aim from "../../js/function.easy7d";
import SubmitCheck, { CallbackCheck } from "../HandleFunction";

export default function Post(props) {
  console.log("Customer post");
  const navigate = useNavigate();
  // const $ = window.$;
  // console.log(props);
  const action = props.searchParams.get("action");
  // handle submit
  const handleSubmit = (data) => {
    try {
      const getData = SubmitCheck(data);
      console.log(getData);

      const d = getData.dataForm;
      // edit data
      const page = "postCustomer";

      const businessList = d["business[]"];
      const phoneList = d["phone[]"];
      const gql = `mutation {
          ${page}(
            ${data.data}
            address: "${d.address}"
            birthday: "${d.birthday}"
            business: ${JSON.stringify(businessList)}
            discount: "${d.customer_discount}"
            name: "${d.customer_name}"
            gender: "${d.gender}"
            phone: ${JSON.stringify(phoneList)}
          ) {
            customer {
              name
              discount
            }
          }
        }`;
      const callback = (data) => {
        // console.log(data);
        data.page = page;
        // edit data
        let action = "អ្នកប្រើប្រាស់";
        if (data.page !== "update") {
          action = `ធ្វើបច្ចុប្បន្នភាព ${action}`;
        }
        // edit data
        let dataMessage = `
<b>${action}</b>

<b>ឈ្មោះអតិថិជន:</b> ${d.customer_name}
<b>លេខទូរស័ព្ទ:</b> ${d.phone}
<b>បញ្ចុះតម្លៃ:</b> ${d.customer_discount}
<b>ភេទ:</b> ${d.gender}
<b>អាសយដ្ឋាន:</b> ${d.address}
<b>ថ្ងៃខែឆ្នាំកំណើត:</b> ${d.birthday}
<b>ក្រុមហ៊ុន:</b> ${businessList}`;
        dataMessage = dataMessage.replace(/\n/g, "\\n");
        // edit data Telegram Function
        const functionName = "Customer";
        const gql = `
          mutation MyMutation {
            sendTelegramMessage(
              text: "${dataMessage}"
              functionName: "${functionName}"
            ) {
              success
            }
          }
        `;
        // edit data
        CallbackCheck(data, gql);
        navigate(`/${functionName}`);
      };
      // console.log(gql);
      aim.jquery_free(gql, {}, callback);
    } catch (error) {
      console.log(error);
    }
  };

  if (action === "add") {
    // multiple props clearForm={clearForm} and handleSubmit={handleSubmit} to CustomerAdd
    return <CustomerAdd props={{ handleSubmit }} />;
  } else if (action === "edit") {
    return (
      <CustomerEdit
        props={{
          ...props,
          handleSubmit,
        }}
      />
    );
  } else {
    return <Page404 />;
  }
}
