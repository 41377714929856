import { useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";
import { useEffect } from "react";
// step 1
export default function Post(props) {
  const $ = window.$;
  // step 2
  const navigate = useNavigate();
  const business = aim.useGetBusiness();
  const currency = aim.useGetCurrency();
  useEffect(() => {
    if (business) {
      const businessSelect = document.getElementById("business");
      businessSelect.innerHTML = "";
      // add option to select
      businessSelect.innerHTML = `<option value="">ជ្រើសរើស</option>`;
      business.forEach((element) => {
        // const option = document.createElement("option");
        // option.value = element.node.id;
        // option.text = element.node.name;
        element.node.businessSet.edges.forEach((v) => {
          const option = document.createElement("option");
          option.value = v.node.id;
          option.text = v.node.business + " - " + element.node.name;
          businessSelect.appendChild(option);
        });
      });
    }
    let currencyOptions = "";
    if (currency) {
      currency.forEach((element) => {
        // base 64 decode id
        const id = atob(element.node.id);
        // get id
        const id_get = id.split(":")[1];
        if (element.node.isDefault) {
          currencyOptions += `<option value="${id_get}" selected>${element.node.nameKh}</option>`;
        } else {
          currencyOptions += `<option value="${id_get}">${element.node.nameKh}</option>`;
        }
      });
    }
    const currencySelect = document.getElementById("currency");
    currencySelect.innerHTML = currencyOptions;
  }, [business, currency]);
  // step 2
  // step 3
  // step 4 setup database on server and create schema
  // step 1
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "create", data: null });
  };
  const addOption = () => {
    const addOption = `
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">ជម្រើស</span>
        </div>
        <input
          class="form-control option"
          type="text"
          name="option[]"
          autoComplete="off"
          required
        />
        <div class="input-group-append">
          <button class="input-group-text delete_option bg-danger">
            លុប
          </button>
        </div>
      </div>`;
    // remove d-none class to show option
    $("#option_information").removeClass("d-none");
    $("#option_information").append(addOption);
  };
  const addExtra = () => {
    const addExtra = `
    <div class="option_row">
        <h3 class="text-primary input-group-append">
          បន្ថែមទំនិញ <button class="input-group-text delete_extra text-danger ml-2">លុប</button>
        </h3>
        <div class="input-group mb-3 row">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
                <span class="input-group-text">ចំណងជើង</span>
            </div>
            <input class="form-control" type="text" autocomplete="off" onClick="this.select();" name="extra_title[]">
          </div>
            
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">តម្លៃ</span>
            </div>
            <input
              class="form-control number"
              type="text"
              name="extra_price[]"
              autoComplete="off"
              required
            />
          </div>
        </div>    
    </div>`;
    // remove d-none class to show extra
    $("#extra_information").removeClass("d-none");
    $("#extra_information").append(addExtra);
  };
  // remove option, extra
  $(document).on("click", ".delete_option, .delete_extra", function () {
    $(this).parent().parent().remove();
  });
  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">ពត៌មានការលក់</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                {/* business */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">អាជីវកម្ម</span>
                  </div>
                  <input
                    type="hidden"
                    name="business_name"
                    id="business_name"
                  />
                  <select
                    id="business"
                    name="business"
                    className="form-control"
                    onChange={(e) => {
                      document.getElementById("business_name").value =
                        e.target.options[e.target.selectedIndex].text;
                    }}
                    required
                  ></select>
                </div>
                {/* ប្រភេទទំនិញ */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ប្រភេទទំនិញ</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="category"
                    id="category"
                    autoComplete="off"
                    required
                  />
                </div>
                {/* ឈ្មោះទំនិញ */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ឈ្មោះទំនិញ</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="title"
                    id="title"
                    autoComplete="off"
                    required
                  />
                </div>
                {/* sale_type */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ប្រភេទការលក់</span>
                  </div>
                  <select
                    className="form-control"
                    name="sale_type"
                    id="sale_type"
                  >
                    <option value="Food">ម្ហូប</option>
                    <option value="Drink">គ្រឿងទឹក</option>
                    <option value="Other">ផ្សេងៗ</option>
                  </select>
                </div>
                {/* រូបិយប័ណ្ណ */}
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">រូបិយប័ណ្ណ</span>
                  </div>
                  <select
                    className="form-control"
                    name="currency"
                    id="currency"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="input-group mb-3">
                  {/* តម្លៃ */}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">តម្លៃ</span>
                    </div>
                    <input
                      className="form-control number"
                      type="text"
                      name="price"
                      id="price"
                      autoComplete="off"
                      required
                    />
                  </div>
                </div>
                {/* button options */}
                <div className="mb-3 text-center">
                  <div id="option_information" className="d-none"></div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={addOption}
                  >
                    ជម្រើស
                  </button>
                </div>
                {/* button add center */}
                <div className="mb-3 text-center">
                  <div id="extra_information" className="d-none"></div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={addExtra}
                  >
                    បន្ថែម
                  </button>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/sale_information")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
  // step 1
}
