import React, { useEffect, useRef } from "react";
import * as aim from "../../js/function.easy7d";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

export default function Post(props) {
  const navigate = useNavigate();
  const id = props.props.searchParams.get("id");
  // console.log(id);
  const files = [
    "usa.png",
    "uk.png",
    "gold.png",
    "default.png",
    "Zimbabwe.png",
    "Zambia.png",
    "Yemen.png",
    "Western-Sahara.png",
    "Wallis-and-Futuna.png",
    "Wales.png",
    "WTO.png",
    "WHO.png",
    "Virgin-Islands-US.png",
    "Virgin-Islands-British.png",
    "Viet-Nam.png",
    "Venezuela.png",
    "Vatican-City.png",
    "Vanutau.png",
    "Uzbekistan.png",
    "Uruguay.png",
    "United-States-of-America(USA).png",
    "United-Nations.png",
    "United-Arab-Emirates.png",
    "Ukraine.png",
    "Uganda.png",
    "UNICEF.png",
    "UNESCO.png",
    "Tuvalu.png",
    "Turks-and-Caicos-Islands.png",
    "Turkmenistan.png",
    "Turkey.png",
    "Tunisia.png",
    "Tristan-da-Cunha.png",
    "Trinidad-&-Tobago.png",
    "Tonga.png",
    "Tokelau.png",
    "Togo.png",
    "Timor-Leste.png",
    "Thailand.png",
    "Tanzania.png",
    "Tajikistan.png",
    "Taiwan.png",
    "Tahiti(French-Polinesia).png",
    "Syria.png",
    "Switzerland.png",
    "Sweden.png",
    "Swaziland.png",
    "Svalbard and Jan Mayen.png",
    "Suriname.png",
    "Sudan.png",
    "St-Vincent-&-the-Grenadines.png",
    "St-Kitts-&-Nevis.png",
    "Sri-Lanka.png",
    "Spain.png",
    "Southern-Sudan.png",
    "South-Korea.png",
    "South-Georgia-and-South-Sandwich.png",
    "South-Africa.png",
    "Somaliland.png",
    "Somalia.png",
    "Solomon-Islands.png",
    "Slovenia.png",
    "Slovakia.png",
    "Sint-Maarten.png",
    "Singapore.png",
    "Sierra-Leone.png",
    "Seychelles.png",
    "Serbia(Yugoslavia).png",
    "Senegal.png",
    "Scotland.png",
    "Saudi-Arabia.png",
    "Sao-Tome-&-Principe.png",
    "San-Marino.png",
    "Samoa.png",
    "Saint-Pierre-and-Miquelon.png",
    "Saint-Martin.png",
    "Saint-Lucia.png",
    "Saint-Helena.png",
    "Saint-Barthelemy.png",
    "Rwanda.png",
    "Russian-Federation.png",
    "Romania.png",
    "Reunion.png",
    "Red-Cross.png",
    "Quebec.png",
    "Qatar.png",
    "Puerto-Rico.png",
    "Portugal.png",
    "Poland.png",
    "Pitcairn.png",
    "Philippines.png",
    "Peru.png",
    "Paraguay.png",
    "Papua-New-Guinea.png",
    "Panama.png",
    "Palestine.png",
    "Palau.png",
    "Pakistan.png",
    "Oman.png",
    "Olimpic-Movement.png",
    "OPEC.png",
    "OECD.png",
    "OAS.png",
    "Norway.png",
    "Northern-Mariana.png",
    "Northern-Ireland.png",
    "Northern-Cyprus.png",
    "North-Korea.png",
    "Norfolk.png",
    "Niue.png",
    "Nigeria.png",
    "Niger.png",
    "Nicaragua.png",
    "New-Zealand.png",
    "New-Caledonia.png",
    "Netherlands.png",
    "Netherlands-Antilles.png",
    "Nepal.png",
    "Nauru.png",
    "Namibia.png",
    "NATO.png",
    "Myanmar(Burma).png",
    "Mozambique.png",
    "Morocco.png",
    "Montserrat.png",
    "Montenegro.png",
    "Mongolia.png",
    "Monaco.png",
    "Moldova.png",
    "Micronesia.png",
    "Mexico.png",
    "Mayotte.png",
    "Mauritius.png",
    "Mauritania.png",
    "Martinique.png",
    "Marshall-Islands.png",
    "Malta.png",
    "Mali.png",
    "Maldives.png",
    "Malaysia.png",
    "Malawi.png",
    "Madagascar.png",
    "Macedonia.png",
    "Macao.png",
    "Luxembourg.png",
    "Lithuania.png",
    "Liechtenshein.png",
    "Libya.png",
    "Liberia.png",
    "Lesotho.png",
    "Lebanon.png",
    "Latvia.png",
    "Laos.png",
    "Kyrgyzstan.png",
    "Kuwait.png",
    "Kosovo.png",
    "Kiribati.png",
    "Kenya.png",
    "Kazakhstan.png",
    "Jordan.png",
    "Jersey.png",
    "Japan.png",
    "Jamaica.png",
    "Italy.png",
    "Israel.png",
    "Isle-of-Man.png",
    "Islamic-Conference.png",
    "Ireland.png",
    "Iraq.png",
    "Iran.png",
    "Indonezia.png",
    "India.png",
    "Iceland.png",
    "IHO.png",
    "IAEA.png",
    "Hungary.png",
    "Hong-Kong.png",
    "Honduras.png",
    "Heard Island and McDonald.png",
    "Haiti.png",
    "Guyana.png",
    "Guinea.png",
    "Guinea-Bissau.png",
    "Guernsey.png",
    "Guatemala.png",
    "Guam.png",
    "Guadeloupe.png",
    "Grenada.png",
    "Greenland.png",
    "Greece.png",
    "Gibraltar.png",
    "Ghana.png",
    "Germany.png",
    "Georgia.png",
    "Gambia.png",
    "Galicia.png",
    "Gabon.png",
    "French-Southern-and-Antarctic-Lands.png",
    "French-Guiana.png",
    "France.png",
    "Finland.png",
    "Fiji.png",
    "Faroes.png",
    "Falkland-(Malvinas).png",
    "FAO.png",
    "European-Union.png",
    "Ethiopia.png",
    "Estonia.png",
    "Eritrea.png",
    "Equatorial-Guinea.png",
    "England.png",
    "El-Salvador.png",
    "Egypt.png",
    "Ecuador.png",
    "Dominican-Republic.png",
    "Dominica.png",
    "Djibouti.png",
    "Denmark.png",
    "Czech-Republic.png",
    "Cyprus.png",
    "Curacao.png",
    "Cuba.png",
    "Croatia.png",
    "Cote-d'Ivoire.png",
    "Costa-Rica.png",
    "Cook-Islands.png",
    "Congo-Kinshasa(Zaire).png",
    "Congo-Brazzaville.png",
    "Comoros.png",
    "Commonwealth.png",
    "Colombia.png",
    "Cocos-(Keeling).png",
    "Christmas.png",
    "China.png",
    "Chile.png",
    "Chad.png",
    "Central-African-Republic.png",
    "Cayman-Islands.png",
    "Catalonia.png",
    "Cape-Verde.png",
    "Canada.png",
    "Cameroon.png",
    "Cambodia.png",
    "CIS.png",
    "CARICOM.png",
    "Burundi.png",
    "Burkina-Faso.png",
    "Bulgaria.png",
    "Brunei.png",
    "British-Indian-Ocean-Territory.png",
    "Brazil.png",
    "Bouvet.png",
    "Botswana.png",
    "Bosnia-&-Herzegovina.png",
    "Bonaire-Sint Eustatius-and-Saba.png",
    "Bolivia.png",
    "Bhutan.png",
    "Bermuda.png",
    "Benin.png",
    "Belize.png",
    "Belgium.png",
    "Belarus.png",
    "Basque-Country.png",
    "Barbados.png",
    "Bangladesh.png",
    "Bahrain.png",
    "Bahamas.png",
    "Azerbaijan.png",
    "Austria.png",
    "Australia.png",
    "Aruba.png",
    "Armenia.png",
    "Argentina.png",
    "Arab-League.png",
    "Antigua-&-Barbuda.png",
    "Anguilla.png",
    "Angola.png",
    "Andorra.png",
    "American-Samoa.png",
    "Algeria.png",
    "Alderney.png",
    "Albania.png",
    "Aland.png",
    "African-Union.png",
    "Afghanistan.png",
    "ASEAN.png",
  ];
  // sort array
  files.sort();
  // file select option
  const flag = files.map((file, index) => {
    return (
      <option key={index} value={file}>
        {file}
      </option>
    );
  });
  const nameKh = useRef(),
    shortName = useRef(),
    order = useRef(),
    shortcutKey = useRef(),
    round = useRef(),
    rateBuy = useRef(),
    rateSale = useRef(),
    rateFixed = useRef(),
    flagName = useRef();
  useEffect(() => {
    if (id) {
      const gql = `query MyQuery($id: ID!) {
        currencys(id: $id) {
          flag {
            name
          }
          nameKh
          shortName
          shortcutKey
          order
          round
          rate {
            buy
            sale
            fixed
          }
        }
      }`;
      const variables = {
        id,
      };
      const callback = (data) => {
        const d = data.data.currencys;
        // console.log(d);
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
          navigate("/post/user?action=add");
        } else {
          nameKh.current.value = d.nameKh;
          shortName.current.value = d.shortName;
          order.current.value = d.order;
          shortcutKey.current.value = d.shortcutKey;
          round.current.value = d.round;
          rateBuy.current.value = d.rate.buy;
          rateSale.current.value = d.rate.sale;
          rateFixed.current.value = d.rate.fixed;
          flagName.current.value = d.flag.name;
          // change flagName select option value
          /* const select = document.querySelector("#flag");
          const options = select.options;
          for (let i = 0; i < options.length; i++) {
            if (options[i].value === d.flag.name) {
              select.selectedIndex = i;
              break;
            }
          } */
          // update data-title attribute of shortcut key by ref
          shortcutKey.current.setAttribute("data-title", d.shortcutKey);
          /* document
            .querySelector(".shortcut")
            .setAttribute("data-title", d.shortcutKey); */
        }
      };
      aim.jquery_free(gql, variables, callback);
    }
  }, [id, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit({ page: "update", data: id });
  };
  const shortcut = (e) => {
    e.preventDefault();
    /* const shortcut = document.querySelector("#shortcut_key");
    console.log(shortcut.value); */
    // shortcut.value = e.key;
    // console.log(e.key);
    const gql = `query MyQuery($shortcutKey: String!) {
      allCurrencys(shortcutKey: $shortcutKey) {
        edges {
          node {
            shortcutKey
          }
        }
      }
    }`;
    const variables = {
      shortcutKey: e.key,
    };
    // accept key code
    const keyCode = [
      "Backspace",
      "Tab",
      "Enter",
      "Shift",
      "Control",
      "Alt",
      "CapsLock",
      "Escape",
      "PageUp",
      "PageDown",
      "End",
      "Home",
      "ArrowLeft",
      "ArrowUp",
      "ArrowRight",
      "ArrowDown",
      "Insert",
      "Delete",
      "F1",
      "F2",
      "F3",
      "F4",
      "F5",
      "F6",
      "F7",
      "F8",
      "F9",
      "F10",
      "F11",
      "F12",
      "Meta",
    ];
    if (keyCode.includes(e.key)) {
      return false;
    }

    const callback = (data) => {
      // console.log(data);
      const d = data.data.allCurrencys.edges;
      if (d.length > 0) {
        // get data-title attribute of shortcut key
        const key = document
          .querySelector(".shortcut")
          .getAttribute("data-title");
        if (key === e.key) {
          return false;
        } else if (d[0].node.shortcutKey === e.key) {
          // console.log(d[0].node.shortcutKey);5
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            text: "លេខកូដនេះបានប្រើរួចហើយ។",
          });
          document.querySelector("#shortcut_key").value = "";
        }
      } else {
        document.querySelector("#shortcut_key").value = e.key;
      }
    };
    aim.jquery_free(gql, variables, callback);
  };
  return (
    <div className="content-wrapper">
      <div className="container" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">រូបិយប័ណ្ណ</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ទុងជាតិ</span>
                  </div>
                  <select
                    id="flag"
                    name="flag"
                    className="form-control"
                    ref={flagName}
                  >
                    {flag}
                  </select>
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ឈ្មោះរូបិយប័ណ្ណ</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    required
                    ref={nameKh}
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ឈ្មោះ‌ខ្លី</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    name="short_name"
                    id="short_name"
                    autoComplete="off"
                    required
                    ref={shortName}
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">លំដាប់</span>
                  </div>
                  <input
                    className="form-control currency-s0"
                    type="text"
                    name="order"
                    id="order"
                    autoComplete="off"
                    required
                    ref={order}
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Short-cut Key</span>
                  </div>
                  <input
                    className="form-control shortcut"
                    type="text"
                    name="shortcut_key"
                    id="shortcut_key"
                    autoComplete="off"
                    data-title=""
                    required
                    // on key up
                    onKeyUp={shortcut}
                    ref={shortcutKey}
                  />
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">ទសភាគ</span>
                  </div>
                  <input
                    className="form-control currency-s0"
                    type="text"
                    name="round"
                    id="round"
                    required
                    ref={round}
                  />
                </div>
                <div className="row mb-3">
                  <div className="col-4">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">អត្រាទិញ</span>
                      </div>
                      <input
                        className="form-control currency-s0"
                        type="text"
                        name="rateBuy"
                        id="rateBuy"
                        required
                        ref={rateBuy}
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">អត្រាលក់</span>
                      </div>
                      <input
                        className="form-control currency-s0"
                        type="text"
                        name="rateSale"
                        id="rateSale"
                        required
                        ref={rateSale}
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">អត្រាថេរ</span>
                      </div>
                      <input
                        className="form-control currency-s0"
                        type="text"
                        name="rateFixed"
                        id="rateFixed"
                        required
                        ref={rateFixed}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <button type="submit" className="btn btn-primary">
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={props.props.clearForm}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
