import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as aim from "../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function SignIn() {
  // const $ = window.$;
  const navigate = useNavigate();
  const [userInfor, setUserInfor] = useState({
    username: "",
    password: "",
  });
  const handleChange = (e) => {
    setUserInfor({
      ...userInfor,
      [e.target.name]: e.target.value,
    });
  };
  const handlSubmit = (e) => {
    e.preventDefault();
    const gql = `mutation SignIn($password: String!, $username: String!) {
      tokenAuth(input: {username: $username, password: $password}) {
        payload
        token
        user {
          firstName
          lastName
          account {
            business {
              edges {
                node {
                  id
                  business
                }
              }
            }
          }
        }
      }
    }`;
    const variables = {
      password: userInfor.password,
      username: userInfor.username,
    };
    // const [callbackData, setCallbackData] = useState(null);
    const callback = (data) => {
      // console.log(data);
      if (data.errors) {
        withReactContent(Swal).fire({
          icon: "error",
          title: "មានបញ្ហា",
          text: "កំហុសក្នុងការតភ្ជាប់ សូមបញ្ចូលព័ត៌មានត្រឹមត្រូវរបស់អ្នក",
          confirmButtonText: "យល់ព្រម",
        });
      } else {
        // Cookies.set("token", data.tokenAuth.token);
        // add user first name and last name to data.data.tokenAuth.payload
        data.data.tokenAuth.payload.firstName =
          data.data.tokenAuth.user.firstName;
        data.data.tokenAuth.payload.lastName =
          data.data.tokenAuth.user.lastName;
        localStorage.setItem(
          "user",
          JSON.stringify(data.data.tokenAuth.payload)
        );
        // set user first name and last name
        /* localStorage.setItem(
          "user_firstName",
          data.data.tokenAuth.user.firstName
        );
        localStorage.setItem(
          "user_lastName",
          data.data.tokenAuth.user.lastName
        ); */
        // setAuthUser(data.tokenAuth.payload);
        localStorage.setItem("token", data.data.tokenAuth.token);
        // setSuccess(true);
        // check if data.data.tokenAuth.user.account is null or not
        if (!data.data.tokenAuth.user.account) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            // company, business, account
            text: "ព័ត៌មានមិនគ្រប់គ្រាន់ទេ សូមបញ្ចូលព័ត៌មានក្រុមហ៊ុន អាជីវកម្ម និងគណនីរបស់អ្នក",
            confirmButtonText: "យល់ព្រម",
          });
          return;
        }
        const business = data.data.tokenAuth.user.account.business.edges;
        // loop business
        const businessData = [];
        business.forEach((business) => {
          businessData.push({
            id: business.node.id,
            business: business.node.business,
          });
        });
        localStorage.setItem("business", JSON.stringify(businessData));
        setUserInfor({
          username: "",
          password: "",
        });

        navigate("/");
        // setCallbackData(menuSidebarItem);
      }
    };
    // console.log(gql, variables);
    aim.jquery_free(gql, variables, callback);
  };
  return (
    <div className="container">
      <div className="modal-dialog">
        <div
          className="modal-content"
          style={{ boxShadow: "0 3px 9px rgba(0,0,0,.5)" }}
        >
          <form method="post" onSubmit={handlSubmit}>
            <div className="modal-header">
              <h1 className="text-primary">ចូលប្រើ</h1>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <input
                  type="text"
                  id="username"
                  name="username"
                  className="form-control"
                  placeholder="លេខទូរសព្ទ"
                  autoComplete="off"
                  value={userInfor.username}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder="ពាក្យសម្ងាត់"
                  value={userInfor.password}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            {/* <div className="form-group col-12">
              មិនទាន់មានគណនី?&nbsp;&nbsp;
              <Link to="/sign_up">បង្កើត​គណនី​ថ្មី</Link>
            </div> */}
            <div className="modal-footer">
              <input
                type="submit"
                value="ចូលប្រើ"
                className="btn btn-primary"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
