import Page404 from "../../pages/Page404";
import ProductsListAdd from "./ProductsListAdd";
import CreatePo from "./CreatePo";
import CreateQuotation from "./CreateQuotation";
import CreateInvoice from "./CreateInvoice";
import CreateDelivery from "./CreateDelivery";
import CreatePayment from "./CreatePayment";
import Print from "./Print";
import PrintPo from "./PrintPo";
import PrintQuotation from "./PrintQuotation";
import PrintInvoice from "./PrintInvoice";
import PrintDelivery from "./PrintDelivery";
import PrintPayment from "./PrintPayment";
import { useNavigate } from "react-router-dom";

// step 3
import * as aim from "../../js/function.easy7d";
import SubmitCheck from "../HandleFunction";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Post(props) {
  const action = props.searchParams.get("action");
  const navigate = useNavigate();
  const handleSubmit = (data) => {
    try {
      const $ = window.$;
      let products_list = $("#addDataForm").serializeArray();
      products_list = JSON.stringify(products_list);
      const dataProductsList = { inventory: products_list };

      const page = "postProduct";
      if (data.page !== "update") {
        data.data = `id: null`;
      } else {
        data.data = `id: "${data.data}"`;
      }
      const gql = `
        mutation MyMutation($inventory: GenericScalar = {}) {
          ${page}(data: {inventory: $inventory}, ${data.data}) {
            success
          }
        }
      `;
      const variables = dataProductsList;

      const callback = (data) => {
        if (data.errors) {
          withReactContent(Swal).fire({
            icon: "error",
            title: "មានបញ្ហា",
            html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
            confirmButtonText: "យល់ព្រម",
          });
        } else {
          withReactContent(Swal).fire({
            icon: "success",
            title: "ជោគជ័យ",
            showConfirmButton: false,
            timer: 3500,
          });
        }
      };
      // console.log(gql, variables);
      aim.jquery_free(gql, variables, callback);
    } catch (error) {
      console.error("error:", error);
      navigate("/products_list");
    }
  };

  const handleSubmit2 = (data) => {
    try {
      const getData = SubmitCheck(data);
      const d = getData.dataForm;
      const page = "createProcess";

      console.log(getData);

      let gql = "";
      if (data.page === "po") {
        gql = `mutation {
          ${page}(
            page: "${data.page}"
            productsListId: "${d.products_list_id}"
            poNumberCustomer: "${d.po_number_customer}"
            dateRequests: "${d.date_requests}"
            requests: "${d.requests}"
            dateNeed: "${d.date_need}"
          ) {
            success
          }
        }`;
      } else if (data.page === "quotation") {
        gql = `mutation {
          ${page}(
            page: "${data.page}"
            productsListId: "${d.products_list_id}"
            dateNeed: "${d.date_need}"
          ) {
            success
          }
        }`;
      } else if (data.page === "invoice") {
        gql = `mutation {
          ${page}(
            page: "${data.page}"
            productsListId: "${d.products_list_id}"
            dateNeed: "${d.date_need}"
            deposit: "${d.deposit}"
          ) {
            success
          }
        }`;
      } else if (data.page === "delivery") {
        gql = `mutation {
          ${page}(
            page: "${data.page}"
            productsListId: "${d.products_list_id}"
            dateNeed: "${d.date_need}"
          ) {
            success
          }
        }`;
      }

      if (data.page === "payment") {
        if (d.balance_final !== "0") {
          withReactContent(Swal).fire({
            icon: "warning",
            title: "មានបញ្ហា",
            html: `សូមធ្វើការទូទាត់ប្រាក់ឡើងវិញ អោយបានត្រឹមត្រូវ`,
            confirmButtonText: "យល់ព្រម",
          });
          return;
        }
        const payment_types = JSON.stringify(d["payment_types[]"]);
        const payment_types_in = JSON.stringify(d["payment_types_in[]"]);
        const debit = JSON.stringify(d["debit[]"]);
        const rate = JSON.stringify(d["rate[]"]);
        /* ${page}(
          page: "${data.page}"
          productsListId: "${d.products_list_id}"
          dateNeed: "${d.date_need}"
        ) {
          success
        } */
        gql = `mutation {
          postJournalMultiple(
            business: "${d.business}"
            title: "តារាងរួម"
            paymentTypes: ${payment_types}
            paymentTypesIn: ${payment_types_in}
            paymentPaidLoad: ${debit}
            rate: ${rate}
          ) {
            success
            JournalId
          }
        }`;
        const callback = (v) => {
          console.log(v);
          if (v.errors) {
            console.error("error:", v.errors);
          } else {
            gql = `mutation {
              ${page}(
                page: "${data.page}"
                productsListId: "${d.products_list_id}"
                dateNeed: "${d.date_need}"
                journalId: "${v.data.postJournalMultiple.JournalId}"
              ) {
                success
              }
            }`;
            const callback2 = (data) => {
              if (data.errors) {
                console.error("error:", data.errors);
              } else {
                withReactContent(Swal).fire({
                  icon: "success",
                  title: "ជោគជ័យ",
                  showConfirmButton: false,
                  timer: 3500,
                });
                // navigate("/products_list");
              }
            };
            console.log(gql);
            aim.jquery_free(gql, {}, callback2);
          }
        };
        console.log(gql);
        aim.jquery_free(gql, {}, callback);
      } else {
        const callback = (v) => {
          console.log(v);
          if (v.errors) {
            console.error("error:", v.errors);
          } else {
            withReactContent(Swal).fire({
              icon: "success",
              title: "ជោគជ័យ",
              showConfirmButton: false,
              timer: 3500,
            });
          }
        };
        console.log(gql);
        aim.jquery_free(gql, {}, callback);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (action === "add") {
    return <ProductsListAdd props={{ handleSubmit }} />;
  } else if (action === "create_po") {
    return <CreatePo props={{ handleSubmit2 }} />;
  } else if (action === "create_quotation") {
    return <CreateQuotation props={{ handleSubmit2 }} />;
  } else if (action === "create_invoice") {
    return <CreateInvoice props={{ handleSubmit2 }} />;
  } else if (action === "create_delivery_note") {
    return <CreateDelivery props={{ handleSubmit2 }} />;
  } else if (action === "create_payment") {
    return <CreatePayment props={{ handleSubmit2 }} />;
  } else if (
    action === "print" &&
    props.searchParams.get("status") === "products_list"
  ) {
    return <Print props={{ ...props }} />;
  } else if (action === "print" && props.searchParams.get("status") === "po") {
    return <PrintPo props={{ ...props }} />;
  } else if (
    action === "print" &&
    props.searchParams.get("status") === "quotation"
  ) {
    return <PrintQuotation props={{ ...props }} />;
  } else if (
    action === "print" &&
    props.searchParams.get("status") === "invoice"
  ) {
    return <PrintInvoice props={{ ...props }} />;
  } else if (
    action === "print" &&
    props.searchParams.get("status") === "delivery_note"
  ) {
    return <PrintDelivery props={{ ...props }} />;
  } else if (
    action === "print" &&
    props.searchParams.get("status") === "payment"
  ) {
    return <PrintPayment props={{ ...props }} />;
  } else {
    return <Page404 />;
  }
}
