import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Post(props) {
  const handleSubmit = (e) => {
    e.preventDefault();
    props.props.handleSubmit2({ page: "payment", data: null });
  };
  const navigate = useNavigate();
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const businessAll = aim.useGetBusiness();
      if (businessAll) {
        const businessSelect = document.getElementById("business");
        businessSelect.innerHTML = "";
        // add option to select
        businessSelect.innerHTML = `<option value="">ជ្រើសរើស</option>`;
        businessAll.forEach((element) => {
          // const option = document.createElement("option");
          // option.value = element.node.id;
          // option.text = element.node.name;
          element.node.businessSet.edges.forEach((v) => {
            const option = document.createElement("option");
            option.value = v.node.id;
            option.text = v.node.business + " - " + element.node.name;
            businessSelect.appendChild(option);
          });
        });
      }
      const $ = window.$;
      $("#date_need")
        .datepicker({ dateFormat: "dd-mm-yy" })
        .datepicker("setDate", new Date());

      $("#code_products_list").keyup(function () {
        // console.log("code_products_list", $(this).val());
        const item = $(this).val();
        const gql = `query SearchProductList {
            allProcess(
              quotationStatus: 1
              invoiceStatus: 1
              deliveryStatus: 1
              paymentStatus: 0
              productsInfo_Invoice_Icontains: "${item}", 
              productsInfo_InvoiceManual_Icontains: "${item}"
            ) {
              edges {
                node {
                  id
                  productsInfo {
                    id
                    invoice
                    invoiceManual
                    company
                    customer
                    customerPhone
                  }
                }
              }
            }
          }`;
        $("#code_products_list").devbridgeAutocomplete({
          serviceUrl: `https://landscape.aimsarik.com/gql/`,
          type: "POST",
          ajaxSettings: {
            headers: {
              // jwt token
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
            contentType: "application/json",
            data: JSON.stringify({ query: gql }),
          },
          transformResult: function (response) {
            // convert response to json object
            response = JSON.parse(response);
            console.log(response);
            return {
              suggestions: response.data.allProcess.edges.map((item) => {
                return {
                  value: `${item.node.productsInfo.company} - ${item.node.productsInfo.customer} - ${item.node.productsInfo.customerPhone} - ${item.node.productsInfo.invoice} - ${item.node.productsInfo.invoiceManual}`,
                  data: item.node.productsInfo.id,
                };
              }),
            };
          },
          onSelect: function (suggestion) {
            // console.log("suggestion", suggestion);
            $("#products_list_id").val(suggestion.data);
            const gql = `query ProductsList($id: ID!) {
              productsInfo(id: $id) {
                id
                company
                customer
                customerPhone
                customerAddress
                productdetailSet {
                  edges {
                    node {
                      product {
                        name
                        uom
                      }
                      quantity
                      price
                      discount
                      total
                      afterDiscount
                    }
                  }
                  sumTotal
                  sumAfterDiscount
                }
                currency {
                  shortName
                  rate {
                    fixed
                  }
                  round
                }
              }
            }`;
            const variables = {
              id: suggestion.data,
            };
            const callback = (data) => {
              const d = data.data.productsInfo;
              // console.log("data:", data);

              if (data.errors) {
                console.error("error:", data.errors);
              } else {
                // console.log("data:", d);
                const cd = {
                  rate: {
                    fixed: d.currency.rate.fixed,
                  },
                };
                let counter = 1;
                let html = "";
                let discount_check = 0;
                d.productdetailSet.edges.forEach((v) => {
                  if (v.node.discount > 0) {
                    discount_check = 1;
                  }
                  // create html
                  html += `<tr>
                    <td>${counter++}</td>
                    <td>${v.node.product.name}</td>
                    <td>${v.node.product.uom}</td>
                    <td>${aim.addCommas(v.node.quantity)}</td>
                    <td>${aim.currencyFormat(
                      v.node.price,
                      d.currency.shortName,
                      2
                    )}</td>
                    <td>${v.node.discount}</td>
                    <td>${aim.currencyFormat(
                      v.node.afterDiscount,
                      d.currency.shortName,
                      2
                    )}</td>
                  </tr>`;
                });

                // show discount if have
                let show_discount = "";
                if (discount_check === 1) {
                  show_discount = `<div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">បញ្ចុះតម្លៃ</span>
                      </div>
                      <input class="form-control" type="text" readonly value="${aim.addCommas(
                        aim.currencyRound(
                          d.productdetailSet.sumTotal -
                            d.productdetailSet.sumAfterDiscount,
                          d.currency.round
                        )
                      )}" />
                      <div class="input-group-append">
                        <span class="input-group-text text-danger">${
                          d.currency.shortName
                        }</span>
                      </div>
                  </div>`;
                }
                // create summary
                // crate function load form paid
                const payment_paid_add_multi = () => {
                  var data = `
                    <div class="payment_row">
                        <div class="text-primary"><h3>ព័ត៌មានការទូទាត់ <span class="payment_count text-danger cursor-pointer"></span></h3></div>
                        <div class="row">
                          <div class="input-group mb-3 col-12">
                            <div class="input-group-prepend">
                                <span class="input-group-text">​គណនីចំណូល</span>
                            </div>
                            <input type="hidden" class="rate" name="rate[]" required>
                            <select name="payment_types[]" class="form-control payment_types select2bs4" required>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="input-group mb-3 col-12">
                            <div class="input-group-prepend">
                                <span class="input-group-text">​គណនីទទួល</span>
                            </div>
                            <select name="payment_types_in[]" class="form-control payment_types_in select2bs4" required>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                            <div class="input-group mb-3 col-12">
                              <div class="input-group-prepend">
                                  <span class="input-group-text">ចំនួន</span>
                              </div>
                              <input class="form-control debit number" name="debit[]" type="text" autocomplete="off" onClick="this.select();" value="0" required>
                              <div class="input-group-append">
                                  <span class="input-group-text text-danger currShort">កំពុងគណនា</span>
                              </div>
                            </div>
                        </div>
                    </div>`;
                  return data;
                };
                const loadFormPaid = () => {
                  var data = `<div class="row">
                      <div class="input-group mb-3 col-12">
                        <div class="input-group-prepend">
                            <span class="input-group-text">សាច់ប្រាក់ត្រូវទូទាត់</span>
                        </div>
                        <input class="form-control number" type="text" name="grand_total" id="grand_total" readonly value="${aim.addCommas(
                          aim.currencyRound(
                            d.productdetailSet.sumAfterDiscount,
                            d.currency.round
                          )
                        )}">
                        <div class="input-group-append">
                            <span class="input-group-text text-danger grand_total_currShort">${
                              d.currency.shortName
                            }</span>
                        </div>
                      </div>
                    </div>

                    <div id="payment_information"></div>
                    <div class="text-center mb-3"><span class="btn btn-primary" id="add_payment">បន្ថែមការទូទាត់</span></div>

                    <div class="row">
                      <div class="mb-3 col-12 text-center text-danger" style="display: none" id="check_balance">
                        តុល្យភាពមិនប្រក្រតីសូមត្រួតពិនិត្យឡើងវិញមុននឹងចុចរួចរាល់
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-group mb-3 col-12">
                          <div class="input-group-prepend">
                              <span class="input-group-text">តុល្យភាព</span>
                          </div>
                          <input class="form-control number" type="text" id="balance_final" name="balance_final" readonly value="${aim.addCommas(
                            aim.currencyRound(
                              d.productdetailSet.sumAfterDiscount,
                              d.currency.round
                            )
                          )}">
                          <div class="input-group-append">
                              <span class="input-group-text text-danger balance_final_currShort">${
                                d.currency.shortName
                              }</span>
                          </div>
                      </div>
                    </div>`;
                  return data;
                };
                // product list summary
                const products_list_summary = `
                  <div class="input-group">
                      <div class="input-group-prepend">
                          <span class="input-group-text">ទឹកប្រាក់សរុប</span>
                      </div>
                      <input class="form-control" type="text" readonly value="${aim.addCommas(
                        aim.currencyRound(
                          d.productdetailSet.sumTotal,
                          d.currency.round
                        )
                      )}">
                      <div class="input-group-append">
                        <span class="input-group-text text-danger">${
                          d.currency.shortName
                        }</span>
                      </div>
                  </div>
                  ${show_discount}
                  ${loadFormPaid()}
                `;

                $("#form_print_data").html(`
                  <div class="row mt-4">
                    <div id="no-more-tables" class="w-100">
                        <div class="card border-primary">
                            <h3 class="card-header bg-primary text-white text-center">
                                <span>តារាងតម្លៃ</span>
                            </h3> 

                            <div class="card-body mt-2 row">
                                <table class="table table-hover table-bordered" id="addCategory">
                                    <thead>
                                        <tr>
                                            <th>លរ</th>
                                            <th>ឈ្មោះផលិតផល</th>
                                            <th>ឯកតា</th>
                                            <th>បរិមាណ</th>
                                            <th>តម្លៃលក់</th>
                                            <th>បញ្ចុះតម្លៃ%</th>
                                            <th>តម្លៃសរុប</th>
                                        </tr>
                                    </thead>
                                    <tbody id="table_body">
                                        ${html}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-end" id="products_list_summary" style="display: grid">
                    ${products_list_summary}
                </div>`);

                // income
                function incomeFunc(currency = null) {
                  const handleError = (errors) => {
                    withReactContent(Swal).fire({
                      icon: "error",
                      title: "មានបញ្ហា",
                      html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${errors[0].message}`,
                      confirmButtonText: "យល់ព្រម",
                    });
                  };

                  const generateOption = (v) => `
                    <option 
                      value="${v.id}" 
                      class="${v.currency.shortName}" 
                      data-currency_id="${v.currency.id}" 
                      data-exchange_rate="${v.currency.rate.fixed}"
                      data-round="${v.currency.round}">
                      ${v.name}
                    </option>`;

                  const gql = `query MyQuery($category_In: [String] = null) {
                    allCoa(category_In: $category_In) {
                      edges {
                        node {
                          currency {
                            id
                            nameKh
                            shortName
                            rate {
                              fixed
                            }
                            round
                          }
                          type {
                            type
                          }
                          id
                          category
                          name
                          accountNo
                          finanPreparant {
                            id
                            name
                          }
                        }
                      }
                    }
                  }`;

                  const variables = {
                    category_In: ["ចំណូល", "ទ្រព្យ"],
                  };

                  return new Promise((resolve, reject) => {
                    const callback = (data) => {
                      if (data.errors) {
                        handleError(data.errors);
                        reject(data.errors);
                      } else {
                        let asset = "<option value=''></option>";
                        let income = "<option value=''></option>";
                        data.data.allCoa.edges.forEach((v) => {
                          if (v.node.category === "ទ្រព្យ") {
                            asset += generateOption(v.node);
                          } else if (v.node.category === "ចំណូល") {
                            income += generateOption(v.node);
                          }
                        });
                        resolve({ asset, income });
                      }
                    };

                    aim.jquery_free(gql, variables, callback);
                  });
                }
                // Function to filter currency id in payment_types_in
                const filterCurrencyInPaymentTypes = (index, currency_id) => {
                  const paymentInformation = $("#payment_information");
                  let options = paymentInformation
                    .find(".payment_types_in")
                    .eq(index)
                    .find("option");

                  options = options.filter(function () {
                    return $(this).attr("data-currency_id") === currency_id;
                  });

                  if (options.length === 0) {
                    paymentInformation
                      .find(".payment_types_in")
                      .eq(index)
                      .val("");

                    // Show warning message with SweetAlert
                    withReactContent(Swal).fire({
                      icon: "warning",
                      title: "មិនមានប្រភេទគណនីដូចគ្នាទេ!!!",
                    });
                  } else {
                    paymentInformation
                      .find(".payment_types_in")
                      .eq(index)
                      .html(options);
                  }

                  paymentInformation
                    .find(".payment_types_in")
                    .eq(index)
                    .select2()
                    .val("")
                    .trigger("change");
                };

                function check_paid_money() {
                  const grand_total = parseFloat(
                    $("#grand_total").val().replace(/,/g, "")
                  );
                  let total_paid = 0;
                  $(".debit").each(function () {
                    // get data-exchange_rate from payment_types
                    if ($(this).val() === "") {
                      $(this).val(0);
                    }
                    const rate = $(this)
                      .closest(".payment_row")
                      .find(".payment_types")
                      .find(":selected")
                      .attr("data-exchange_rate");
                    const exchange = aim.exchangeRate(0, rate, cd);
                    total_paid +=
                      parseFloat($(this).val().replace(/,/g, "")) * exchange;
                  });
                  const balance = grand_total - total_paid;
                  $("#balance_final").val(aim.addCommas(balance));
                  if (balance < 0) {
                    $("#check_balance").show();
                    $("#balance").css({
                      "background-color": "#dc3545!important",
                      color: "#fff!important",
                    });
                  } else if (balance > 0) {
                    $("#check_balance").show();
                    $("#balance").css({
                      "background-color": "#28a745!important",
                      color: "#fff!important",
                    });
                  } else {
                    $("#check_balance").hide();
                    $("#balance").css({
                      "background-color": "#e9ecef!important",
                      color: "#000!important",
                    });
                  }
                }

                $("#products_list_summary").on(
                  "click",
                  "#add_payment",
                  function () {
                    let payment_count = $(".payment_row").length;
                    // console.log("payment_count", payment_count);

                    incomeFunc()
                      .then(({ asset, income }) => {
                        $("#payment_information").append(
                          payment_paid_add_multi()
                        );
                        // console.log("asset:", asset, "income:", income);
                        $(".payment_types").eq(payment_count).html(income);
                        // $(".payment_types_in").eq(payment_count).html(asset);
                        // payment_types change
                        $("#payment_information").on(
                          "change",
                          ".payment_types",
                          function () {
                            // check this index
                            const index = $(this).index(".payment_types");
                            // console.log(index);
                            const currShort = $(this)
                              .find(":selected")
                              .attr("class");
                            const currency_id = $(this)
                              .find(":selected")
                              .attr("data-currency_id");
                            // console.log(currShort, currency_id);
                            if (
                              typeof currShort !== "undefined" &&
                              currShort !== false
                            ) {
                              $(".currShort").eq(index).text(currShort);
                              // $('#currency_id').val(currency_id);
                            }
                            // add rate in to rate value
                            const rate = $(this)
                              .find(":selected")
                              .attr("data-exchange_rate");
                            // console.log("rate", rate);
                            $(".rate").eq(index).val(rate);

                            // add asset in payment_types_in
                            $(".payment_types_in").eq(index).html(asset);

                            // filter currency id in payment_types_in
                            filterCurrencyInPaymentTypes(index, currency_id);
                            check_paid_money();
                          }
                        );
                        $(".select2bs4").select2({
                          theme: "bootstrap4",
                        });
                      })
                      .catch((error) => {
                        console.error("Error:", error);
                      });
                  }
                );
                $("#add_payment").click();
                $("#payment_information").on("keyup", ".debit", function () {
                  check_paid_money();
                });

                $("#submit").show();
              }
            };
            aim.jquery_free(gql, variables, callback);
          },
        });
        $(this).focus();
      });
    }
  });
  return (
    <div className="content-wrapper">
      <div className="container-fluid" id="report">
        <div className="page-loading">
          <div className="card card-primary">
            <div className="card-header">
              <h2 className="card-title">បញ្ជាទិញទំនិញ</h2>
            </div>
            <form
              name="addDataForm"
              id="addDataForm"
              method="post"
              className="addDataForm form col-12 p-0 center-block mt-4"
              onSubmit={handleSubmit}
            >
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">កូដតារាងរួម</span>
                      </div>
                      <input
                        type="hidden"
                        name="products_list_id"
                        id="products_list_id"
                        className="form-control"
                      />
                      <input
                        type="text"
                        name="code_products_list"
                        id="code_products_list"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <span className="input-group-text">កាលបរិច្ឆេទ</span>
                      </div>
                      <input
                        type="text"
                        name="date_need"
                        id="date_need"
                        className="form-control"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="input-group mb-2 col-12">
                    <div className="input-group-prepend">
                      <span className="input-group-text">អាជីវកម្ម</span>
                    </div>
                    <select
                      id="business"
                      name="business"
                      className="form-control"
                      required
                    ></select>
                  </div>
                </div>
              </div>
              <div id="form_print_data" className="col-12" />
              <div className="card-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  id="submit"
                  style={{ display: "none" }}
                >
                  រួចរាល់
                </button>
                <button
                  type="button"
                  className="btn btn-default float-right cancel"
                  onClick={() => navigate("/products_list")}
                >
                  បោះបង់
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
