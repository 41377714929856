import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../appState/AuthProvider";
import { Link } from "react-router-dom";
import * as aim from "../js/function.easy7d";

export default function Sidebar(props) {
  const $ = window.$;
  const { user } = useContext(AuthContext);
  const [sideBarData, setSideBarData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      // wait for user data to load from AuthProvider
      if (user.business) {
        const gql = `allCompanies {
          edges {
            node {
              name
              phone
              logo
              address
              branch
            }
          }
        }
        allMenuSidebar(orderBy: "rank") {
          edges {
            node {
              name
              nameKh
              rank
              icon
              url
              business {
                edges {
                  node {
                    id
                    business
                  }
                }
              }
              menusidebaritemSet(orderBy: "rank") {
                edges {
                  node {
                    name
                    nameKh
                    rank
                    icon
                    url
                    business {
                      edges {
                        node {
                          id
                          business
                        }
                      }
                    }
                    menusidebaritemsubSet(orderBy: "rank") {
                      edges {
                        node {
                          name
                          nameKh
                          rank
                          icon
                          url
                          business {
                            edges {
                              node {
                                id
                                business
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`;
        const callback = (data) => {
          let company = data.data.allCompanies.edges[0].node;
          company.logo = `https://landscape.aimsarik.com/media/${company.logo}`;
          setCompanyData(company);
          const menuSidebar = data.data.allMenuSidebar.edges;
          const menuSidebarItem = [];
          const businessCheck = (businessItem) => {
            let check = false;
            businessItem.forEach((item) => {
              if (user.business.find((x) => x.id === item.node.id)) {
                check = true;
              }
            });
            return check;
          };
          menuSidebar.forEach((menuSidebar) => {
            const name = menuSidebar.node.nameKh;
            const icon = menuSidebar.node.icon;
            const url = menuSidebar.node.url;
            const itemCount = menuSidebar.node.menusidebaritemSet.edges.length;
            const businessItem = menuSidebar.node.business.edges;

            if (itemCount > 0) {
              const menuSidebarItemData = [];
              // foreach menu sidebar item
              menuSidebar.node.menusidebaritemSet.edges.forEach(
                (menuSidebarItem) => {
                  const name = menuSidebarItem.node.nameKh;
                  const icon = menuSidebarItem.node.icon;
                  const url = menuSidebarItem.node.url;
                  const subItemCount =
                    menuSidebarItem.node.menusidebaritemsubSet.edges.length;
                  const businessItem = menuSidebarItem.node.business.edges;
                  if (subItemCount > 0) {
                    const menuSidebarItemSubData = [];
                    // foreach menu sidebar item sub
                    menuSidebarItem.node.menusidebaritemsubSet.edges.forEach(
                      (menuSidebarItemSub) => {
                        const name = menuSidebarItemSub.node.nameKh;
                        const icon = menuSidebarItemSub.node.icon;
                        const url = menuSidebarItemSub.node.url;
                        const businessItem =
                          menuSidebarItemSub.node.business.edges;
                        const meneItemSub = (
                          <li className="nav-item" key={name}>
                            <Link
                              to={url}
                              className="nav-link menu_link sub_item pl-5"
                            >
                              <i className={icon} />
                              <p>{name}</p>
                            </Link>
                          </li>
                        );
                        if (businessItem.length > 0) {
                          if (businessCheck(businessItem)) {
                            menuSidebarItemSubData.push(meneItemSub);
                          }
                        } else {
                          menuSidebarItemSubData.push(meneItemSub);
                        }
                      }
                    );
                    // menu sidebar item
                    menuSidebarItemData.push(
                      <li className="nav-item has-treeview" key={name}>
                        <Link to={url} className="nav-link sub_main_link">
                          <i className={icon}></i>
                          <p>
                            {name}
                            <i className="right fas fa-angle-left"></i>
                          </p>
                        </Link>
                        <ul className="nav nav-treeview">
                          {menuSidebarItemSubData}
                        </ul>
                      </li>
                    );
                  } else {
                    const menuItem = (
                      <li className="nav-item" key={name}>
                        <Link to={url} className="nav-link menu_link">
                          <i className={icon} />
                          <p>{name}</p>
                        </Link>
                      </li>
                    );

                    if (businessItem.length > 0) {
                      if (businessCheck(businessItem)) {
                        menuSidebarItemData.push(menuItem);
                      }
                    } else {
                      menuSidebarItemData.push(menuItem);
                    }
                  }
                }
              );
              // menu main
              const menuMain = (
                <li className="nav-item has-treeview" key={name}>
                  <Link to={url} className="nav-link main_link">
                    <i className={icon}></i>
                    <p>
                      {name}
                      <i className="right fas fa-angle-left"></i>
                    </p>
                  </Link>
                  <ul className="nav nav-treeview">{menuSidebarItemData}</ul>
                </li>
              );
              menuSidebarItem.push(menuMain);
            } else {
              const menuMain = (
                <li className="nav-item" key={name}>
                  <Link to={url} className="nav-link menu_link">
                    <i className={icon} />
                    <p>{name}</p>
                  </Link>
                </li>
              );
              if (businessItem.length > 0) {
                if (businessCheck(businessItem)) {
                  menuSidebarItem.push(menuMain);
                }
              } else {
                menuSidebarItem.push(menuMain);
              }
            }
          });
          setSideBarData(menuSidebarItem);
          // check menu sidebar is true or false
          if (
            typeof navigator === "object" &&
            typeof navigator.userAgent === "string" &&
            navigator.userAgent.indexOf("Electron") >= 0
          ) {
            $('[data-widget="treeview"]').Treeview("init");
          } else {
            const meneBar = localStorage.getItem("menuSidebar") || false;
            if (meneBar !== "true") {
              localStorage.setItem("menuSidebar", true);
              // reload page
              window.location.reload();
            }
          }
          // $('[data-widget="treeview"]').Treeview("init");
          // check if Treeview is open
          /* if ($('[data-widget="treeview"]').hasClass("menu-open")) {
            $('[data-widget="treeview"]').removeClass("menu-open");
          } else {
            $(this).addClass("menu-open");
          } */
        };
        aim.jquery_query(gql, callback);
      }
    }
  });
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <a href="index3.html" className="brand-link text-center">
        <img
          // src={`https://landscape.aimsarik.com/media/${companyData.logo}`}
          src={companyData.logo}
          alt="Logo"
          className="brand-image elevation-3 float-none mb-3"
        />
        <br />
        <span className="brand-text font-weight-light">{companyData.name}</span>
      </a>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="/dist/img/avatar5.png"
              className="img-circle elevation-2"
              alt="User"
            />
          </div>
          <div className="info">
            <span className="d-block text-white">{user.user.username}</span>
          </div>
        </div>
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {sideBarData}
            <li className="nav-item">
              <Link
                to="/sign_in"
                className="nav-link menu_link"
                onClick={props.signOut}
                style={{ cursor: "pointer" }}
              >
                <p>
                  <i className="nav-icon fa fa-sign-out"></i>
                  ចាកចេញ
                </p>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}
