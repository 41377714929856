import React, { useEffect, useRef, useState, useCallback } from "react";
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Print(props) {
  const id = props.props.searchParams.get("id");
  const status = props.props.searchParams.get("status");

  const initialized = useRef(false);
  // const [companyData, setCompanyData] = useState([]);
  const [productsListData, setProductsListData] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState({
    name: true,
    code: true,
    uom: true,
    quantity: true,
    price: true,
    discount: true,
    afterDiscount: true,
  });

  const fetchData = useCallback(() => {
    const gql = `query ProductInfo {
      allCompanies {
        edges {
          node {
            name
            phone
            logo
            address
            branch
          }
        }
      }
      productsInfo(id: "${id}") {
        id
        company
        customer
        customerPhone
        customerAddress
        date
        invoice
        invoiceManual
        sale
        salePhone
        productdetailSet {
          edges {
            node {
              product {
                name
                code
                uom
              }
              quantity
              price
              discount
              afterDiscount
            }
          }
          sumTotal
          sumAfterDiscount
        }
        currency {
          shortName
          round
        }
      }
    }`;

    const callback = (data) => {
      console.log(data);
      const d = data.data.productsInfo;
      if (data.errors) {
        withReactContent(Swal).fire({
          icon: "error",
          title: "មានបញ្ហា",
          html: `កំហុសក្នុសមានបញ្ហា សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
          confirmButtonText: "យល់ព្រម",
        });
      } else {
        let company = data.data.allCompanies.edges[0].node;
        company.logo = `https://landscape.aimsarik.com/media/${company.logo}`;
        if (status === "products_list") {
          // setCompanyData(company);
          const products_list_information = (
            <div className="row mt-3">
              <div
                className="col-7 text-left"
                style={{ border: "1px solid", padding: 12, borderRadius: 12 }}
              >
                <div className="float-left">
                  <strong>ក្រុមហ៊ុន</strong>
                  <br />
                  <strong>ឈ្មោះ​អតិថិជន</strong>
                  <br />
                  <strong>លេខទូរស័ព្ទ</strong>
                  <br />
                  <strong>អាសយដ្ឋាន</strong>
                  <br />
                </div>
                <div className="float-left">
                  &nbsp;:&nbsp; {d.company}
                  <br />
                  &nbsp;:&nbsp; {d.customer}
                  <br />
                  &nbsp;:&nbsp; {d.customerPhone}
                  <br />
                  &nbsp;:&nbsp; {d.customerAddress}
                  <br />
                </div>
              </div>
              <div className="col-1" />
              <div
                className="col-4 text-left"
                style={{ border: "1px solid", padding: 12, borderRadius: 12 }}
              >
                <div className="float-right">
                  &nbsp;:&nbsp; {d.date}
                  <br />
                  &nbsp;:&nbsp; {d.sale}
                  <br />
                  &nbsp;:&nbsp; {d.salePhone}
                  <br />
                </div>
                <div className="float-right">
                  <strong>កាលបរិច្ឆេទ</strong>
                  <br />
                  <strong>អ្នកលក់</strong>
                  <br />
                  <strong>លេខទូរស័ព្ទ</strong>
                  <br />
                </div>
              </div>
            </div>
          );
          const title = "តារាងរួម";
          let tableBody = [];
          let number = 1;
          d.productdetailSet.edges.forEach((v) => {
            const row = (
              <tr key={`${number}-${v.node.product.code}`}>
                <td>{number++}</td>
                {columnVisibility.name && <td>{v.node.product.name}</td>}
                {columnVisibility.code && <td>{v.node.product.code}</td>}
                {columnVisibility.uom && <td>{v.node.product.uom}</td>}
                {columnVisibility.quantity && (
                  <td>{aim.addCommas(v.node.quantity)}</td>
                )}
                {columnVisibility.price && (
                  <td>
                    {aim.currencyFormat(
                      aim.currencyRound(v.node.price, d.currency.round),
                      d.currency.shortName
                    )}
                  </td>
                )}
                {columnVisibility.discount && (
                  <td>{aim.multiple_digits(v.node.discount, 0)}</td>
                )}
                {columnVisibility.afterDiscount && (
                  <td>
                    {aim.currencyFormat(
                      aim.currencyRound(v.node.afterDiscount, d.currency.round),
                      d.currency.shortName
                    )}
                  </td>
                )}
              </tr>
            );
            // push row to tableBody
            tableBody.push(row);
          });

          const visibleColumnsCount =
            Object.values(columnVisibility).filter(Boolean).length + 1;

          let discountCheck = [
            <tr key="sumDiscount">
              <td colSpan={visibleColumnsCount - 1} className="text-right">
                បញ្ចុះតម្លៃ
              </td>
              <td>
                {aim.currencyFormat(
                  aim.currencyRound(
                    d.productdetailSet.sumTotal -
                      d.productdetailSet.sumAfterDiscount,
                    d.currency.round
                  ),
                  d.currency.shortName
                )}
              </td>
            </tr>,
            <tr key="sumAfterDiscount">
              <td colSpan={visibleColumnsCount - 1} className="text-right">
                នៅសល់
              </td>
              <td>
                {aim.currencyFormat(
                  aim.currencyRound(
                    d.productdetailSet.sumAfterDiscount,
                    d.currency.round
                  ),
                  d.currency.shortName
                )}
              </td>
            </tr>,
          ];
          let products_list_summary = [
            <tr key="sumTotal">
              <td colSpan={visibleColumnsCount - 1} className="text-right">
                ទឹកប្រាក់សរុប
              </td>
              <td>
                {aim.currencyFormat(
                  aim.currencyRound(
                    d.productdetailSet.sumTotal,
                    d.currency.round
                  ),
                  d.currency.shortName
                )}
              </td>
            </tr>,
          ];
          if (
            d.productdetailSet.sumTotal !== d.productdetailSet.sumAfterDiscount
          ) {
            products_list_summary = products_list_summary.concat(discountCheck);
          }

          const printData = (
            <div className="content-wrapper">
              <div className="container-fluid">
                <div className="page-loading">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h2 className="card-title">ព្រីនតារាងរួម</h2>
                    </div>
                    <div className="print_data">
                      <div className="border-bottom p-2">
                        <div className="row">
                          <div className="col-auto mr-auto">
                            <img
                              alt="logo"
                              id="company_logo"
                              height="100"
                              src={company.logo}
                            />
                          </div>
                          <div className="col-auto">
                            <h2 className="text-center company_name mt-3 text-secondary moul">
                              {company.name}
                            </h2>
                            <h4 className=" text-secondary company_slogans">
                              ផ្គត់ផ្គង់សំភារៈសំណង់
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        {products_list_information}
                        <div className="column-visibility-controls mt-2 hidePrint">
                          <label className="mr-2">
                            <input
                              type="checkbox"
                              checked={columnVisibility.name}
                              onChange={() =>
                                setColumnVisibility((prev) => ({
                                  ...prev,
                                  name: !prev.name,
                                }))
                              }
                            />{" "}
                            ឈ្មោះផលិតផល
                          </label>
                          <label className="mr-2">
                            <input
                              type="checkbox"
                              checked={columnVisibility.code}
                              onChange={() =>
                                setColumnVisibility((prev) => ({
                                  ...prev,
                                  code: !prev.code,
                                }))
                              }
                            />{" "}
                            លេខកូដ
                          </label>
                          <label className="mr-2">
                            <input
                              type="checkbox"
                              checked={columnVisibility.uom}
                              onChange={() =>
                                setColumnVisibility((prev) => ({
                                  ...prev,
                                  uom: !prev.uom,
                                }))
                              }
                            />{" "}
                            ឯកតា
                          </label>
                          <label className="mr-2">
                            <input
                              type="checkbox"
                              checked={columnVisibility.quantity}
                              onChange={() =>
                                setColumnVisibility((prev) => ({
                                  ...prev,
                                  quantity: !prev.quantity,
                                }))
                              }
                            />{" "}
                            បរិមាណ
                          </label>
                          <label className="mr-2">
                            <input
                              type="checkbox"
                              checked={columnVisibility.price}
                              onChange={() =>
                                setColumnVisibility((prev) => ({
                                  ...prev,
                                  price: !prev.price,
                                }))
                              }
                            />{" "}
                            តម្លៃលក់
                          </label>
                          <label className="mr-2">
                            <input
                              type="checkbox"
                              checked={columnVisibility.discount}
                              onChange={() =>
                                setColumnVisibility((prev) => ({
                                  ...prev,
                                  discount: !prev.discount,
                                }))
                              }
                            />{" "}
                            បញ្ចុះតម្លៃ%
                          </label>
                          <label className="mr-2">
                            <input
                              type="checkbox"
                              checked={columnVisibility.afterDiscount}
                              onChange={() =>
                                setColumnVisibility((prev) => ({
                                  ...prev,
                                  afterDiscount: !prev.afterDiscount,
                                }))
                              }
                            />{" "}
                            តម្លៃសរុប
                          </label>
                        </div>
                        <div className="row mt-2">
                          <div id="no-more-tables" className="w-100">
                            <div className="card border-primary">
                              <h3 className="card-header bg-primary text-white text-center">
                                <span>{title}</span>
                              </h3>
                              <div className="card-body mt-2 row">
                                <table
                                  className="table table-hover table-bordered"
                                  id="addCategory"
                                >
                                  <thead>
                                    <tr>
                                      <th>លរ</th>
                                      {columnVisibility.name && (
                                        <th>ឈ្មោះផលិតផល</th>
                                      )}
                                      {columnVisibility.code && <th>លេខកូដ</th>}
                                      {columnVisibility.uom && <th>ឯកតា</th>}
                                      {columnVisibility.quantity && (
                                        <th>បរិមាណ</th>
                                      )}
                                      {columnVisibility.price && (
                                        <th>តម្លៃលក់</th>
                                      )}
                                      {columnVisibility.discount && (
                                        <th>បញ្ចុះតម្លៃ%</th>
                                      )}
                                      {columnVisibility.afterDiscount && (
                                        <th>តម្លៃសរុប</th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody id="table_body">{tableBody}</tbody>
                                  <tbody id="products_list_summary">
                                    {products_list_summary}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="button" className="btn btn-primary print">
                        ព្រីន
                      </button>
                      <button
                        type="button"
                        className="btn btn-default float-right cancel"
                      >
                        បោះបង់
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
          setProductsListData(printData);
        }
      }
    };

    aim.jquery_free(gql, {}, callback);
  }, [id, columnVisibility, status]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      fetchData();
    }
  }, [id, fetchData]);

  useEffect(() => {
    if (initialized.current) {
      fetchData();
    }
  }, [columnVisibility, fetchData]);

  useEffect(() => {
    const printButton = document.querySelector(".print");
    const handlePrint = (e) => {
      e.preventDefault();
      const $ = window.$;
      $(".print").parents(".page-loading").find(".print_data").print();
    };

    if (printButton) {
      printButton.addEventListener("click", handlePrint);
    }

    return () => {
      if (printButton) {
        printButton.removeEventListener("click", handlePrint);
      }
    };
  }, [productsListData]);

  return <div>{productsListData}</div>;
}
