import { useEffect, useRef } from "react";
import * as aim from "../../js/function.easy7d";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

export function groupSaleOrder(d) {
  let list = [];
  const food = d.filter((v) => v.node.type !== "stock");
  // sort by name
  food.sort((a, b) =>
    a.node.saleInformation.name.localeCompare(b.node.saleInformation.name)
  );
  // food
  // group by saleInformation.name and date
  const groupFood = food.reduce((r, a) => {
    const dateTime = new Date(a.node.date.created);
    const date = aim.dateFormat.date_kh(dateTime);
    const key = a.node.saleInformation.name + ": " + date;
    r[key] = r[key] || [];
    r[key].push(a);
    return r;
  }, Object.create(null));
  // console.log("food:", food);
  // console.log("groupFood:", groupFood);
  // sum quantity and total from group
  const sumGroupFood = Object.keys(groupFood).map((v) => {
    let quantity = 0;
    groupFood[v].forEach((x) => {
      quantity += x.node.quantity;
    });
    return { quantity, group: groupFood[v] };
  });
  // console.log("sumGroupFood:", sumGroupFood);

  // export to list
  sumGroupFood.forEach((x) => {
    const d = {
      id: x.group[0].node.id,
      name: x.group[0].node.saleInformation.name,
      quantity: x.quantity,
      type: x.group[0].node.type,
      date: x.group[0].node.date.created,
    };
    list.push(d);
  });
  // console.log("list:", list);

  // stock
  const stock = d.filter((v) => v.node.type === "stock");
  stock.sort((a, b) => a.node.stock.name.localeCompare(b.node.stock.name));

  const groupStock = stock.reduce((r, a) => {
    const dateTime = new Date(a.node.date.created);
    const date = aim.dateFormat.date_kh(dateTime);
    const key = a.node.stock.name + ": " + date;
    r[key] = r[key] || [];
    r[key].push(a);
    return r;
  }, Object.create(null));

  const sumGroupStock = Object.keys(groupStock).map((v) => {
    let quantity = 0;
    groupStock[v].forEach((x) => {
      quantity += x.node.quantity;
    });
    return { quantity, group: groupStock[v] };
  });

  sumGroupStock.forEach((x) => {
    const d = {
      id: x.group[0].node.id,
      name: x.group[0].node.stock.name,
      quantity: x.quantity,
      type: x.group[0].node.type,
      date: x.group[0].node.date.created,
    };
    list.push(d);
  });
  // console.log("list:", list);
  return list;
}

export default function CloseReport() {
  const navigate = useNavigate();
  const initialized = useRef(false);
  const page_name = "sale_income_close";
  const tbId = `tb_${page_name}`;
  const page_name2 = "sale_check";
  const tbId2 = `tb_${page_name2}`;
  const formOption = [
    <div
      className="btn-group pull-right mx-1 saleCloseHide"
      key={"print"}
      id="printData"
    >
      <button
        type="button"
        className="btn btn-primary dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        ព្រីនរបាយការណ៍
      </button>
      <div className="dropdown-menu dropdown-menu-right">
        <div className="dropdown-item-action">
          <span
            className="w-100 btn btn-primary"
            data-print="tb_sale_income_close"
          >
            <i
              className="fa fa-print printContent"
              data-print="tb_sale_income_close"
              aria-hidden="true"
            >
              ចំណូលការលក់
            </i>
          </span>
        </div>
        <div className="dropdown-item-action">
          <span className="w-100 btn btn-primary">
            <i
              className="fa fa-print printContent"
              aria-hidden="true"
              data-print="tb_sale_check"
            >
              របាយការណ៍លក់
            </i>
          </span>
        </div>
      </div>
    </div>,
    <span
      className="btn btn-success pull-right mx-1 saleCloseHide"
      key={"CloseSaleList"}
      id="close_sale"
    >
      បិទបញ្ជី
    </span>,
  ];
  const thead = aim.datatableHeadFoot(
    [
      "លរ",
      "ព័ត៌មាន",
      "សរុប",
      "បញ្ចុះតម្លៃ",
      "នៅសល់",
      "លុយអាប់",
      "តុល្យភាព",
      "លុយទទួលបាន",
      "ឈ្មោះគណនី",
    ],
    1
  );
  const thead2 = aim.datatableHeadFoot(
    ["លរ", "ឈ្មោះ", "បរិមាណ", "ប្រភេទ", "កាលបរិច្ឆេទ"],
    null
  );
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const sumAmount = [2, 3, 4, 5, 6, 7];
      const sumAmount2 = null;
      const curr = aim.useGetCurrency();
      aim.loadDataCloseReport(
        {
          currency: curr,
          tbId: tbId,
          page_name: page_name,
          load_page: "allCreateInvoice",
          gql: `{
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            totalCount
            edgeCount
            edges {
              cursor
              node {
                id
                invoice
                customer {
                  id
                  name
                  discount
                  phone {
                    number
                  }
                }
                discountAll
                total
                discount
                grandTotal
                balance
                changepriceSet {
                  id
                  change
                  coaId {
                    id
                    name
                    currency {
                      id
                      nameKh
                      shortName
                    }
                  }
                }
                paidSet {
                  id
                  paid
                  coaId {
                    id
                    name
                    type {
                      type
                    }
                    currency {
                      id
                      nameKh
                      shortName
                    }
                  }
                }
                table {
                  id
                  name
                  number
                }
                Currency {
                  id
                  nameKh
                  shortName
                  round
                  rate {
                    fixed
                  }
                }
                status
                closeTransaction
                saleorderSet {
                  edges {
                    node {
                      CreateInvoice {
                        invoice
                      }
                    }
                  }
                }
              }
            }
          }`,
        },
        {
          page_name: page_name,
          function_load: `${page_name}_datatable`,
          sumAmount: sumAmount,
          order_false: [1, 3, 4],
          width_column: {
            1: "100px",
          },
          columnHidden: [],
          orderColumn: ["id", "", "", "", "", ""],
          searching: ["invoice", "Currency"],
          otherOption: {
            status: 1,
            closeTransaction: 0,
          },
          // function
          data_execute: (result, load_page) => {
            // console.log(result.data[load_page].edges);
            if (result.data[load_page].edges.length === 0) {
              $(".saleCloseHide").hide();
              withReactContent(Swal).fire({
                icon: "error",
                title: "មិនមានទិន្នន័យទេ",
                html: "សូមធ្វើការបញ្ចូលទិន្នន័យជាមុនឡើងវិញ",
                confirmButtonText: "យល់ព្រម",
              });
            }
            // step 7
            const data_tb_row = [];
            result.recordsTotal = result.data[load_page].totalCount;
            result.recordsFiltered = result.data[load_page].totalCount;
            // console.log(result.recordsTotal);
            // console.log("result:", result.data[load_page].edges);
            // console.log("result:", result, "load_page:", load_page);
            // forEach
            let number = 1;
            result.data[load_page].edges.forEach((v) => {
              let balance = "";
              if (v.node.balance) {
                balance = `${aim.currencyFormat(
                  v.node.balance,
                  v.node.Currency.shortName,
                  2
                )}`;
              }
              let changePrice = "";
              if (v.node.changepriceSet.length > 0) {
                v.node.changepriceSet.forEach((x) => {
                  if (x.change !== 0) {
                    changePrice += `${aim.currencyFormat(
                      x.change,
                      x.coaId.currency.shortName,
                      2
                    )}`;
                  }
                });
              }

              let multiplePaid = "";
              let multiplePaidType = "";
              if (v.node.paidSet.length > 0) {
                v.node.paidSet.forEach((x) => {
                  if (multiplePaid !== "") {
                    multiplePaid += "<br>";
                    multiplePaidType += "<br>";
                  }
                  // multiplePaid += aim.multiple_digits(x.paid, 2);
                  // multiplePaid += x.paid;
                  multiplePaid += `${aim.currencyFormat(
                    x.paid,
                    x.coaId.currency.shortName,
                    2
                  )}`;
                  multiplePaidType += `${x.coaId.name}`;
                });
              }
              let customer = "";
              if (v.node.customer !== null) {
                customer = `${v.node.customer.name} (${v.node.customer.phone[0].number})`;
              } else {
                customer = "ទូទៅ";
              }

              var tb_row = [
                `${number++}`,
                `វិក្កយបត្រ: ${v.node.invoice}<br>
                ឈ្មោះតុ: ${v.node.table.name}<br>
                អតិថិជន: ${customer}<br>
                បញ្ចុះតម្លៃ: ${v.node.discountAll}%`,
                `${aim.currencyFormat(
                  v.node.total,
                  v.node.Currency.shortName,
                  2
                )}`,
                `${aim.currencyFormat(
                  v.node.discount,
                  v.node.Currency.shortName,
                  2
                )}`,
                `${aim.currencyFormat(
                  v.node.grandTotal,
                  v.node.Currency.shortName,
                  2
                )}`,
                `${changePrice}`,
                `${balance}`,
                `${multiplePaid}`,
                `${multiplePaidType}`,
              ];
              data_tb_row.push(tb_row);
            });
            return data_tb_row;
          },
        }
      );
      aim.loadDataCloseReport(
        {
          currency: curr,
          tbId: tbId2,
          page_name: page_name2,
          load_page: "allSaleOrder",
          gql: `{
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
            }
            totalCount
            edgeCount
            edges {
              node {
                type
                saleInformation {
                  name
                }
                stock {
                  name
                }
                id
                quantity
                delete
                date {
                  created
                  creator {
                    username
                  }
                }
                saleorderextraSet {
                  AddExtra {
                    title
                  }
                }
                table {
                  name
                  number
                }
              }
            }
          }`,
        },
        {
          page_name: page_name2,
          function_load: `${page_name2}_datatable`,
          sumAmount: sumAmount2,
          order_false: [2, 3],
          width_column: {
            1: "100px",
          },
          columnHidden: [],
          orderColumn: ["id", "name", "", "", ""],
          searching: ["name_Icontains"],
          otherOption: {
            CreateInvoice_Status: 1,
            CreateInvoice_Closetransaction: 0,
          },
          // function
          data_execute: (result, load_page) => {
            // step 7
            const data_tb_row = [];
            result.recordsTotal = result.data[load_page].totalCount;
            result.recordsFiltered = result.data[load_page].totalCount;

            const d = result.data[load_page].edges;

            let number = 1;
            groupSaleOrder(d).forEach((v) => {
              let typeSale = "";
              if (v.type === "food") {
                typeSale = "ម្ហូប";
              } else if (v.type === "drink") {
                typeSale = "គ្រឿងទឹក";
              } else if (v.type === "stock") {
                typeSale = "ផលិតផលក្នុងស្តុក";
              } else if (v.type === "other") {
                typeSale = "ផ្សេងៗ";
              } else if (v.type === "set") {
                typeSale = "ឈុត";
              }
              const dateTime = new Date(v.date);
              const date = aim.dateFormat.date_kh(dateTime);
              var tb_row = [
                `${number++}`,
                `${v.name}`,
                `${v.quantity}`,
                `${typeSale}`,
                `${date}`,
              ];
              data_tb_row.push(tb_row);
            });
            return data_tb_row;
          },
        }
      );
      const $ = window.$;
      document.querySelector(`#report`).addEventListener("click", (e) => {
        const el = e.target;
        if (el.classList.contains("printContent")) {
          const content = el.getAttribute("data-print");
          console.log(content);
          // $.print(data_print_invoice);
          $(`#${content}`).print();
        } else if (el.id === "close_sale") {
          console.log("close_sale");
          withReactContent(Swal)
            .fire({
              icon: "question",
              title: "បិទបញ្ជី?",
              html: `របាយការណ៍ត្រូវបានបិទបន្ទាប់ពីអ្នកយល់ព្រម
                    <span class="text-danger"><br>សូមទាញទិន្នន័យមុនបិទបញ្ជីជាមុនសិន</span>`,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              cancelButtonText: "បោះបង់",
              confirmButtonText: "យល់ព្រម",
            })
            .then((result) => {
              if (result.isConfirmed) {
                const gql = `mutation MyMutation {
                closeTransaction(Close: 1) {
                  success
                }
              }`;
                const callback = (data) => {
                  if (data.errors) {
                    withReactContent(Swal).fire({
                      icon: "error",
                      title: "មានបញ្ហា",
                      html: `កំហុសក្នុងការតភ្ជាប់ សូមទាក់ទងអ្នកគ្រប់គ្រង<br>${data.errors[0].message}`,
                      confirmButtonText: "យល់ព្រម",
                    });
                  } else {
                    withReactContent(Swal)
                      .fire({
                        icon: "success",
                        title: "បញ្ជីបានបិទ",
                        text: "បញ្ជីបានបិទដោយជោគជ័យ",
                        showConfirmButton: false,
                        timer: 2500,
                      })
                      .then(() => {
                        navigate("/close_sale_list");
                      });
                  }
                };
                aim.jquery_free(gql, {}, callback);
              }
            });
        }
      });
    }
  });
  return (
    <div className="content-wrapper">
      <div className="container-fluid col-12 saleCloseHide" id="report">
        <div className="page-loading">
          {aim.header_option(formOption)}
          <div className="card card-primary">
            <div className="card-header">
              <h5 className="card-title" style={{ fontSize: "1.5rem" }}>
                <span className="language">ចំណូលការលក់</span>
                <span> </span>
                <span className="text-dark sale_income_close_row"></span>
              </h5>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">{aim.body_report(tbId, thead)}</div>
          </div>
          <div className="card card-primary">
            <div className="card-header">
              <h5 className="card-title" style={{ fontSize: "1.5rem" }}>
                <span className="language">របាយការណ៍លក់</span>
                <span> </span>
                <span className="text-dark sale_income_close_row"></span>
              </h5>

              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus"></i>
                </button>
              </div>
            </div>
            <div className="card-body">{aim.body_report(tbId2, thead2)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
